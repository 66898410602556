import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import MUIGrid from '../../Controller/MUIGrid';
import Tooltip from '@mui/material/Tooltip';
import { approveLeaveRequest, declineLeaveRequest } from '../../Components/AttendanceComponent.js';
import IconButton from '@mui/material/IconButton';
import { CheckCircleOutline as RightSignIcon, Cancel as CrossSignIcon } from '@mui/icons-material';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import * as Common from '../../Controller/Common';

const PendingApproval = () => {
    document.title = 'Pending Approval | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForDialog, setIsLoadingForDilaog] = useState(false);

    const [pendingApprovalData, setPendingApprovalData] = useState('');
    const [formData, setFormData] = useState('');
    let reseteFormState = { LineManagerRemark: "", LeaveId: "", EmpId: "", StartDate: "", EndDate: "", EmpRemark: "" };

    useEffect(() => {
        GetAllUsersLeavesForLineManager();
    }, []);

    const GetAllUsersLeavesForLineManager = async () => {

        let response
        response = await Get(APIs.getAllPendingApproval);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setPendingApprovalData(response);
        }
    }

    const approveLeave = async () => {

        setIsLoadingForDilaog(true);

        try {
          //  let response = await approveLeaveRequest(leaveId, empId, startDate, endDate, remark);
            let response = await approveLeaveRequest(formData);

            let msg = ['Employee leave approved successfully']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                GetAllUsersLeavesForLineManager();
                setFormData(reseteFormState);
                Common.CloseDialog('addApproveRemarkForLineManager');
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoadingForDilaog(false);
    }

    const columns = [
        {
            field: 'actions',
            headerName: '',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                (params.row.Status === 1) ?
                    <>
                        <Tooltip title="Approve">
                            <IconButton
                                size="small"
                                color="primary"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#addApproveRemarkForLineManager"
                                onClick={() => {
                                    //approveLeave(params.row.Id, params.row.EmpId, params.row.C_FromDate, params.row.C_ToDate, params.row.EmpRemarks)
                                    onChangeApproveDeclineLeave(params.row.Id, params.row.EmpId, params.row.C_FromDate, params.row.C_ToDate, params.row.EmpRemarks)
                                }}
                            >
                                <RightSignIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Decline">
                            <IconButton
                                size="small"
                                color="secondary"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#addRemarkForLineManager"
                                onClick={() => {
                                    onChangeApproveDeclineLeave(params.row.Id, params.row.EmpId, params.row.C_FromDate, params.row.C_ToDate, params.row.EmpRemarks)
                                }}
                            >
                                <CrossSignIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </>
                    : ""
            ),
        },
        {
            field: 'Full_Name',
            headerClassName: 'super-app-theme--header',
            headerName: 'Name',
            width: 160,
            editable: false,
        },
        {
            field: 'C_FromDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'From Date',
            width: 120,
            editable: false,
        },
        {
            field: 'C_ToDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'To Date',
            width: 120,
            editable: false,
        },
        {
            field: 'LeaveCount',
            headerClassName: 'super-app-theme--header',
            headerName: 'Leave Count',
            width: 100,
            editable: false,
        },
        {
            field: 'Status',
            headerClassName: 'super-app-theme--header',
            headerName: 'Leave Status',
            width: 105,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 2 ? 'success' : params.value === 3 ? 'danger' : params.value === 1 ? 'warning' : ""}`}>
                    {params.value === 2 ? 'Approved' : params.value === 3 ? 'Declined' : params.value === 1 ? 'Pending' : ""}
                </span>
            ),
        },
        {
            field: 'EmpRemarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Employee Remarks',
            width: 180,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ||  params.value === "undefined" ||  params.value === undefined ? "" : params.value;
            },
        },
        {
            field: 'LineManagerRemarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Line Manager Remark',
            width: 180,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ||  params.value === "undefined" ||  params.value === undefined ? "" : params.value;
            },
        },
        {
            field: 'C_CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Created Date',
            width: 170,
            editable: false,
        },
        {
            field: 'C_UpdatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Updated Date',
            width: 170,
            editable: false,
        }
    ];

    const rowsWithSrno = Array.from(pendingApprovalData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    const onChangeApproveDeclineLeave = async (leaveId, empId, startDate, endDate, empRemark) => {

        let initialFormState = { LeaveId: leaveId, EmpId: empId, StartDate: startDate, EndDate: endDate, EmpRemark: empRemark }

        setFormData(initialFormState);
    }

    const AddDeclineLeave = async () => {

        setIsLoadingForDilaog(true);

        try {
            let response = await declineLeaveRequest(formData);
            let msg = ['Employee leave decline successfully']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog('addRemarkForLineManager');
                GetAllUsersLeavesForLineManager();
                setFormData(reseteFormState);
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoadingForDilaog(false);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Pending Approval</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Pending Approval</h2>
                            </div>
                            <div className="col-auto">
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}

                    </div>
                </div>
            </main>
            {/* here update dialog */}
            <div className="modal fade" id="addRemarkForLineManager" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Add Decline Remark</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>
                        {isLoadingForDialog ? <ShowLoadingIcon /> : ''}
                        <div className="modal-body">
                            <form id="addUpdateBlogCategoryForm" encType="multipart/form-data">
                                <input
                                    className="form-control"
                                    id="categoryName"
                                    name='categoryName'
                                    value={formData.LineManagerRemark}
                                    placeholder='Why you decline user leave?'
                                    onChange={e => setFormData({ ...formData, LineManagerRemark: e.target.value })}
                                />
                                <div className="modal-footer">
                                    <button onClick={() => AddDeclineLeave()} className="btn btn-primary" id='okayButton' type="button">Add</button>
                                    <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* end dialog */}

            {/* here update dialog */}
            <div className="modal fade" id="addApproveRemarkForLineManager" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Add Approve Remark</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>
                        {isLoadingForDialog ? <ShowLoadingIcon /> : ''}
                        <div className="modal-body">
                            <form id="addUpdateBlogCategoryForm" encType="multipart/form-data">
                                <input
                                    className="form-control"
                                    id="categoryName"
                                    name='categoryName'
                                    value={formData.LineManagerApproveRemark}
                                    placeholder='Why you decline user leave?'
                                    onChange={e => setFormData({ ...formData, LineManagerRemark: e.target.value })}
                                />
                                <div className="modal-footer">
                                    <button onClick={() => approveLeave()} className="btn btn-primary" id='okayButton' type="button">Add</button>
                                    <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* end dialog */}
        </>
    )
}

export default PendingApproval    
