import React from 'react';

// Show Loading Icon
export const ShowLoadingIcon = () => {
    return (
        <div className="card-preloader ">
            <div className="card-status">
                <div className="spinner-border text-primary">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
};

// Hide Loading Icon
export const hideLoadingIcon = () => {
    return (
        <div className="card-preloader d-none">
            <div className="card-status">
                <div className="spinner-border text-primary">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
}
