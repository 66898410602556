import React from "react"
import { ReactDOM } from "react";
import Footer from "../../Layout/Footer";
import { useState } from "react";
import Messages from "../../Helpers/app.label";
import * as Component from "../../Components/ForgotPasswordComponent";
import { ToastContainer, toast } from 'react-toastify';
import { ShowLoadingIcon } from "../../Helpers/Loader";


const ForgotPassword = () => {
    document.title = 'Forgot Password | Codes';
    const [forgotPasswordFormData, setForgotPasswordFormData] = useState({ email: '' });
    const [isLoading, setIsLoading] = useState(false);


    const handleEmail = async () => {
        setIsLoading(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!forgotPasswordFormData.email) {
            toast.error("Email Field Cannot be Empty");
            setIsLoading(false);
            return;
        } else if (!emailRegex.test(forgotPasswordFormData.email)) {
            toast.error("Invalid Email Format");
            setIsLoading(false);
            return;
        }
        try {
            const response = await Component.forgotPassword(forgotPasswordFormData)

            if (response.result === true) {
                // setIsLoading(false);
                toast.success(response.message);
                setIsLoading(false);

            } else if (response.result === false) {
                toast.warning(response.message);
                setIsLoading(false);

            }
            else {
                toast.error(response.message);
                setIsLoading(false);

            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
            setIsLoading(false);

        }
    }



    return (
        <>


            <main className="main" id="top">
                {/* for toaster message */}
                <ToastContainer theme="colored" />
                {isLoading ? <ShowLoadingIcon /> : ''}
                <div className="container-fluid px-0" data-layout="container">
                    <div className="row vh-100 flex-cente g-0">
                        <div className="col-lg-6 position-relative d-none d-lg-block">
                            <div className="bg-holder" style={{ backgroundImage: "url(../../../assets/img/bg/34.png)" }}></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row flex-center h-100 g-0 px-4 px-sm-0">
                                <div className="col col-sm-6 col-lg-7 col-xl-6"><a className="d-flex flex-center text-decoration-none mb-4" href="../../../index.html">
                                    <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block"><img src="../../../assets/img/icons/CodesTechnology.png" alt="phoenix" width="200" /></div>
                                </a>
                                    <div className="text-center">
                                        <h4 className="text-1000">Forgot your password?</h4>
                                        <p className="text-700 mb-5">Enter your email below and we will send you a reset link</p>
                                        <form className="d-flex align-items-center mb-5">
                                            <input className="form-control flex-1" onChange={e => setForgotPasswordFormData({ email: e.target.value })}
                                                value={forgotPasswordFormData.email}
                                                id="email" type="email" placeholder="Email" />
                                            <button type="button" onClick={handleEmail} className="btn btn-primary ms-2">Send<span className="fas fa-chevron-right ms-2"></span></button>
                                        </form><a className="fs--1 fw-bold" href="#!">Still having problems?</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>



        </>
    )

}
export default ForgotPassword