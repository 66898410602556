import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, json } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Header from '../Layout/Header';
import { useState } from 'react';
import * as Component from '../Components/OrganizationSettingsComponent';
import Messages from '../Helpers/app.label';
import * as APIs from '../Helpers/app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';
import { ShowLoadingIcon } from '../Helpers/Loader';
import { ManageHolidays } from '../Helpers/ManageHolidays';



const Settings = () => {
    document.title = 'Organizations Settings | Codes';
    const [activeTab, setActiveTab] = useState('settings');
    const [workingHrsFormData, setWorkingHrsFormData] = useState('');
    const [paidWorkingDays, setPaidWorkingDays] = useState('');

    const [allowLeavePermission, setAllowLeavePermission] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [showDaySettings, setShowDaySettings] = useState(false);
    const [settingValue, setSettingValue] = useState('');
    const [settingDays, setSettingDays] = useState([]);
    const [formData, setFormData] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });

    useEffect(() => {
        setSettingDays(settingValue.split(','));
    }, [settingValue]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        GetOrgnizationStatus();
        // GetAllWorkingDayTime();
    }, []);

    //Get Setting Availabe in DB
    const GetOrgnizationStatus = async (event) => {

        try {
            let response = await Get(APIs.getOrgnizationSettingStatus);

            if (response === "SettingExisted") {
                setShowDaySettings(true);
                GetAllWorkingDayTime();
            } else {
                toast.error(Messages.OGNIZATIONS_SETTING_MSG);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    }

    //Get working days and time
    const GetAllWorkingDayTime = async (event) => {

        try {
            setIsLoading(true);

            let response = await Get(APIs.getAllDayAndWorkingTime);

            if (!response) {
                toast.error(Messages.OGNIZATIONS_SETTING_MSG);
            } else if (response.Message === "Working day record not found add new record!") {
                toast.warning(response.Message)
            } else {
                const { Hours, Days, PaidWorkingDays, LeavePermission } = response;
                setWorkingHrsFormData(Hours.SettingValue);
                setPaidWorkingDays(PaidWorkingDays.SettingValue);
                setSettingValue(Days.SettingValue);
                setAllowLeavePermission(LeavePermission.SettingValue);

                const selectedDays = Days.SettingValue.split(',');

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    Monday: selectedDays.includes('Monday'),
                    Tuesday: selectedDays.includes('Tuesday'),
                    Wednesday: selectedDays.includes('Wednesday'),
                    Thursday: selectedDays.includes('Thursday'),
                    Friday: selectedDays.includes('Friday'),
                    Saturday: selectedDays.includes('Saturday'),
                    Sunday: selectedDays.includes('Sunday'),
                }));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    };

    //Submit Working Day
    const SubmitWorkingDays = async () => {

        setIsLoading(true);
        try {
            if (!formData) {
                toast.error('Please select at least one day.');
                setIsLoading(false);
                return;
            }

            let response;

            response = await Component.submitWorkingDays(formData, workingHrsFormData, paidWorkingDays, allowLeavePermission);

            let msg = ['Settings saved successfully!']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                GetAllWorkingDayTime();
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong.");
        }
        setIsLoading(false);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked,
        }));
    };

    const handleCheckboxChangeForLeavePermission = (event) => {
        debugger
        const { name, checked } = event.target;
        // setFormData((prevFormData) => ({
        //     ...prevFormData,
        //     [name]: checked,
        // }));
        if (checked) {
            setAllowLeavePermission(name);
        }
        else {
            setAllowLeavePermission("");
        }
    };

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">
                    {/* Navbar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />
                    <ToastContainer theme="colored" />


                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <NavLink to="/Dashboard">Dashboard</NavLink>
                                </li>
                                <li className="breadcrumb-item active">Organizations Settings</li>
                            </ol>
                        </nav>

                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Organization Settings</h2>
                            </div>
                            <div className="" id="chat-sidebar">
                                <ul className="nav nav-phoenix-pills mb-5 d-sm-none d-xl-flex" id="contactListTab" data-chat-thread-tab="data-chat-thread-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className={`nav-link cursor-pointer ${activeTab === 'unread' ? 'active' : ''}`} data-bs-toggle="tab" role="tab" data-chat-thread-list="unread" aria-selected={activeTab === 'unread'} tabIndex="-1">
                                            General
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={`nav-link cursor-pointer ${activeTab === 'settings' ? 'active' : ''}`} data-bs-toggle="tab" data-chat-thread-list="settings" role="tab" aria-selected={activeTab === 'settings'} onClick={() => handleTabChange('settings')}>
                                            Accounts
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={`nav-link cursor-pointer ${activeTab === 'public-holidays' ? 'active' : ''}`} data-bs-toggle="tab" role="tab" data-chat-thread-list="public-holidays" aria-selected={activeTab === 'public-holidays'} onClick={() => handleTabChange('public-holidays')} tabIndex="-1">
                                            Manage Public Holidays
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className="tab-content">
                            {/* Settings Tab */}
                            {activeTab === 'settings' && showDaySettings && (
                                <div className="tab-pane fade show active" id="settings" role="tabpanel">

                                    <div className="container">
                                        <div className="row mb-3">

                                            <div className="col-sm-4 col-md-4">
                                                <div className="form-floating">
                                                    <input value={workingHrsFormData} onChange={(e) => setWorkingHrsFormData(e.target.value)} className="form-control" id="floatingInputGrid" type="number" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Working Hours <span className="text-danger"></span></label>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-8">
                                                <label className="" style={{ fontSize: '20px;' }}>Working Days !</label>
                                                <br />

                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Monday"
                                                        checked={formData.Monday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Monday
                                                    </label>
                                                </div>
                                                {/* <br /> */}
                                                <div className="form-check form-check-inline">

                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Tuesday"
                                                        checked={formData.Tuesday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Tuesday
                                                    </label>
                                                </div>
                                                {/* <br /> */}
                                                <div className="form-check form-check-inline">


                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="Wednesday"
                                                        checked={formData.Wednesday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Wednesday
                                                    </label>
                                                </div>
                                                {/* <br /> */}
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Thursday"
                                                        checked={formData.Thursday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Thursday
                                                    </label>
                                                </div>
                                                {/* <br /> */}
                                                <div className="form-check form-check-inline">

                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Friday"
                                                        checked={formData.Friday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Friday
                                                    </label>
                                                </div>
                                                {/* <br /> */}
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Saturday"
                                                        checked={formData.Saturday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Saturday
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="Sunday"
                                                        checked={formData.Sunday}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label">
                                                        Sunday
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4 col-md-4">
                                                <div className="form-floating">
                                                    <input value={paidWorkingDays} onChange={(e) => setPaidWorkingDays(e.target.value)} className="form-control" id="floatingInputGrid" type="number" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Leave Days <span className="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div className="col-sm-8 col-md-8">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="1"
                                                        checked={allowLeavePermission}
                                                        onChange={handleCheckboxChangeForLeavePermission}
                                                    />
                                                    <label className="form-check-label">
                                                        Allow employee take an approval from the Line Manager for leave
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <br />
                                        {isLoading ? <ShowLoadingIcon /> : ''}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            height: '40vh'  // Set container height to fill the entire viewport
                                        }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <button onClick={SubmitWorkingDays} type="button" className="btn btn-primary float-end">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            )}
                            {/* Manage Public Holidays Tab */}
                            {activeTab === 'public-holidays' && (
                                <div className="tab-pane fade show active" id="public-holidays" role="tabpanel">
                                    <ManageHolidays />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main >
        </>
    );

}

export default Settings