import React, { useEffect, useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import * as APIs from '../Helpers/app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';
import { Delete, Edit } from '@mui/icons-material';
import MUIGrid from '../Controller/MUIGrid';

import ConformationDialog from '../Controller/ConformationDialog';
import { ShowLoadingIcon } from '../Helpers/Loader';
import { Editor } from '@tinymce/tinymce-react'
import { addUpdateBlogData, DeleteBlog, getBlogById } from '../Components/BlogComponent.js';
import { getCategories } from '../Components/BlogCategoriesComponent';
import * as Common from '../Controller/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Messages from '../Helpers/app.label';
import { ImageSrcURL } from '../Helpers/app.constant.api';
import $ from 'jquery';
import Tooltip from '@mui/material/Tooltip';

const Blogs = () => {

    document.title = 'Blog | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [dialogName, setDialogName] = useState("");
    const [dialogButtonName, setDialogButtonName] = useState("");
    const [wantToUploadNewImage, setNewUploadImageBit] = useState(false);
    const [blogData, setBlogData] = useState('');
    const [blogRowId, setBlogRowId] = useState('');
    const thumbnailFileInputRef = useRef(null);
    const [formData, setFormData] = useState('');
    const [categories, setCategories] = useState('');
    let addUpdateFieldsState = { Id: 0, Title: "", CategoryId: "", MetaTitle: "", MetaDescription: "", BlogDescription: "" }
    const rowsWithSrno = Array.from(blogData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });
    // Grid Columns
    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 80,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Blog">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>

                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target='#addUpdateBlogModal'
                                //data-bs-target='#editBlogModal'
                                onClick={() => {
                                    openBlogDialog(params.row.Id)
                                }}

                            />
                        </div>
                    </Tooltip>
                    <>
                        <Tooltip title="Delete Blog">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>
                                <Delete
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteBlog"
                                    onClick={() => {
                                        setBlogRowId(params.row.Id)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                </>
            ),
        },
        {
            field: 'SRNO', headerName: 'SRNO', width: 60
        },
        {
            field: 'Title',
            headerClassName: 'super-app-theme--header',
            headerName: 'Title',
            width: 410,
            editable: false,
        }, {
            field: 'CategoryName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Category',
            width: 200,
            editable: false,
        }, {
            field: 'CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Created Date',
            width: 190,
            editable: false,
        }, {
            field: 'ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Modified Date',
            width: 190,
            editable: false,
        },

        // {
        //     field: 'delete',
        //     headerName: '',
        //     width: 25,
        //     renderCell: (params) => (
        //         <>
        //             <Delete
        //                 fontSize='small'
        //                 color="#525B75"
        //                 type="button"
        //                 data-bs-toggle="modal"
        //                 data-bs-target="#deleteBlog"
        //                 onClick={() => {
        //                     setBlogRowId(params.row.Id)
        //                 }}
        //             />
        //         </>
        //     ),
        // },
    ];

    useEffect(() => {
        GetAllBlogs();
        fetchCategories();
    }, []);

    // Get All Blogs Dat
    const GetAllBlogs = async (event) => {

        let response = await Get(APIs.getAllBlogs);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setBlogData(response);
        }
    }

    // Fetch Category from DB
    const fetchCategories = async () => {
        try {
            let data = await getCategories();
            setCategories(data);
        } catch (error) {
            console.error(error);
        }
    };

    // Open Blog Data Dialog
    const openBlogDialog = async (id) => {
        setIsLoading(true);
        let dialogName = ""
        let dialogButtonName = ""

        // By default Image Field should be null
        if (thumbnailFileInputRef.current) thumbnailFileInputRef.current.value = null;

        try {
            if (id === 0) {
                dialogName = "Add Blog";
                dialogButtonName = "Add";
            } else {
                dialogName = "Update Blog";
                dialogButtonName = "Update";

                addUpdateFieldsState = await getBlogById(id);

                if (!addUpdateFieldsState) {
                    throw new Error(Messages.Error);
                }
            }

            setDialogName(dialogName);
            setDialogButtonName(dialogButtonName);
            setFormData(addUpdateFieldsState);
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    }

    // Add Update Blog
    const addUpdateBlog = async () => {
        setIsLoading(true);
        let needToValidate = !(formData.Id > 0 && !wantToUploadNewImage);

        try {
            const response = await addUpdateBlogData(formData, needToValidate);
            // let msg = ['Blog Successfully Created!', 'Blog Successfully Updated!']

            if (response.result === true) {
                toast.success(response.message);
                Common.CloseDialog("addUpdateBlogModal");

                // By default Image Field should be null
                if (thumbnailFileInputRef.current) thumbnailFileInputRef.current.value = null;

                setNewUploadImageBit(false);
                setFormData(addUpdateFieldsState);
                await GetAllBlogs();
            } else if (response.result.original.Status === 400) {
                toast.warning("Blog name already exist!");
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    };

    // Delete Blog
    const deleteBlog = async () => {
        setIsLoading(true);
        const response = await DeleteBlog(blogRowId);

        if (response.Result === true) {
            setIsLoading(false);
            toast.success("Blog successfully deleted");
            GetAllBlogs();
        } else {
            setIsLoading(false);
        }
    }

    //Handle thumbnail image 
    const hideShowThumbnailImage = async (isHide) => {
        if (isHide) {
            $(".divThumbnailImage").hide();
            $(".divUploadFile").show();
        } else {
            $(".divUploadFile").hide();
            $(".divThumbnailImage").show();
        }
        setNewUploadImageBit(isHide);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Blog</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Blog</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target='#addUpdateBlogModal' onClick={() => { openBlogDialog(0) }}><span className="fas fa-plus me-2"></span>Add Blog</button>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure to delete this Blog !"} pageName={"Delete" + " " + "Blog"} onOkayClick={deleteBlog} id={"deleteBlog"} />

                    </div>
                </div>
            </main>

            {/* Add Update Blog Dialog */}
            <div className="modal fade" id="addUpdateBlogModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">{dialogName}</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>
                        <div className="modal-body">
                            <form id="addUpdateBlogForm" encType="multipart/form-data">

                                <div className='row'>
                                    <div className="col-sm-3 col-md-8">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Title
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                        <input value={formData.Title} onChange={e => setFormData({ ...formData, Title: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                    </div>
                                    <div className="col-sm-3 col-md-4">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Category <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.CategoryId} onChange={e => setFormData({ ...formData, CategoryId: e.target.value })} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Category</option>
                                            {Array.from(categories).map((item, index) => <option value={item.Id}>{item.Name}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Meta Title
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                        <input value={formData.MetaTitle} onChange={e => setFormData({ ...formData, MetaTitle: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Meta Title" />
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Thumbnail Image  <span style={{ color: "red" }}> *</span></label>

                                        {formData.Id === 0 ?
                                            <input ref={thumbnailFileInputRef} onChange={e => setFormData({ ...formData, image: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" id="floatingInputStartDate" type="file" placeholder="" />
                                            :
                                            <>
                                                <div className="divThumbnailImage" style={{ float: "right", marginTop: "6px" }}>
                                                    <a href={`${ImageSrcURL}/${formData.ThumbnailImagePath}`} target="_blank" rel="noopener noreferrer" className="document-link">
                                                        <img src={'/Assets/img/icons/jpgimage.png'} alt="File Icon" className="document-icon" style={{ width: "70px", height: "auto" }} />
                                                    </a>
                                                    <i onClick={() => hideShowThumbnailImage(true)} title="Remove Thumbail" style={{ padding: "6px 12px", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer", verticalAlign: "bottom", marginleft: "8px" }}><FontAwesomeIcon icon="fa-solid fa-trash" /> </i>
                                                </div>
                                                <div className="divUploadFile" style={{ display: "none" }}>
                                                    <div className='row'>
                                                        <div className='col-11'>
                                                            <input onChange={e => setFormData({ ...formData, image: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" id="floatingInputStartDate" type="file" placeholder="" />
                                                        </div>
                                                        <div className='col-1'>
                                                            <i onClick={() => hideShowThumbnailImage(false)} title="Undo Thumbail" style={{ padding: "7px 12px", float: "right", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon="fa-solid fa-arrows-rotate" /></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 gy-3">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Meta Description<span style={{ color: "red" }}> *</span></label>
                                    <textarea value={formData.MetaDescription} onChange={e => setFormData({ ...formData, MetaDescription: e.target.value })} className="form-control" id="floatingProjectOverview" placeholder=""></textarea>
                                </div>

                                <div className="col-12">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Description  <span style={{ color: "red" }}> *</span></label>
                                    <Editor
                                        apiKey='qli41xv2gw7u074e3xcvhz3acyd3p4in406rh3t3auk1l21o'
                                        //initialValue={formData.BlogDescription}
                                        value={formData.BlogDescription}
                                        onEditorChange={(newValue, editor) => setFormData({ ...formData, BlogDescription: newValue })}
                                        init={{
                                            height: 250,
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                            lists_indent_on_tab: false
                                        }}
                                    //onChange={e => setFormData({ ...formData, BlogDescription: descriptionTextEditorRef.current.getContent() })}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addUpdateBlog} className="btn btn-primary" id='okayButton' type="button">{dialogButtonName}</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Add Update Blog Dialog */}
        </>
    )
}

export default Blogs
