import { Post } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

//Payslip validation
const validatePayslipDetails = (year, month) => {
    debugger
    let result = { Message: '' }

    if (!year) {
        result.Message = "Year is required!"
    } else if (!month) {
        result.Message = "Month is required!"
    }  else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const selectedMonth = monthNames.findIndex((m) => m === month) + 1;

        if (parseInt(year) === currentYear && selectedMonth === currentMonth) {
            result.Message = "You can't select the current month!";
        } else if (parseInt(year) > currentYear || (parseInt(year) === currentYear && selectedMonth > currentMonth)) {
            result.Message = "You can't select months above the current month!";
        }
    }

    return result;
}
// Generate payslips
export const generatePayslipRequest = async (year, month) => {
    let response = validatePayslipDetails(year, month);

    if (!response.Message) {
        
        try {
            const data = new FormData();
            data.append('Year', year);
            data.append('Month', month);

            response = await Post((APIs.generatePayslip), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}