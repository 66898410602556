import { Post } from '../Services/HttpClient/HttpRequest';
import * as Constants from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

// Forgot Password Function
export const forgotPassword = async (formData) => {
    let response;
    try {
        const data = new FormData();
        data.append('email', formData.email);

        response = await Post(Constants.forgotPassword, data); // assuming post is imported from another module

        return response; // moved inside the try block
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
};