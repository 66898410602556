import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { ShowLoadingIcon } from '../Helpers/Loader';
import { NavLink, useNavigate } from "react-router-dom";
import { generatePayslipRequest } from '../Components/PayslipComponent.js';
import html2pdf from 'html2pdf.js';
import numberToWords from 'number-to-words';

const Payslips = (props) => {
    document.title = 'Payslips | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const years = Array.from({ length: 11 }, (_, index) => new Date().getFullYear() - index);

    const [hideGeneratePayslip, setHideGeneratePayslip] = useState(true);
    const [data, setData] = useState('');
    const [netPaybleInWord, setNetPaybleInWord] = useState('');

    let payslipData = { current_date: "", month_year: "", full_name: "", bank_name: "", acc_number: "", branch_name: "", IFSC_code: "", salary: "", days_month: "", days_payble: "", pan_number: "", payble_earning: "", incentive: "", net_payable: "", earnend: "", availed: "", closing: "" }

    const generatePayslip = async () => {
        debugger
        setIsLoading(true);
        try {
            let payslipData = await generatePayslipRequest(year, month);
            let msg = ['Payslip successfully generate']

            if (payslipData && msg.includes(payslipData.Message)) {

                // toast.success(response.Message);
                const inWord = numberToWords.toWords(payslipData.net_payablein_word);
                const capitalizedInWord = inWord.charAt(0).toUpperCase() + inWord.slice(1);
                const formattedInWord = capitalizedInWord.replace(/, /g, ' ');
                const finalFormattedInWord = formattedInWord.endsWith('And')
                    ? formattedInWord + ' rupees only'
                    : formattedInWord + ' rupees only';

                setNetPaybleInWord(finalFormattedInWord);
                setHideGeneratePayslip(false);
                setData(payslipData);

            } else {
                toast.error(payslipData.Message);
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
    }

    const convertToPdf = async () => {
        debugger
        setIsLoading(true);
        try {

            const pdfOptions = {
                filename: 'Payslip-' + month + '-' + year + '.pdf',
                margin: 0,
                html2canvas: {
                    dpi: 192,
                    useCORS: true,
                    scale: 4,
                    allowImageAccessFromFile: true,
                },
                jsPDF: {
                    // unit: 'px',
                    // format: [794, 1123],
                    unit: 'in',
                    format: [8.28, 11.69],
                    orientation: 'portrait',
                },
            };
            const content = document.getElementById('content');

            const generate = await html2pdf()
                .from(content)
                .set(pdfOptions)
                .save();

            setHideGeneratePayslip(true);
            window.location.reload(false);

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
    };

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                {/* {id && (
                                    <li className="breadcrumb-item active">
                                        <a
                                            style={{
                                                color: '#3874ff',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.textDecoration = 'underline';
                                                e.target.style.color = '#1d55ff';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.textDecoration = 'none';
                                                e.target.style.color = '#3874ff';
                                            }}
                                            onClick={() => handelRedirectingEmployee(id)}
                                        >
                                            {employeeName}
                                        </a>
                                    </li>
                                )} */}
                                <li className="breadcrumb-item active">  Payslips</li>
                            </ol>
                        </nav>

                        {hideGeneratePayslip && (
                            <>
                                <div className="row align-items-center justify-content-between g-3 mb-2">
                                    <div className="col col-auto">
                                        <h2 className="text-bold text-1100 mb-5">Payslips</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Year <span style={{ color: "red" }}> *</span></label>
                                        <select onChange={(e) => setYear(e.target.value)} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Year</option>
                                            {years.map((year, index) => (
                                                <option key={index} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Month <span style={{ color: "red" }}> *</span></label>
                                        <select onChange={(e) => setMonth(e.target.value)} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Month</option>
                                            {months.map((month, index) => (
                                                <option key={index} value={month}>{month}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-12 gy-400 mt-6">
                                        <div className="row g-3 justify-content-end">
                                            <div className="col-auto"><button onClick={generatePayslip} type="button" className="btn btn-primary px-5 px-sm-15">Generate Payslip</button></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {hideGeneratePayslip === false && (
                            <>
                                <div className="row align-items-center justify-content-between g-3 mb-2">
                                    <div className="col col-auto">
                                        <h2 className="text-bold text-1100 mb-5">Payslips</h2>
                                    </div>
                                    <div className="col-auto">
                                        <div className="d-flex align-items-center"></div>
                                        <button onClick={convertToPdf} className="btn btn-phoenix-secondary">
                                            <svg className="svg-inline--fa fa-download me-sm-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                <path fill="currentColor" d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z">
                                                </path>
                                            </svg>
                                            Download Payslip
                                        </button>
                                    </div>
                                </div>
                                {/* Here payslip template */}
                                <div id="content"
                                    style={{
                                        // fontFamily: "Nunito Sans, sans-serif",
                                        fontFamily: "Livvic, sans-serif",
                                        maxWidth: 800,
                                        margin: "0 auto",
                                        padding: "5px",
                                        backgroundColor: "#ffffff",
                                        fontWeight: 600
                                    }}
                                >
                                    <p style={{ textAlign: "right" }}></p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "5px",
                                        }}
                                    >
                                        <div style={{ flex: "0 0 33.33%" }}>
                                            <img
                                                src="/Assets/img/icons/CodesTechnology.png"
                                                alt="Logo"
                                                width="270"
                                                style={{
                                                    marginLeft: "11px",
                                                    marginBottom: "20px"
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0 0 64.66%" }}>
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    color: "#888",
                                                    fontSize: 14,
                                                    marginRight: "15px"
                                                }}
                                            >
                                                Codes Technology<br />
                                                Surat Gujarat-395001<br />
                                                2177815/SUR/2022/01
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <br />
                                    <p style={{
                                        textAlign: "right",
                                        marginBottom: 0,
                                        padding: "5px",
                                        marginRight: "10px",
                                        fontWeight: 510,
                                        letterSpacing: "1px",
                                    }}>
                                        Date Issued : 01 {data.current_date}
                                    </p>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px",
                                            borderCollapse: "collapse",
                                            border: "1px solid #000",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan="4"
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "10px",
                                                        border: "1px solid #000",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    PAY SLIP FOR {data.month_year}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px",
                                            borderCollapse: "collapse",
                                            border: "1px solid #000",
                                            marginTop: "3px",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: "10px", fontWeight: 600, textAlign: "center", border: "1px solid #000", }}>
                                                    Name Of Employee
                                                </td>
                                                <td colSpan="3" style={{ padding: "10px", textAlign: "center", border: "1px solid #000", }}>
                                                    {data.full_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px", border: "1px solid #000", }}>
                                                    Name<br />
                                                    Bank Name <br />
                                                    Bank A/C No <br />
                                                    Branch <br />
                                                    IFSC Code<br />
                                                </td>
                                                <td style={{ padding: "10px", border: "1px solid #000", }}>
                                                    {data.full_name} <br />
                                                    {data.bank_name} <br />
                                                    {data.acc_number} <br />
                                                    {data.branch_name} <br />
                                                    {data.IFSC_code} <br />
                                                </td>
                                                <td style={{ padding: "10px", border: "1px solid #000", }}>
                                                    Monthly Gross <br />
                                                    Days Month <br />
                                                    Days Payable <br />
                                                    PAN <br /><br />
                                                </td>
                                                <td style={{ textAlign: "right", padding: "10px", border: "1px solid #000", }}>
                                                    ₹ {data.salary} <br />
                                                    {data.days_month} <br />
                                                    {data.days_payble} <br />
                                                    {data.pan_number} <br /><br />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px", borderCollapse: "collapse",
                                            border: "1px solid #000",
                                            marginTop: "3px",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" style={{ padding: "10px", fontWeight: "bold", textAlign: "center", }}>
                                                    Leave Balance
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px", borderCollapse: "collapse",
                                            border: "1px solid #000",
                                            marginTop: "3px",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>Opening</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>Earnend</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>Availed</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>Closing</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>{data.opening}</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>{data.earnend}</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>{data.availed}</td>
                                                <td style={{ textAlign: "center", border: "1px solid #000", padding: "10px" }}>{data.closing}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px", borderCollapse: "collapse",
                                            border: "1px solid #000",
                                            marginTop: "3px",
                                            padding: "10px"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" style={{ fontWeight: "bold", padding: "10px" }}>
                                                    <br />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{
                                        width: "97%",
                                        marginLeft: "11px",
                                        borderCollapse: "collapse",
                                        border: "1px solid #000",
                                        marginTop: "3px",
                                        padding: "10px"
                                    }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "29.6468%", border: "1px solid #000", padding: "10px" }}>Earning Head</td>
                                                <td style={{ width: "12.1673%", border: "1px solid #000", padding: "10px" }}>Month Earning</td>
                                                <td style={{ width: "29.6468%", border: "1px solid #000", padding: "10px" }}>Deduction Head</td>
                                                <td style={{ width: "12.1673%", border: "1px solid #000", padding: "10px" }}>Month Deduction</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #000", padding: "10px" }}>
                                                    Payable Earning<br />
                                                    Incentive
                                                </td>
                                                <td style={{ textAlign: "right", border: "1px solid #000", padding: "10px" }}>
                                                    ₹ {data.payble_earning}<br />
                                                    {data.incentive}
                                                </td>
                                                <td style={{ border: "1px solid #000", padding: "10px" }}>
                                                    Income Tax (TDS) <br />
                                                    Professional Tax <br />
                                                    PF Deducted
                                                </td>
                                                <td style={{ textAlign: "right", border: "1px solid #000", padding: "10px" }}>
                                                    0<br />
                                                    0<br />
                                                    0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #000", padding: "10px" }}>Total</td>
                                                <td style={{ textAlign: "right", border: "1px solid #000", padding: "10px" }}>₹ {data.net_payable}</td>
                                                <td style={{ border: "1px solid #000", padding: "10px" }}></td>
                                                <td style={{ textAlign: "right", border: "1px solid #000", padding: "10px" }}>0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "97%",
                                            marginLeft: "11px",
                                            borderCollapse: "collapse",
                                            border: "1px solid #000",
                                            marginTop: "3px",
                                            padding: "10px"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "29.461%", border: "1px solid #000", padding: "10px" }}>
                                                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                                                        Net Payable
                                                    </div>
                                                </td>
                                                <td style={{ width: "70.539%", border: "1px solid #000", padding: "10px" }}>
                                                    <strong>₹ {data.net_payable}</strong>
                                                    <br />
                                                    <strong>{netPaybleInWord}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}
                    </div>
                </div>
            </main>

        </>
    )
}

export default Payslips

