import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import MUIGrid from '../../Controller/MUIGrid';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import Tooltip from '@mui/material/Tooltip';
import { Edit } from '@mui/icons-material';
import { getAttendanceById, updateAttendanceData } from '../../Components/AttendanceComponent.js';
import Messages from '../../Helpers/app.label';
import * as Common from '../../Controller/Common';

const AttendanceDetails = () => {
    document.title = 'Attendance Details | Codes';

    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [attendanceData, setAttendanceData] = useState('');
    const [formData, setFormData] = useState('');
    let addUpdateFieldsState = { Id: 0, AttendanceType: "", PunchInTime: "", PunchOutTime: "", Remarks: "" }
    const urlParams = new URLSearchParams(window.location.search);
    let UserId = urlParams.get("id");
    const [isPresent, setIsPresent] = useState(false);
    const [employeeName, setEmployeeName] = useState('');
    useEffect(() => {
        if (UserId !== null) {
            const storedEmployeeName = localStorage.getItem('UserFullName');
            if (storedEmployeeName) {
                setEmployeeName(storedEmployeeName);
            }
        } else {
            GetAllAttendanceDetails();
        }
    }, [UserId]);


    useEffect(() => {
        GetAllAttendanceDetails();
    }, []);

    const GetAllAttendanceDetails = async () => {
      
        let response
        if (UserId !== null) {
            response = await Get(APIs.getAllAttendanceDetails + "/" + id + "," + UserId);
        } else {
            response = await Get(APIs.getAllAttendanceDetails + "/" + id);
        }

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setAttendanceData(response);
        }
    }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 60,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Record">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>

                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target='#updateAttendanceRecord'
                                onClick={() => {
                                    openAttendanceDialog(params.row.Id)
                                }}

                            />
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'C_Date',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            width: 140,
            editable: false,
        },
        {
            field: 'AttendanceType',
            headerClassName: 'super-app-theme--header',
            headerName: 'Status',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 1 ? 'success' : params.value === 2 ? 'danger' : 'warning'}`}>
                    {params.value === 1 ? 'Present' : params.value === 2 ? 'Leave' : params.value === 3 ? 'Half Day' : ""}
                </span>
            ),
        },
        {
            field: 'PunchInTime',
            headerClassName: 'super-app-theme--header',
            headerName: 'Punch In Time',
            width: 115,
            editable: false,
        },
        {
            field: 'PunchOutTime',
            headerClassName: 'super-app-theme--header',
            headerName: 'Punch Out Time',
            width: 125,
            editable: false,
        },
        {
            field: 'Duration',
            headerClassName: 'super-app-theme--header',
            headerName: 'Duration',
            width: 90,
            editable: false,
            // valueFormatter: (params) => {
            //     return params.value ? params.value + " min" : "";
            // },
        },
        {
            field: 'Remarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Remarks',
            width: 220,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ? "" : params.value;
            },
        },
        {
            field: 'C_ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Modified Date',
            width: 170,
            editable: false,
        }
    ];

    // Open Blog Data Dialog
    const openAttendanceDialog = async (id) => {
        
        setIsLoading(true);
        try {
            addUpdateFieldsState = await getAttendanceById(id);
            if (!addUpdateFieldsState) {
                throw new Error(Messages.Error);
            }
            setFormData(addUpdateFieldsState);
            let attendanceStatus  = addUpdateFieldsState.AttendanceType;

            if (attendanceStatus === null  || attendanceStatus === '' || attendanceStatus === '1' || attendanceStatus === 1 || attendanceStatus === '3' || attendanceStatus === 3) {
                setIsPresent(true);
            } else {
                setIsPresent(false);
            }

        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    };

    //Update attendance
    const updateAttendance = async () => {

        setIsLoading(true);
        try {
            let response = await updateAttendanceData(formData);
            let msg = ['Attendance record successfully updated']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog("updateAttendanceRecord");
                setFormData(addUpdateFieldsState);
                await GetAllAttendanceDetails();
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    };

    const rowsWithSrno = Array.from(attendanceData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    const changeStatus = e => {
      
        let selectedStatus = e.target.value;
        setFormData({ ...formData, AttendanceType: selectedStatus });

        if (selectedStatus === '1' || selectedStatus === 1 || selectedStatus === '3' || selectedStatus === 3) {
            setIsPresent(true);
        } else {
            setIsPresent(false);
        }
    };
    const handelRedirectingEmployee = () => {
        if (UserId == null) {
            navigate('/Attendance');
        } else {
            navigate(`/Attendance?id=${UserId}`);
        }
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                {/* <li className="breadcrumb-item active"><NavLink to="/Attendance">Attendance</NavLink></li> */}
                                <li className="breadcrumb-item active">
                                    <a
                                        style={{
                                            color: '#3874ff',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = '#1d55ff';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = '#3874ff';
                                        }}
                                        onClick={() => handelRedirectingEmployee(id)}
                                    >
                                        {/* {employeeName} */}
                                        Attendance
                                    </a>
                                </li>

                                <li className="breadcrumb-item active">Attendance Details</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Attendance Details</h2>
                            </div>
                            <div className="col-auto">
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Conformation Dialog */}
                        {/* <ConformationDialog msg={"Are you sure to delete this Employee ?"} pageName={pageName} name={name} onOkayClick={handleDeleteEmployee} id={"deleteEmployees"} />
                        <ConformationDialog msg={"Are you sure to Change Ownership to this Employee !"} pageName={pageName} name={name} onOkayClick={changeOwnershipEmployee} id={"changeOwner"} /> */}

                    </div>
                </div>
            </main>

            {/* Here change password dialog */}
            <div className="modal fade" id="updateAttendanceRecord" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Update Attendance Record</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>

                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-body fs-14">
                            <form id="" encType="multipart/form-data">
                                <div className='row'>

                                    <div className="col-sm-3 col-md-12">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Status <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.AttendanceType} onChange={changeStatus} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Status</option>
                                            <option value="1">Present</option>
                                            <option value="2">Leave</option>
                                            <option value="3">Half Day</option>
                                        </select>
                                    </div>

                                    {isPresent && (
                                        <>
                                            <div className="col-sm-3 col-md-6 mt-2">
                                                <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Punch In Time<span style={{ color: "red" }}> *</span></label>
                                                <input value={formData.PunchInTime ? formData.PunchInTime : ""} onChange={e => setFormData({ ...formData, PunchInTime: e.target.value })} className="form-control" type="time" />
                                            </div>

                                            <div className="col-sm-3 col-md-6 mt-2">
                                                <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Punch Out Time<span style={{ color: "red" }}> *</span></label>
                                                <input value={formData.PunchOutTime ? formData.PunchOutTime : ""} onChange={e => setFormData({ ...formData, PunchOutTime: e.target.value })} className="form-control" type="time" />
                                            </div>
                                        </>
                                    )}

                                    <div className="col-sm-3 col-md-12 mt-2">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Remark<span style={{ color: "red" }}></span></label>
                                        <textarea value={formData.Remarks ? formData.Remarks : ""} onChange={e => setFormData({ ...formData, Remarks: e.target.value })} className="form-control" id="floatingProjectOverview" placeholder=""></textarea>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={updateAttendance} className="btn btn-primary" id='okayButton' type="button">Submit</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
            {/* end dialog */}
        </>
    )
}

export default AttendanceDetails    