import { Post, Delete, Get } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

const validateTransaction = (formData) => {
    let result = { Message: '' }
    if (!formData.Remarks) {
        result.Message = "Remark is required!"
        // } else if (!formData.PayReceiveBy) {
        //     result.Message = "Pay/ReceiveBy is required!"
        // } else if (!formData.TransactionType) {
        //     result.Message = "Transaction Type is required!"
    } else if (!formData.TransactionAmount) {
        result.Message = "Transaction Amount is required!"
    }
    return result;
}

// Add Update Blog Data
export const addUpdateTransactionData = async (formData) => {
    let response = validateTransaction(formData);

    if (!response.Message) {

        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('Remarks', formData.Remarks);
            data.append('PayReceiveBy', formData.PayReceiveBy);
            data.append('TransactionType', formData.TransactionType);
            data.append('TransactionAmount', formData.TransactionAmount);

            response = await Post((APIs.addUpdateTransaction), data)

        } catch (error) {
            toast.error("Something went wrong.");
        }
    }
    return response;
}

//Delete Transaction
export const deleteTransaction = async (id) => {
    let response = "";
    try {
        response = await Delete((APIs.deleteTransaction + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}

//Get transaction
export const getTransactionById = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getTransactionById + "/" + id);

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}