import { Post, Delete } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';



export const submitWorkingDays = async (formData, workingHrsFormData, paidWorkingDays, allowLeavePermission) => {

  let response = "";
  try {
    const data = new FormData();

    let checkedDays = [];
    for (const key in formData) {
      if (formData[key]) {
        checkedDays.push(key);
      }
    }
    data.append('checkedDays', checkedDays.join(','));
    data.append('WorkingTime', workingHrsFormData);
    data.append('PaidWorkingDays', paidWorkingDays);
    data.append('AllowLeavePermission', allowLeavePermission);

    response = await Post(APIs.addUpdateOrgnizationSettings, data);
  } catch (error) {
    throw new Error(Messages.SOMETHING_WENT_WRONG);
  }
  return response;
};

export const DeleteHoliday = async (id) => {
  let response = "";
  try {
    response = await Delete((APIs.deleteHoliday + "/" + id));

  } catch (error) {
    throw new Error(Messages.SOMETHING_WENT_WRONG);
  }
  return response;
}

const validateHoliday = (formData) => {
  let result = { message: '' }
  if (!formData.Date) {
    result.message = "Date is required!"
  } else if (!formData.Type) {
    result.message = "Type is required!"
  } else if (!formData.HolidayName) {
    result.message = "Holiday Name is required!"
  }
  return result;
}

//Add Update Holiday 
export const addUpdateHolidayData = async (formData) => {

  let response = validateHoliday(formData);

  if (!response.message) {
    try {

      const data = new FormData();
      data.append('Id', formData.Id);
      data.append('Date', formData.Date);
      data.append('HolidayName', formData.HolidayName);
      data.append('Type', formData.Type);

      response = await Post((APIs.addUpdateHoliday), data)

    } catch (error) {
      toast.error("Something went wrong.");
    }
  }
  return response;
}




