import { Post } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

const validateLoginForm = (formData) => {
    let result = { message: '' }

    if (!formData.email) {
        result.message = "Email is required!";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        result.message = "Email is invalid!"
    }
    if (!formData.password) {
        result.message = "Password is required!"
    }
    return result;
};

export const SignIn = async (formData) => {
    let response = validateLoginForm(formData);

    if (!response.message) {
        try {
            const data = new FormData();
            data.append('email', formData.email);
            data.append('password', formData.password);
            data.append('platFormType', "isWebApp");

            response = await Post(APIs.login, data);
        } catch (error) {
            response = "";
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}

