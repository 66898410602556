import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Post, Get } from '../../Services/HttpClient/HttpRequest';
import { Edit } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import MUIGrid from '../../Controller/MUIGrid';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import { addLeaveRequest, getAttendanceById, updateAttendanceData } from '../../Components/AttendanceComponent.js';
import * as Common from '../../Controller/Common';
import Messages from '../../Helpers/app.label';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const Attendance = () => {
    document.title = 'Attendance | Codes';

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLeaveButtonLoading, setIsLeaveButtonLoading] = useState(true);
    const [isUpdateAttendanceLoading, setUpdateAttendanceLoading] = useState(true);
    const [attendanceData, setAttendanceData] = useState('');

    const [punchOutVisible, setPunchOutVisible] = useState(true);        // for punchout buuton 
    const [disablePunchButtons, setDisablePunchButton] = useState(true); // for punchin and punchout buttons
    const [disableButton, setDisableButton] = useState(false);           // for 3 buttons

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [remark, setRemark] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    const [employeeName, setEmployeeName] = useState('');
    const [events, setEvents] = useState('');

    const [formData, setFormData] = useState('');
    let addUpdateFieldsState = { Id: 0, AttendanceType: "", PunchInTime: "", PunchOutTime: "", Remarks: "" }
    const [isPresent, setIsPresent] = useState(false);

    useEffect(() => {
        if (id !== null) {
            const storedEmployeeName = localStorage.getItem('UserFullName');
            if (storedEmployeeName) {
                setEmployeeName(storedEmployeeName);
            }
        } else {
            GetAllAttendance(null);
            GetAttendanceForCalender(null);
        }
    }, [id]);

    useEffect(() => {
        GetAllAttendance(null);
        GetAttendanceForCalender(null);
    }, []);

    useEffect(() => {
        if (!id) {
            CheckPunchinOrPunchout();
        }
    }, [id]);

    //Check punch in, punch out and leave button visible or disable
    const CheckPunchinOrPunchout = async () => {

        let response = await Post(APIs.checkPunchinOrPunchout);

        if (response && response.Rusult === "IsPunchedIn") {
            setPunchOutVisible(true);
            setDisableButton(true);
        } else if (response && response.Rusult === "IsPunchedOut") {
            setPunchOutVisible(false);
            setDisableButton(true);
        } else if (response && response.Rusult === "TodaysActivityCompleted") {
            setDisablePunchButton(false);
            setDisableButton(true);
        } else if (response && response.Rusult === "TodaysLeave") {
            setDisablePunchButton(false);
            setDisableButton(true);
        } else {
            setDisableButton(true);
        }

        const currentDate = new Date();
        const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
        if (isWeekend) {
            setDisableButton(false)
        }
    }

    const PunchIn = async () => {

        setIsLoading(true);
        try {
            let response = await Post(APIs.punchIn);
            let msg = ['You have successfully punched-in']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                GetAttendanceForCalender(null);
                GetAllAttendance(null);
                setPunchOutVisible(false);
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
    }

    const PunchOut = async () => {
        setIsLoading(true);

        try {
            let response = await Post(APIs.punchOut);
            let msg = ['You have successfully punched-out']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                GetAllAttendance(null);
                GetAttendanceForCalender(null);
                setPunchOutVisible(true);
                CheckPunchinOrPunchout();
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }

    const GetAllAttendance = async (date) => {

        let formattedDate = "";
        if (date === null) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            formattedDate = `${year}-${month}`;
        } else {
            const selectedDate = new Date(date);
            const year = selectedDate.getFullYear();
            const month = selectedDate.toLocaleString('default', { month: '2-digit' });
            formattedDate = `${year}-${month}`;
        }

        let response
        if (id !== null) {
            response = await Get(APIs.getAllAttendanceDetails + "/" + formattedDate + "," + id);
        } else {
            response = await Get(APIs.getAllAttendanceDetails + "/" + formattedDate);
        }

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setAttendanceData(response);
        }
    }

    const ViewDetailClick = (params) => {
        let url = '/AttendanceDetails/' + params.row.MonthYear;
        if (id) {
            url += '/' + id;
        }
        navigate(url);
    };
   
    const columns = [
        // {
        //     field: 'edit',
        //     headerName: '',
        //     width: 60,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <>
        //             <Tooltip title="Edit Record">
        //                 <div style={{ marginRight: '5px', display: 'inline-block' }}>

        //                     <Edit
        //                         fontSize='small'
        //                         color="#525B75"
        //                         type="button"
        //                         data-bs-toggle="modal"
        //                         data-bs-target='#updateAttendanceRecord'
        //                         onClick={() => {
        //                             openAttendanceDialog(params.row.Id)
        //                         }}

        //                     />
        //                 </div>
        //             </Tooltip>
        //         </>
        //     ),
        // },
        {
            field: 'C_Date',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            width: 140,
            editable: false,
        },
        {
            field: 'AttendanceType',
            headerClassName: 'super-app-theme--header',
            headerName: 'Status',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 1 ? 'success' : params.value === 2 ? 'danger' : 'warning'}`}>
                    {params.value === 1 ? 'Present' : params.value === 2 ? 'Leave' : params.value === 3 ? 'Half Day' : ""}
                </span>
            ),
        },
        {
            field: 'PunchInTime',
            headerClassName: 'super-app-theme--header',
            headerName: 'Punch In Time',
            width: 115,
            editable: false,
        },
        {
            field: 'PunchOutTime',
            headerClassName: 'super-app-theme--header',
            headerName: 'Punch Out Time',
            width: 125,
            editable: false,
        },
        {
            field: 'Duration',
            headerClassName: 'super-app-theme--header',
            headerName: 'Duration',
            width: 90,
            editable: false,
            // valueFormatter: (params) => {
            //     return params.value ? params.value + " min" : "";
            // },
        },
        {
            field: 'Remarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Remarks',
            width: 220,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ? "" : params.value;
            },
        },
        {
            field: 'C_ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Modified Date',
            width: 170,
            editable: false,
        }
    ];

    const rowsWithSrno = Array.from(attendanceData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    let inputStartDate = startDate;
    let sDate = inputStartDate ? inputStartDate.substring(0, 10) : new Date().toISOString().split("T")[0];
    let inputEndDate = endDate;
    let eDate = inputEndDate ? inputEndDate.substring(0, 10) : new Date().toISOString().split("T")[0];

    const addLeave = async () => {

        setIsLoading(true);
        setIsLeaveButtonLoading(false);

        try {
            let response = await addLeaveRequest(startDate, endDate, remark);
            let msg = ['You have successfully apply for leave']
            let warnMsg = ["Already you have complete activity on given day for more information check details!"]

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog('leaveModel');
                GetAttendanceForCalender(null);
                GetAllAttendance(null);
                setStartDate("");
                setEndDate("");
                setRemark("");
                CheckPunchinOrPunchout();
            } else if (response && warnMsg.includes(response.Message)) {
                toast.warning(response.Message);
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
        setIsLeaveButtonLoading(true);
    }

    const handelRedirectingEmployee = () => {
        if (id == null) {
            navigate('/Employee');
        } else {
            navigate(`/AddUpdateEmployee?id=${id}`);
        }
    }

    const GetAttendanceForCalender = async (date) => {

        setIsLoading(true);
        try {
            let formattedDate = "";
            if (date === null) {
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                formattedDate = `${year}-${month}`;
            } else {
                const selectedDate = new Date(date);
                const year = selectedDate.getFullYear();
                const month = selectedDate.toLocaleString('default', { month: '2-digit' });
                formattedDate = `${year}-${month}`;
            }

            const attendanceResponse = await Get(APIs.getAttendanceForCalender + "/" + formattedDate + (id !== null ? "," + id : ""));

            //Attendance record
            const apiAttendanceEvents = attendanceResponse.Attendance.map((attendance) => {
                const attendanceLabel = attendance.AttendanceLabel.toUpperCase();
                const [year, month, day] = attendance.FormattedDate.split(", ").map((value) => parseInt(value));
                const formattedDate = new Date(year, month - 1, day);

                return {
                    title: attendanceLabel,
                    start: formattedDate,
                    end: formattedDate,
                };
            });
            //Holidays record
            const apiHolidayEvents = attendanceResponse.Holiday.map((holiday) => {
                const holidayLabel = holiday.HolidayName.toUpperCase();
                const [year, month, day] = holiday.FormattedDate.split(", ").map((value) => parseInt(value));
                const formattedDate = new Date(year, month - 1, day);

                return {
                    title: holidayLabel,
                    start: formattedDate,
                    end: formattedDate,
                };
            });
            //Weekend record
            // const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

            const weekdays = attendanceResponse.WorkingDays;
            let currentYear = "";
            let currentMonth = "";
            const weekendEvents = [];

            if (date === null) {
                currentYear = new Date().getFullYear();
                currentMonth = new Date().getMonth();
            } else {
                let selectDate = new Date(date);
                currentYear = selectDate.getFullYear();
                currentMonth = selectDate.getMonth();
            }

            for (let dayIndex = 1; dayIndex <= 31; dayIndex++) {
                const currentDate = new Date(currentYear, currentMonth, dayIndex);
                const currentDayName = currentDate.toLocaleString('en-US', { weekday: 'long' });

                if (!weekdays.includes(currentDayName)) {
                    const startDate = currentDate;
                    const endDate = new Date(currentYear, currentMonth, dayIndex + 1);
                    weekendEvents.push({
                        title: "WEEK END",
                        start: startDate,
                        end: endDate,
                    });
                }
            }

            setEvents([...apiAttendanceEvents, ...apiHolidayEvents, ...weekendEvents]);
        } catch (error) {
            toast.error("Something went wrong!");
        }
        setIsLoading(false);
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor;

        switch (event.title) {
            case 'LEAVE':
                backgroundColor = 'red';
                break;
            case 'PRESENT':
                backgroundColor = 'green';
                break;
            case 'HALF DAY':
                backgroundColor = 'orange';
                break;
            case 'WEEK END':
                backgroundColor = 'gray';
                break;
            default:
                backgroundColor = 'sky blue';
                break;
        }

        const style = {
            backgroundColor,
            color: 'white',
        };


        return {
            className: '',
            style,
            content: (
                <div
                    style={{
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        padding: '10px',
                        maxWidth: '200px', // Adjust the width as needed
                        borderRadius: '5px', // Add rounded corners
                    }}
                >
                    <h3 style={{ margin: '0', fontSize: '16px', color: '#333' }}>{event.title}</h3>
                </div>
            ),
        };
    };

    const localizer = momentLocalizer(moment);

    //When click on date
    // const handleDateSlotClick = (event) => {
    //     alert('This is date slot : ' + event.end);
    // };
    //When click on lable
    // const handleLableEventClick = (event) => {
    //     alert('This is lable event : ' + event.title);
    // };
    //When click on next button
    const handleNextButton = (date) => {
        GetAttendanceForCalender(date);
        GetAllAttendance(date);
    };
    //When click on back button
    const handleBackButton = (date) => {
        GetAttendanceForCalender(date);
        GetAllAttendance(date);
    };

    //Add custom toolbaar like next, back, today buttons
    const CustomToolbar = ({ localizer, label, onNavigate }) => {
        const handleNavigate = (action) => {
            onNavigate(action);
        };

        const currentMonthYear = moment(label).format('MMMM YYYY');

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => handleNavigate('MONTH')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Month</span>
                    </button>
                    <button type="button" onClick={() => handleNavigate('WEEK')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Week</span>
                    </button>
                    <button type="button" onClick={() => handleNavigate('DAY')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Day</span>
                    </button>
                </span>
                <span className="rbc-toolbar-label">{currentMonthYear}</span>
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => handleNavigate('PREV')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Prev</span>
                    </button>
                    <button type="button" onClick={() => handleNavigate('TODAY')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Today</span>
                    </button>
                    <button type="button" onClick={() => handleNavigate('NEXT')} style={{ fontSize: '13px', padding: '3px 5px' }}>
                        <span className="rbc-btn-text">Next</span>
                    </button>
                </span>
            </div>
        );
    };

    // Open Blog Data Dialog
    const openAttendanceDialog = async (id) => {

        setIsLoading(true);
        setUpdateAttendanceLoading(false);

        try {
            addUpdateFieldsState = await getAttendanceById(id);
            if (!addUpdateFieldsState) {
                throw new Error(Messages.Error);
            }
            setFormData(addUpdateFieldsState);
            let attendanceStatus = addUpdateFieldsState.AttendanceType;

            if (attendanceStatus === null || attendanceStatus === '' || attendanceStatus === '1' || attendanceStatus === 1 || attendanceStatus === '3' || attendanceStatus === 3) {
                setIsPresent(true);
            } else {
                setIsPresent(false);
            }

        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
        setUpdateAttendanceLoading(true);
    };

    //Update attendance
    const updateAttendance = async () => {

        setIsLoading(true);
        setUpdateAttendanceLoading(false);
        try {
            let response = await updateAttendanceData(formData);
            let msg = ['Attendance record successfully updated']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog("updateAttendanceRecord");
                setFormData(addUpdateFieldsState);
                await GetAllAttendance(null);
                GetAttendanceForCalender(null);
                CheckPunchinOrPunchout();
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
        setUpdateAttendanceLoading(true);
    };

    const changeStatus = e => {

        let selectedStatus = e.target.value;
        setFormData({ ...formData, AttendanceType: selectedStatus });

        if (selectedStatus === '1' || selectedStatus === 1 || selectedStatus === '3' || selectedStatus === 3) {
            setIsPresent(true);
        } else {
            setIsPresent(false);
        }
    };

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                {id && (
                                    <li className="breadcrumb-item active">
                                        <a
                                            style={{
                                                color: '#3874ff',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.textDecoration = 'underline';
                                                e.target.style.color = '#1d55ff';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.textDecoration = 'none';
                                                e.target.style.color = '#3874ff';
                                            }}
                                            onClick={() => handelRedirectingEmployee(id)}
                                        >
                                            {employeeName}
                                        </a>
                                    </li>
                                )}
                                <li className="breadcrumb-item active">  Attendance</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Attendance</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">

                                    {
                                        disableButton === true ? (
                                            <>
                                                <NavLink className="btn btn-success px-5 mx-2" to="/ManageLeaves">
                                                    <i className="fa-solid fa-user-cog me-2"></i>Apply for Leave
                                                </NavLink>
                                                {/* <button className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#leaveModel">
                                                    <span className="fas fa-plus me-2"></span>Leave
                                                </button> */}

                                                {disablePunchButtons === true ? (
                                                    <>
                                                        {punchOutVisible === true ? (
                                                            <button className="btn btn-primary mx-2" onClick={PunchIn} type="button">
                                                                <span className="fas fa-plus me-2"></span>Punch-in
                                                            </button>
                                                        ) : (
                                                            <button className="btn btn-warning mx-2" onClick={PunchOut} type="button">
                                                                <span className="fas fa-plus me-2"></span>Punch-out
                                                            </button>
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        {/* scedualer start */}
                        <div className="row align-items-stretch g-3 mb-5">
                            <div className="col-md-12" style={{ height: '400px' }}>
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ fontSize: '10px' }}
                                    eventPropGetter={eventStyleGetter}
                                    selectable
                                    // onSelectEvent={handleLableEventClick}
                                    // onSelectSlot={handleDateSlotClick}
                                    components={{ toolbar: CustomToolbar }}
                                    onNavigate={(date, view) => {
                                        if (date > new Date()) {
                                            handleNextButton(date);
                                        } else {
                                            handleBackButton(date);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/* sceduler end */}

                        {/* Added DataGrid */}
                        <div className='g-3 mb-5'>
                            <MUIGrid Data={rowsWithSrno} columnData={columns} />
                        </div>

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Here loading icon */}
                        {isLoading && isLeaveButtonLoading && isUpdateAttendanceLoading ? <ShowLoadingIcon /> : ''}
                    </div>
                </div>
            </main>

          

            {/* Here update attendance dialog */}
            <div className="modal fade" id="updateAttendanceRecord" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Update Attendance Record</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>

                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-body fs-14">
                            <form id="" encType="multipart/form-data">
                                <div className='row'>

                                    <div className="col-sm-3 col-md-12">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Status <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.AttendanceType} onChange={changeStatus} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Status</option>
                                            <option value="1">Present</option>
                                            <option value="2">Leave</option>
                                            <option value="3">Half Day</option>
                                        </select>
                                    </div>

                                    {isPresent && (
                                        <>
                                            <div className="col-sm-3 col-md-6 mt-2">
                                                <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Punch In Time<span style={{ color: "red" }}> *</span></label>
                                                <input value={formData.PunchInTime ? formData.PunchInTime : ""} onChange={e => setFormData({ ...formData, PunchInTime: e.target.value })} className="form-control" type="time" />
                                            </div>

                                            <div className="col-sm-3 col-md-6 mt-2">
                                                <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Punch Out Time<span style={{ color: "red" }}> *</span></label>
                                                <input value={formData.PunchOutTime ? formData.PunchOutTime : ""} onChange={e => setFormData({ ...formData, PunchOutTime: e.target.value })} className="form-control" type="time" />
                                            </div>
                                        </>
                                    )}

                                    <div className="col-sm-3 col-md-12 mt-2">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Remark<span style={{ color: "red" }}></span></label>
                                        <textarea value={formData.Remarks ? formData.Remarks : ""} onChange={e => setFormData({ ...formData, Remarks: e.target.value })} className="form-control" id="floatingProjectOverview" placeholder=""></textarea>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={updateAttendance} className="btn btn-primary" id='okayButton' type="button">Submit</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
            {/* end dialog */}
        </>
    )
}

export default Attendance