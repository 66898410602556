import { Post, Get } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

const validateLeaveDetails = (startDate, endDate) => {
    let result = { Message: '' }

    if (!startDate) {
        result.Message = "Start date is required!"
    } else if (!endDate) {
        result.Message = "End date is required!"
    } else if (startDate > endDate) {
        result.Message = "End date must be greater than the start date!";
    } else if (startDate < new Date().toISOString().split('T')[0]) {
        result.Message = "Start date must be greater than the current date!";
    }

    return result;
}

// Add leave
export const addLeaveRequest = async (startDate, endDate, remark) => {
    let response = validateLeaveDetails(startDate, endDate);

    if (!response.Message) {
        // let response = "";
        try {
            const data = new FormData();
            data.append('startDate', startDate);
            data.append('endDate', endDate);
            data.append('Remark', remark);

            response = await Post((APIs.applyForLeave), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}
// Check holidays
export const checkHolidaysRequest = async (startDate, endDate) => {

        let response = "";
        try {
            const data = new FormData();
            data.append('startDate', startDate);
            data.append('endDate', endDate);

            response = await Post((APIs.checkHoliday), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    return response;
}
//Get Attendance By Id
export const getAttendanceById = async (id) => {
   
    let result = "";
    try {
        const response = await Get((APIs.getAttendanceById + "/" + id));

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}


const validateAttendanceData = (formData) => {
    let result = { Message: '' }

    if(!formData.AttendanceType){
        result.Message="Please select attendance type!" ;
    } else if(!formData.PunchInTime){
        result.Message="Please select punch in time!" ;
    } else if(!formData.PunchOutTime){
        result.Message="Please select punch out time!" ;
    } else if (formData.PunchInTime > formData.PunchOutTime) {
        result.Message = "Punch out time must be greater than the punch in time!";
    }
    return result;
}
//Update Attendance
export const updateAttendanceData = async (formData) => {
   
    let response = validateAttendanceData(formData);

    if (!response.Message) {
        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('AttendanceType', formData.AttendanceType);
            data.append('PunchInTime', formData.PunchInTime);
            data.append('PunchOutTime', formData.PunchOutTime);
            data.append('Remarks', formData.Remarks);
        
            response = await Post((APIs.updateAttendance), data)
            
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}
//Approved leave request
export const approveLeaveRequest = async (formData) => {
    let response = validateApproveDeclineLeaveRequest(formData);
debugger
        try {
            const data = new FormData();
            data.append('leaveId', formData.LeaveId);
            data.append('empId', formData.EmpId);
            data.append('startDate', formData.StartDate);
            data.append('endDate', formData.EndDate);
            data.append('Remark', formData.EmpRemark);
            data.append('LineManagerRemark', formData.LineManagerRemark);

            response = await Post((APIs.approveUserLeaveRequest), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    return response;
}

//Decline leave request
export const declineLeaveRequest = async (formData) => {
    let response = validateApproveDeclineLeaveRequest(formData);
debugger
        try {
            const data = new FormData();
            data.append('Remark', formData.LineManagerRemark);
            data.append('LeaveId', formData.LeaveId);
            data.append('EmpId', formData.EmpId);
            data.append('StartDate', formData.StartDate);
            data.append('EndDate', formData.EndDate);

           response = await Post((APIs.declineUserLeaveRequest), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    return response;
}

const validateApproveDeclineLeaveRequest = (formData) => {
    let result = { Message: '' }

    if(!formData.LineManagerRemark){
        result.Message="Remark is required!" ;
    } 
    return result;
}