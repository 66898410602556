import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import * as Icon from 'react-feather';

const Dropzone = ({ onFilesAccepted, isShowImages }) => {

    const [droppedFiles, setDroppedFiles] = useState([]);

    const handleDrop = (acceptedFiles) => {
        setDroppedFiles(acceptedFiles);
        onFilesAccepted(acceptedFiles);
    };

    // const handleDrop = async (acceptedFiles) => {
    //     debugger
    //     if (isShowImages === false) {
    //         setDroppedFiles([]);
    //     }
    //     await setDroppedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    //     onFilesAccepted(acceptedFiles);
    //     // onFilesAccepted(droppedFiles);
    //     // onFilesAccepted((prevFiles) => [...prevFiles, ...acceptedFiles]);
    // };

    // const handleDrop = async (acceptedFiles) => {
    //     if (isShowImages === false) {
    //         setDroppedFiles([]);
    //     }
    //     await setDroppedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    //     useEffect(() => {
    //         onFilesAccepted(droppedFiles);
    //     }, [droppedFiles]);
    // };

    // const [droppedFiles, setDroppedFiles] = useState([]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop, disablePreview: true, });

    const handleRemoveImage = (index) => {
        setDroppedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
    };

    return (
        <>
            {isShowImages && (
                <div className="dropzone dropzone-multiple p-0 mb-1" id="my-awesome-dropzone" data-dropzone="data-dropzone">
                    <div className="dz-preview d-flex flex-wrap">
                        {droppedFiles.map((file, index) => {
                            const fileName = file.name;
                            const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
                            const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
                            const maxChars = 10;
                            const truncatedFileName =
                                fileNameWithoutExtension.length > maxChars
                                    ? fileNameWithoutExtension.substring(0, maxChars) + '...' + fileExtension
                                    : fileName;

                            return (
                                <div key={file.name} className="border bg-white rounded-3 d-flex flex-column align-items-center justify-content-center position-relative me-2" style={{ width: '145px', height: '58px', padding: '10px' }}>
                                    <p className="dz-filename mt-2">{truncatedFileName}</p>
                                    <a onClick={() => handleRemoveImage(index)} className="dz-remove text-400" href="#!" data-dz-remove="data-dz-remove">
                                        <Icon.X size='18' />
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {/* <div className="dropzone" {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p style={{ color: 'blue' }}>Drop the files here...</p>
                ) : (
                    <p style={{ color: 'black' }}><Icon.Cloud size='22' />&nbsp;
                        Drop files to attach or <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>browse</span>
                    </p>
                )}
            </div> */}

            <div className="dropzone" {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
                <input {...getInputProps()} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                    {isDragActive ? (
                        <p style={{ color: 'blue' }}>Drop the files here...</p>
                    ) : (
                        <p style={{ color: 'black' }}>
                            <Icon.Cloud size='22' />&nbsp;
                            Drop files to attach or <span style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer' }}>browse</span>
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}

export default Dropzone
