import React, { useEffect, useState } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as Component from '../../Components/PortfolioComponent.js';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import { Editor } from '@tinymce/tinymce-react'
import Messages from '../../Helpers/app.label';
import { ImageSrcURL } from '../../Helpers/app.constant.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Dropzone from '../../Controller/Dropzone';

const AddUpdatePortfolio = () => {
    const [title, setTitle] = useState("Add Portfolio | Codes");
    const { id } = useParams();
    const navigate = useNavigate();
    const [addUpdateButtonName, setaddUpdateButtonName] = useState("Add Portfolio");
    const [isLoading, setIsLoading] = useState(false);
    const [wantToUploadNewImage, setNewUploadImageBit] = useState(false);
    const [wantToUploadNewGallleryImages, setNewUploadGalleryImageBit] = useState(false);
    const [formData, setFormData] = useState({ Id: 0, ProjectTimelineType: "Days", IsWindowsPlatformType: false, IsWebPlatformType: false, IsAndroidPlatformType: false, IsIOSPlatformType: false, IsLinuxPlatformType: false, IsMacPlatformType: false});
    // let initialAddUpdateFormState = { Id: 0, Title: "", Industry: "", CompanyName: "", ProjectLocation: "", ProjectLink: "", ProjectTimeline: "", ProjectTimelineType: "Days", OverviewText: "", FeaturesText: "", ModulesText: "", PortGallery: []}

    const [showImages, setShowImages] = useState(false);

    useEffect(() => {
        document.title = title;

        const GetPortfolioDetailsbyId = async () => {
            let addUpdateFieldsState = "";

            if (id) {
                setTitle('Update Portfolio | Codes');
                setaddUpdateButtonName("Update Portfolio");

                setIsLoading(true);

                try {

                    addUpdateFieldsState = await Component.getPortfolioById(id);

                    if (!addUpdateFieldsState) {
                        throw new Error(Messages.Error);
                    }

                    setFormData(addUpdateFieldsState);
                } catch (error) {
                    navigate('/Portfolio')
                    // toast.error(Messages.SOMETHING_WENT_WRONG);
                }

                setIsLoading(false);
            }
        };
        GetPortfolioDetailsbyId();
    }, [id, navigate, title]);

    // Add Update Portfolio
    const addUpdatePortfolio = async () => {
        setIsLoading(true);

        let needToValidateThumbnailImage = !(formData.Id !== 0 && !wantToUploadNewImage);
        let needToValidateGalleryImage = !(formData.Id !== 0 && !wantToUploadNewGallleryImages);

        if (id) {
            formData.Id = id;
        }

        const response = await Component.addUpdatePortfolio(formData, needToValidateThumbnailImage, needToValidateGalleryImage);
        let msg = ['Portfolio Successfully Updated!', 'Portfolio Successfully Created!']

        if (response && msg.includes(response.message)) {
            toast.success(response.message);
            if (id) {
                window.location.reload(false);
            } else {
                navigate('/Portfolio');
            }
        } else {
            toast.error(response.message);
        }
        setIsLoading(false);
    };

    //Handle thumbnail image 
    const hideShowThumbnailImage = async (isHide) => {
        if (isHide) {
            $(".divThumbnailImage").hide();
            $(".divUploadFile").show();
        } else {
            $(".divUploadFile").hide();
            $(".divThumbnailImage").show();
        }
        setNewUploadImageBit(isHide);
    }

    //Handle Port Gallery images 
    const hideShowPortGalleryImage = async (isHide) => {
        if (isHide) {
            $(".divPortGalleryImages").hide();
            $(".divUploadGalleryFiles").show();
        } else {
            $(".divUploadGalleryFiles").hide();
            $(".divPortGalleryImages").show();
        }
        setNewUploadGalleryImageBit(isHide);
    }

    const handleFilesAccepted = (files) => {
        setShowImages(true);
        setFormData({ ...formData, PortGalleryImages: files })
    };

    // UI/UX Section
    return (
        <>
            <main className="main" id="top">

                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">

                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/Portfolio">Portfolio</NavLink></li>
                                <li className="breadcrumb-item active">Add Update Portfolio</li>
                            </ol>
                        </nav>
                        <h2 className="mb-4">{addUpdateButtonName}</h2>
                        <div className="row">
                            <div className="col-xl-12">
                                <form encType="multipart/form-data" className="row g-3 mb-6">
                                    <div className="col-sm-6 col-md-8">
                                        <div className="form-floating">
                                            <input value={formData.Title} onChange={e => setFormData({ ...formData, Title: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Title <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-floating">
                                            <select value={formData.Industry} onChange={e => setFormData({ ...formData, Industry: e.target.value })} className="form-select" id="floatingSelectTask">
                                                <option value="">Select Industry</option>
                                                <option value="Healthcare">Healthcare</option>
                                                <option value="Retails">Retails</option>
                                                <option value="Finance">Finance</option>
                                                <option value="Transportation">Transportation</option>
                                                <option value="Cloud Computing">Cloud Computing</option>
                                                <option value="Food And Beverages">Food And Beverages</option>
                                                <option value="Logistics">Logistics</option>
                                                <option value="E-Commerce">E-Commerce</option>
                                                <option value="Educational">Educational</option>
                                                <option value="Goverment And Defence">Goverment And Defence</option>
                                                <option value="Construction">Construction</option>
                                                <option value="Insurance">Insurance</option>
                                                <option value="Technology">Technology</option>
                                                <option value="Advertising">Advertising</option>
                                            </select>
                                            <label htmlFor="floatingSelectTask">Industry <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Featured Image <span className="text-danger">*</span></label>

                                        {formData.Id === 0 ?
                                            <input onChange={e => setFormData({ ...formData, FeaturedImage: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" id="floatingInputStartDate" type="file" placeholder="" />
                                            :
                                            <>
                                                <div className="divThumbnailImage" style={{ marginTop: "6px" }}>
                                                    <a href={`${ImageSrcURL}/PortfolioImages/${formData.Title}/${formData.FeaturedImageName}`} target="_blank" rel="noopener noreferrer" className="document-link">
                                                        <img src={'/Assets/img/icons/jpgimage.png'} alt="File Icon" className="document-icon" style={{ width: "70px", height: "auto" }} />
                                                    </a>
                                                    <i onClick={() => hideShowThumbnailImage(true)} title="Remove Thumbail" style={{ padding: "6px 12px", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer", verticalAlign: "bottom", marginleft: "8px" }}><FontAwesomeIcon icon="fa-solid fa-trash" /> </i>
                                                </div>
                                                <div className="divUploadFile" style={{ display: "none" }}>
                                                    <div className='row'>
                                                        <div className='col-11'>
                                                            <input onChange={e => setFormData({ ...formData, FeaturedImage: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" id="floatingInputStartDate" type="file" placeholder="" />
                                                        </div>
                                                        <div className='col-1'>
                                                            <i onClick={() => hideShowThumbnailImage(false)} title="Undo Thumbail" style={{ padding: "7px 12px", float: "right", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon="fa-solid fa-arrows-rotate" /></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <label style={{ padding: "8px 12px", display: "block" }} className="ps-1 form-label" htmlFor="floatingInputStartDate">PLatform Type <span className="text-danger">*</span></label>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Windows"
                                                checked={formData.IsWindowsPlatformType}
                                                onChange={e => setFormData({ ...formData, IsWindowsPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                Windows
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Web"
                                                checked={formData.IsWebPlatformType}
                                                onChange={e => setFormData({ ...formData, IsWebPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                Web
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Android"
                                                checked={formData.IsAndroidPlatformType}
                                                onChange={e => setFormData({ ...formData, IsAndroidPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                Android
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="IOS"
                                                checked={formData.IsIOSPlatformType}
                                                onChange={e => setFormData({ ...formData, IsIOSPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                IOS
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Mac"
                                                checked={formData.IsMacPlatformType}
                                                onChange={e => setFormData({ ...formData, IsMacPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                Mac
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Linux"
                                                checked={formData.IsLinuxPlatformType}
                                                onChange={e => setFormData({ ...formData, IsLinuxPlatformType: e.target.checked })}
                                            />
                                            <label className="form-check-label">
                                                Linux
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.CompanyName} onChange={e => setFormData({ ...formData, CompanyName: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Company Name" />
                                            <label htmlFor="floatingInputGrid">Company Name <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.Location} onChange={e => setFormData({ ...formData, Location: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Project Location" />
                                            <label htmlFor="floatingInputGrid">Project Location <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.ProjectLink} onChange={e => setFormData({ ...formData, ProjectLink: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Project Link" />
                                            <label htmlFor="floatingInputGrid">Project Link</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-md-3">
                                        <div className="form-floating">
                                            <input value={formData.ProjectTimeline} onChange={e => setFormData({ ...formData, ProjectTimeline: e.target.value })} className="form-control" id="floatingInputGrid" min={1} max={10000} type="number" placeholder="Project Timeline" />
                                            <label htmlFor="floatingInputGrid">Project Timeline <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-4 col-md-3">
                                        <div className="form-floating">
                                            <select value={formData.ProjectTimelineType} onChange={e => setFormData({ ...formData, ProjectTimelineType: e.target.value })} className="form-select" id="floatingSelectTask">
                                                <option value="Days">Days</option>
                                                <option value="Months">Months</option>
                                                <option value="Years">Years</option>
                                            </select>
                                            <label htmlFor="floatingSelectTask">Timeline Type </label>
                                        </div>
                                    </div>

                                    <div className="col-12 gy-3">
                                        <div className="form-floating">
                                            <textarea value={formData.OverviewText} onChange={e => setFormData({ ...formData, OverviewText: e.target.value })} className="form-control" id="floatingProjectOverview" placeholder="Overview Text" style={{ height: "100px" }}></textarea>
                                            <label htmlFor="floatingProjectOverview">Overview Text <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-12 gy-4">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Features Text</label>
                                        <Editor
                                            apiKey='qli41xv2gw7u074e3xcvhz3acyd3p4in406rh3t3auk1l21o'
                                            value={formData.FeaturesText}
                                            onEditorChange={(newValue, editor) => setFormData({ ...formData, FeaturesText: newValue })}
                                            init={{
                                                height: 250,
                                            }}
                                        />
                                    </div>

                                    <div className="col-12 gy-4">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Modules Text</label>
                                        <Editor
                                            apiKey='qli41xv2gw7u074e3xcvhz3acyd3p4in406rh3t3auk1l21o'
                                            value={formData.ModulesText}
                                            onEditorChange={(newValue, editor) => setFormData({ ...formData, ModulesText: newValue })}
                                            init={{
                                                height: 250,
                                            }}
                                        />
                                    </div>

                                    <div className="col-12 gy-3">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Gallery Images <span className="text-danger">*</span></label>
                                        {formData.Id === 0 ?
                                            <Dropzone onFilesAccepted={handleFilesAccepted} isShowImages={showImages} />
                                            :
                                            <>
                                                <div className="divPortGalleryImages" style={{ marginTop: "6px" }}>
                                                    {JSON.parse(formData.PortGallery).map((item, i) => {
                                                        return (
                                                            <>
                                                                <a href={`${ImageSrcURL}/PortfolioImages/${formData.Title}/${item}`} target="_blank" rel="noopener noreferrer" className="document-link" key={i}>
                                                                    <img src={'/Assets/img/icons/jpgimage.png'} alt="File Icon" className="document-icon" style={{ width: "70px", height: "auto" }} />
                                                                </a>
                                                            </>
                                                        );
                                                    })}
                                                    <i onClick={() => hideShowPortGalleryImage(true)} title="Remove Thumbail" style={{ padding: "6px 12px", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer", verticalAlign: "bottom", marginleft: "8px" }}><FontAwesomeIcon icon="fa-solid fa-trash" /> </i>
                                                </div>
                                                <div className="divUploadGalleryFiles" style={{ display: "none" }}>
                                                    <div className='row'>
                                                        <div className='col-11'>
                                                            <Dropzone onFilesAccepted={handleFilesAccepted} isShowImages={showImages} />
                                                        </div>
                                                        <div className='col-1'>
                                                            <i onClick={() => hideShowPortGalleryImage(false)} title="Undo Thumbail" style={{ padding: "7px 12px", float: "right", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon="fa-solid fa-arrows-rotate" /></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                                        <div className="card-header p-4 border-bottom border-300 bg-soft">
                                            <div className="row g-3">
                                                <div className="col-12 col-md">
                                                    <h5 className="text-900 mb-0" >Technology Components <span className="text-danger">*</span></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className="row g-3">
                                                <div className="col-md-6 position-relative">
                                                    <div className="form-floating">
                                                        <input value={formData.DevelopmentSkills} onChange={e => setFormData({ ...formData, DevelopmentSkills: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Development Skills" />
                                                        <label htmlFor="floatingInputGrid">Development Skills</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 position-relative">
                                                    <div className="form-floating">
                                                        <input value={formData.Frontend} onChange={e => setFormData({ ...formData, Frontend: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Frontend" />
                                                        <label htmlFor="floatingInputGrid">Frontend</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 position-relative">
                                                    <div className="form-floating">
                                                        <input value={formData.Backend} onChange={e => setFormData({ ...formData, Backend: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Backend" />
                                                        <label htmlFor="floatingInputGrid">Backend</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 position-relative">
                                                    <div className="form-floating">
                                                        <input value={formData.DatabasesName} onChange={e => setFormData({ ...formData, DatabasesName: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Databases" />
                                                        <label htmlFor="floatingInputGrid">Databases</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 position-relative">
                                                    <div className="form-floating">
                                                        <input value={formData.Webservers} onChange={e => setFormData({ ...formData, Webservers: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Web Servers" />
                                                        <label htmlFor="floatingInputGrid">Web Servers</label>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <h6 className="text-900 mb-1" data-anchor="data-anchor">
                                                        Note: Add technology by comma(,) seprated i.e. HTML,CSS,JQuery.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 gy-400">
                                        <div className="row g-3 justify-content-end">
                                            <div className="col-auto">
                                                <button onClick={() => {
                                                    navigate('/Portfolio')
                                                }} className="btn btn-phoenix-primary px-5">Cancel
                                                </button>
                                            </div>
                                            <div className="col-auto"><button onClick={addUpdatePortfolio} type="button" className="btn btn-primary px-5 px-sm-15">{addUpdateButtonName}</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                    </div>
                </div >
            </main >
        </>
    )
}

export default AddUpdatePortfolio