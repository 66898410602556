
// For Local
// export const ImageSrcURL = "http://127.0.0.1:8000";
// const API_BASE = "http://127.0.0.1:8000/api/"; 
// export const LinkURL = "http://localhost:3000";

// //For Production
const API_BASE = "https://api.codestechnology.net/api/";
export const ImageSrcURL = "https://api.codestechnology.net";
export const LinkURL = "https://payroll.codestec.com";

//For Developer
// const API_BASE = "https://apidev.codestechnology.net/api/";
// export const ImageSrcURL = "https://apidev.codestechnology.net";
// export const LinkURL = "https://codespayroll.netlify.app";

//Login  
export const login = API_BASE + "login";
//Blog
export const getAllBlogs = API_BASE + "getAllBlogs";
export const deleteBlog = API_BASE + "deleteBlog";
export const addUpdateBlog = API_BASE + "addUpdateBlog";
export const getBlogById = API_BASE + "getBlogById";
//Blog Category
export const viewBlogCategories = API_BASE + "getAllCategories";
export const deleteBlogCategories = API_BASE + "deleteCategory";
export const addUpdateBlogCategories = API_BASE + "addUpdateCategory";
export const getCategoryById = API_BASE + "getCategoryById";
//Portfolio
export const viewPortfolio = API_BASE + "getAllPortfolios";
export const AddUpdatePortfolio = API_BASE + "addUpdatePortfolio";
export const getPortfolioById = API_BASE + "getPortfolioById";
export const deletePortfolio = API_BASE + "deletePortfolio";
//Subscriber
export const getAllSubscribers = API_BASE + "getAllSubscribers";
export const deleteSubscriber = API_BASE + "deleteSubscriber";
export const sendEmail = API_BASE + "sendEmail";
export const sendEmailToAll = API_BASE + "sendEmailToAll";
//Transactions
export const getAllTransactions = API_BASE + "getAllTransactions";
export const addUpdateTransaction = API_BASE + "addUpdateTransaction";
export const deleteTransaction = API_BASE + "deleteTransaction";
export const getTransactionById = API_BASE + "getTransactionById";
export const getDebitCreditAmount = API_BASE + "getDebitCreditAmount";
//Forgot Password
export const forgotPassword = API_BASE + "forgotPassword";
export const resetPassword = API_BASE + "resetPassword";
export const getPasswordStaus = API_BASE + "getPasswordStaus";
//Employees
export const getAllUser = API_BASE + "getAllUser";
export const getUserById = API_BASE + "getUserById";
export const addUpdateUser = API_BASE + "addUpdateUser";
export const deleteUser = API_BASE + "deleteUser";
export const changePassword = API_BASE + "changePassword";
export const getManagers = API_BASE + "getManagers";
//Employee Documents
export const viewDocuments = API_BASE + "getAllDocuments";
export const addEmployeeDocuments = API_BASE + "addEmployeeDocuments";
export const deleteEmployeeDocuments = API_BASE + "deleteDocuments";
export const viewEmployeeDocuments = API_BASE + "viewEmployeeDocumnets";
//Employee Roles
export const getAllRoles = API_BASE + "getAllRoles";
export const addUpdateRole = API_BASE + "addUpdateRole";
export const getRoleById = API_BASE + "getRoleById";
export const changeOwnerEmployee = API_BASE + "changeOwner";
//Maintenance
export const viewMaintenance = API_BASE + "viewAllMaintenance";
export const viewErrorMessage = API_BASE + "viewErrorMessage"; 
//Organization
export const viewOrganizations = API_BASE + "viewAllOrganizations";
//Attendance
export const getAllEmployeeAttendance = API_BASE + "getAllEmployeeAttendance";
export const getAllAttendanceDetails = API_BASE + "getAllAttendanceDetails";
export const getAllHolidayDetails = API_BASE + "getAllHolidayDetails";
export const punchIn = API_BASE + "punchIn";
export const punchOut = API_BASE + "punchOut";
export const checkPunchinOrPunchout = API_BASE + "checkPunchinOrPunchout";
export const applyForLeave = API_BASE + "applyForLeave";
export const getAttendanceById = API_BASE + "getAttendanceById";
export const updateAttendance = API_BASE + "updateAttendance";
export const checkHoliday = API_BASE + "checkHoliday";
export const getAttendanceForCalender = API_BASE + "getAttendanceForCalender";
export const getAllLeavesRecord = API_BASE + "getAllLeavesDetails";
export const getAllPendingApproval = API_BASE + "getAllPendingApproval";
export const approveUserLeaveRequest = API_BASE + "approveLeaveRequest";
export const declineUserLeaveRequest = API_BASE + "declineLeaveRequest";
export const getTodaysAttendance = API_BASE + "getTodaysAttendance";
export const getCurrentMonthAttendaceCount = API_BASE + "getCurrentMonthAttendaceCount";
export const getBarChartDetails = API_BASE + "getBarChartDetails";

//Orgnizations Settings
export const addUpdateOrgnizationSettings = API_BASE + "addUpdateOrgnizationSettings";
export const getAllHolidayList = API_BASE + "getAllHolidays";
export const deleteHoliday = API_BASE + "deleteHoliday";
export const addUpdateHoliday = API_BASE + "addUpdateHoliday";
export const getHolidayId = API_BASE + "getHolidayById";
export const getOrgnizationSettingStatus = API_BASE + "getOrgnizationSettingStatus";
export const getAllDayAndWorkingTime = API_BASE + "getAllDayAndWorkingTime";
//Payslips
export const generatePayslip = API_BASE + "generatePayslip";
//Notifications
export const getAllNotificationRecord = API_BASE + "getAllNotification";
export const getNotificationCountRecord = API_BASE + "getNotificationCount";
export const notificationSeen = API_BASE + "notificationSeen";
export const getAllCategorizeNotification = API_BASE + "getAllCategorizeNotification";
//Bearar Token
export const isTokenValid = API_BASE + "isTokenValid";

//Logout
export const LogOut = API_BASE + "logout";