import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import { Archive, Edit, Key, AssignmentInd } from '@mui/icons-material';
import MUIGrid from '../../Controller/MUIGrid';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import ConformationDialog from '../../Controller/ConformationDialog';
import { deleteEmployee, changeUserPassword, changeOwnerEmployee } from '../../Components/Employee/EmployeeComponent.js';
import Messages from '../../Helpers/app.label';
import * as Common from '../../Controller/Common';
import Tooltip from '@mui/material/Tooltip';


const Employee = () => {
    document.title = 'Employee | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState('');
    const [employeesRowId, setEmployeesRowId] = useState('');
    const [pageName, setPageName] = useState('');
    const [name, setName] = useState('');

    // const [formData, setFormData] = useState('');
    // const [dialogName, setDialogName] = useState(false);
    // const [dialogButtonName, setDialogButtonName] = useState(false);
    // let intialAddUpdateFormState = { id: "", name: "", middle_name: "", last_name: "", Sex: 1, DOB: "", Phone: "", email: "", Username: "", ProfileImageName: "" }
    const navigate = useNavigate();
    const [passwordData, setPasswordData] = useState("");

    useEffect(() => {
        GetAllEmployees();
    }, []);

    useEffect(() => {
        const url = window.location.pathname;
        const page = url.substring(1); // Remove the leading slash
        setPageName(page);
    }, []);

    const GetAllEmployees = async () => {

        let response = await Get(APIs.getAllUser);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setEmployeeData(response);
        }
    }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 130,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Employee">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>

                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                onClick={() => {
                                    navigate(`/AddUpdateEmployee?id=${params.row.id}`);
                                }}
                            />
                        </div>
                    </Tooltip>
                    {(params.row.IsAccountOwner !== 1) ?
                    <>
                            <Tooltip title="Inactive Employee">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                    <Archive
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteEmployees"
                                    onClick={() => {
                                        setEmployeesRowId(params.row.id);
                                        setName(params.row.name)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                        : <>
                            <Tooltip>
                                <div style={{ marginRight: '25px', display: 'inline-block' }}>

                                </div>
                            </Tooltip>
                        </>}
                    <>
                        <Tooltip title="Change Password">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                <Key
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changePasswordModel"
                                    onClick={() => {
                                        setEmployeesRowId(params.row.id)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                    <>
                        <Tooltip title="Change Ownership">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                <AssignmentInd
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changeOwner"
                                    onClick={() => {
                                        setEmployeesRowId(params.row.id)
                                        setName(params.row.name)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                </>
            ),
        },
        // {
        //     field: 'SRNO', headerName: 'SRNO', width: 55, sortable: false
        // },
        {
            field: 'name',
            headerClassName: 'super-app-theme--header',
            headerName: 'First Name',
            width: 140,
            editable: false,
        }, {
            field: 'last_name',
            headerClassName: 'super-app-theme--header',
            headerName: 'Last Name',
            width: 140,
            editable: false,
        }, {
            field: 'Phone',
            headerClassName: 'super-app-theme--header',
            headerName: 'Phone',
            width: 130,
            editable: false,
        }, {
            field: 'email',
            headerClassName: 'super-app-theme--header',
            headerName: 'Email',
            width: 150,
            editable: false,
        }, {
            field: 'JobTitle',
            headerClassName: 'super-app-theme--header',
            headerName: 'Job Title',
            width: 150,
            editable: false,
        }, {
            field: 'IsActive',
            headerClassName: 'super-app-theme--header',
            headerName: 'Status',
            width: 80,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 1 ? 'success' : 'warning'}`}>
                    {params.value === 1 ? 'Active' : 'InActive'}
                </span>
            ),
        }, {
            field: 'RecentLogin',
            headerClassName: 'super-app-theme--header',
            headerName: 'Recent Login',
            width: 165,
            editable: false,
        }, {
            field: 'CreateDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Create Date',
            width: 165,
            editable: false,
        }, {
            field: 'ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Modified Date',
            width: 165,
            editable: false,
        },


    ];

    const rowsWithSrno = Array.from(employeeData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    // Change user password
    const changePassword = async () => {
        setIsLoading(true);
        try {
            const response = await changeUserPassword(employeesRowId, passwordData);

            if (response.Status === 200) {
                setIsLoading(false);
                toast.success("Password successfully updated");
                setPasswordData("");
                Common.CloseDialog('changePasswordModel');
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }

    //Handle delete employee
    const handleDeleteEmployee = async () => {
        setIsLoading(true);

        try {
            const response = await deleteEmployee(employeesRowId);

            if (response.Result === true) {
                setIsLoading(false);
                toast.success("User succefully inactived");
                GetAllEmployees();
            } else if (response.Result.original.Status === 300) {
                setIsLoading(false);
                toast.warning(Messages.ADMIN_CAN_NOT_BE_DELETE);
            }
        } catch (error) {
            toast.error("error");
        }
        setIsLoading(false);
    }

    const changeOwnershipEmployee = async () => {
        setIsLoading(true);
        try {
            const response = await changeOwnerEmployee(employeesRowId);
            if (response.Result === true) {
                setIsLoading(false);
                toast.success(Messages.OWNER_CHANGE_SUCCESS_MSG);
                GetAllEmployees();
            } else if (response.Result.original.Status === 300) {
                setIsLoading(false);
                toast.warning(Messages.OWNER_NOT_CHANGE_WARNING);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Employees</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Employees</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <NavLink className="btn btn-warning px-5" to="/Roles">
                                        {/* <i className="fa-solid fa-file-plus me-2"></i> */}
                                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                        Roles
                                    </NavLink>

                                    <NavLink className="btn btn-primary px-5 mx-2" to="/AddUpdateEmployee">
                                        <i className="fa-solid fa-plus me-2"></i> Add Employee
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />
                        {isLoading ? <ShowLoadingIcon /> : ''}

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure to delete this Employee ?"} pageName={"Delete"+ " " + pageName}  onOkayClick={handleDeleteEmployee} id={"deleteEmployees"} />
                        <ConformationDialog msg={"Are you sure to Change Ownership to this Employee !"} pageName={"Change Ownership"+ " " +pageName} onOkayClick={changeOwnershipEmployee} id={"changeOwner"} />

                        {/* Here loading icon */}
                        {/* {isLoading ? <ShowLoadingIcon /> : ''} */}
                    </div>
                </div>
            </main>

            {/* Here change password dialog */}
            <div className="modal fade" id="changePasswordModel" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Change Password</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>

                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-body fs-14">
                            <form id="changePasswordForm" encType="multipart/form-data">
                                <div className='row'>
                                    <div className="col-sm-3 col-md-12">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Password
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                        <input onChange={e => setPasswordData({ ...passwordData, password: e.target.value })} className="form-control" id="floatingInputGrid" type="password" placeholder="Password" />
                                    </div>

                                    <div className="col-sm-3 col-md-12 mt-2">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Confirm Password
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                        <input onChange={e => setPasswordData({ ...passwordData, cpassword: e.target.value })} className="form-control" id="floatingInputGrid" type="password" placeholder="Confirm Password" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={changePassword} className="btn btn-primary" id='okayButton' type="button">Submit</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
            {/* end dialog */}
        </>
    )
}

export default Employee