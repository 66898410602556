import React from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
//import { red } from '@mui/material/colors';
// GridRowsProp, GridColDef,

const MUIGrid = ({ Data, columnData }) => {

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    return (
        <>
            <Box sx={{ height: 410, width: '100%' }}>
                <DataGrid
                    rows={Data}
                    columns={columnData}
                    {...(Data.length > 0 ? { loading: !Data.length } : {})}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    density="compact"
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => generateRandom()}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    disableColumnMenu // added prop
                    sx={{
                        boxShadow: 3,
                        fontFamily: "Nunito Sans",
                        color: "#525B75",
                    }}
                />
            </Box>
        </>
    )
}

export default MUIGrid
