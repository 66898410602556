import { Post, Get} from '../Services/HttpClient/HttpRequest';
import * as Constants from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

// Forgot Password Function
export const resetPassword = async (password,token) => {
    let response;
    try {
        const data = new FormData();
        data.append('password',password);
        data.append('token',token);

        response = await Post(Constants.resetPassword, data); // assuming post is imported from another module

        return response; // moved inside the try block
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
};

export const getPasswordStaus = async (token) => {
    let response;
    try {     
        response = await Get(Constants.getPasswordStaus + "/"+ token); 
        return response; // moved inside the try block
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
};