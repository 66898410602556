import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from "react-router-dom";
import * as Component from '../Components/LoginComponent.js';
import { ShowLoadingIcon } from '../Helpers/Loader';
import Messages from '../Helpers/app.label';
import { NavLink } from "react-router-dom";

const Login = () => {

    document.title = 'Login | Codes';

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const SignIn = async () => {
debugger
        setIsLoading(true);
        try {
            const response = await Component.SignIn(formData);

            if (response.message === 'Success!') {
                navigate('/Dashboard');
                localStorage.setItem("token", response.token);
                localStorage.setItem("UserFullName", response.userName);
                localStorage.setItem("UserProfileImage", response.profileImage);
                localStorage.setItem("Email", response.Email);
                localStorage.setItem("IsLineManger", response.IsLineManger);
                localStorage.setItem("IsAccountOwner", response.IsAccountOwner);
                localStorage.setItem("UserId", response.UserId);
                localStorage.setItem("UserRole", response.UserRole);

            } else if (response.message === 'Incorrect!') {
                toast.error(Messages.LOGIN_CREDENTIALS_INCORRECT_MSG);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(Messages.LOGIN_EXCEPTION_MSG);
        }
        setIsLoading(false);
    }

    return (

        <main>

            {isLoading ? <ShowLoadingIcon /> : ''}

            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">
                    <div className="container">
                        <div className="row flex-center min-vh-100 py-5">
                            <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3"><a className="d-flex flex-center text-decoration-none mb-4" href="./">
                                <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block"><img src="Assets/img/icons/CodesTechnology.png" alt="Codes Technology" width="300" /></div>
                            </a>
                                <div className="text-center mb-7">
                                    <h3 className="text-1000">Sign In</h3>
                                    <p className="text-700">Get access to your account</p>
                                </div>
                                {/* <button className="btn btn-phoenix-secondary w-100 mb-3"><span className="fab fa-google text-danger me-2 fs--1"></span>Sign in with google</button><button className="btn btn-phoenix-secondary w-100"><span className="fab fa-facebook text-primary me-2 fs--1"></span>Sign in with facebook</button>
                                <div className="position-relative">
                                    <hr className="bg-200 mt-5 mb-4" />
                                    <div className="divider-content-center">or use email</div>
                                </div> */}
                                <form id="loginForm" encType="multipart/form-data">
                                    <div className="mb-3 text-start"><label className="form-label" htmlFor="email">Email address</label>
                                        <div className="form-icon-container"><input
                                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                                            className="form-control form-icon-input" id="email" type="text" placeholder="name@example.com" /><span className="fas fa-user text-900 fs--1 form-icon"></span></div>
                                    </div>
                                    <div className="mb-3 text-start"><label className="form-label" htmlFor="password">Password</label>
                                        <div className="form-icon-container"><input
                                            onChange={e => setFormData({ ...formData, password: e.target.value })}
                                            className="form-control form-icon-input" id="password" type="password" placeholder="Password" /><span className="fas fa-key text-900 fs--1 form-icon"></span></div>
                                    </div>
                                    <div className="row flex-between-center mb-6">
                                        <div className="col-auto">
                                            <div className="form-check mb-0"><input className="form-check-input" id="basic-checkbox" type="checkbox" /><label className="form-check-label mb-0" htmlFor="basic-checkbox">Remember me</label></div>
                                        </div>
                                        <div className="col-auto">
                                            {/* <NavLink to="/ForgotPassword">Forgot Password?</NavLink> */}
                                            {/* <a href='ForgotPassword'>Forgot Password?</a> */}
                                        </div>

                                        {/* <div className="col-auto"><a className="fs--1 fw-semi-bold" href="#">Forgot Password?</a></div> */}
                                    </div><button type='button' onClick={SignIn} id="login" className="btn btn-primary w-100 mb-3">Sign In</button>
                                </form>
                                {/* <div className="text-center"><a className="fs--1 fw-bold" href="#">Create an account</a></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* for toaster message */}
            <ToastContainer theme="colored" />
        </main>
    )
}

export default Login;