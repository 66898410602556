import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import { Edit } from '@mui/icons-material';
import MUIGrid from '../../Controller/MUIGrid';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import { addUpdateRoleData, getRoleById } from '../../Components/Employee/RoleComponent.js';
import Messages from '../../Helpers/app.label';
import * as Common from '../../Controller/Common';
import Tooltip from '@mui/material/Tooltip';

const Roles = () => {
    document.title = "Roles | Codes";

    const [isLoading, setIsLoading] = useState(false);
    const [roleData, setRoleData] = useState('');
    const [formData, setFormData] = useState('');

    const [dialogName, setDialogName] = useState(false);
    const [dialogButtonName, setDialogButtonName] = useState(false);
   

    let initialAddUpdateFormState = { Id: 0, RoleName: "", IsActive: "1" }
   
    useEffect(() => {
        GetAllRoles();
    }, []);

    const GetAllRoles = async () => {

        let response = await Get(APIs.getAllRoles);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setRoleData(response);
        }
    }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 70,
            sortable: false,
            renderCell: (params) => (
                <>
                <Tooltip title="Edit Role">
                  <div style={{ marginRight: '5px', display: 'inline-block' }}>
                    <Edit
                      fontSize="small"
                      color="#525B75"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#addUpdateRoleModal"
                      onClick={() => {
                        fetchModel(params.row.Id);
                      }}
                    />
                  </div>
                </Tooltip>
              </>
          
                
            ),
        },
               // {
        //     field: 'SRNO', headerName: 'SRNO', width: 75, sortable: false
        // },
        {
            field: 'RoleName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Role Name',
            width: 340,
            editable: false,
        }, {
            field: 'IsActive',
            headerClassName: 'super-app-theme--header',
            headerName: 'Status',
            width: 170,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 1 ? 'primary' : 'warning'}`}>
                    {params.value === 1 ? 'Active' : params.value === 0 ? 'Inactive' : '-'}
                </span>
            ),
        }, {
            field: 'CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Created Date',
            width: 180,
            editable: false,
        }, {
            field: 'ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Modified Date',
            width: 180,
            editable: false,
        },
    ];

    const rowsWithSrno = Array.from(roleData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    const fetchModel = async (id) => {
        setIsLoading(true);
        let dialogName = "";
        let dialogButtonName = "";

        try {
            if (id === 0) {
                dialogName = "Add Role";
                dialogButtonName = "Add";
            } else {
                dialogName = "Update Role";
                dialogButtonName = "Update";

                initialAddUpdateFormState = await getRoleById(id);

                if (!initialAddUpdateFormState) {
                    throw new Error(Messages.Error);
                }
            }

            setDialogName(dialogName);
            setDialogButtonName(dialogButtonName);
            setFormData(initialAddUpdateFormState);
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    }

    const addUpdateRole = async () => {
        setIsLoading(true);

        try {
            const response = await addUpdateRoleData(formData);
            let msg = ['Role Created Successfully!', 'Role Updated Successfully!']

            if (response && msg.includes(response.Message)) {
                setIsLoading(false);
                toast.success(response.Message);
                Common.CloseDialog('addUpdateRoleModal');
                GetAllRoles();
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
    }
    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active"><NavLink to="/Employee">Employees</NavLink></li>
                                <li className="breadcrumb-item active">Roles</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Roles</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => { fetchModel(0) }} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addUpdateRoleModal"><span className="fas fa-plus me-2"></span>Add Role</button>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Here loading icon */}
                        {/* {isLoading ? <ShowLoadingIcon /> : ''} */}
                    </div>
                </div>
            </main>

            {/* here add update role model */}
            <div className="modal fade" id="addUpdateRoleModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">{dialogName}</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>
                        {isLoading ? <ShowLoadingIcon /> : ''}
                        <div className="modal-body">
                            <form id="" encType="multipart/form-data">
                                {formData.Id === 0 ? (
                                    <>
                                        <div className="col-sm-6 col-md-12">
                                            <div className="form-floating">
                                                <input onChange={e => setFormData({ ...formData, RoleName: e.target.value })} value={formData.RoleName} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                <label htmlFor="floatingInputGrid">Role name <span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-9">
                                                <div className="form-floating">
                                                    <input onChange={e => setFormData({ ...formData, RoleName: e.target.value })} value={formData.RoleName} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Role name <span className="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-3 mt-2">
                                                <div className="form-check">
                                                    <input checked={formData.IsActive === 1} onChange={e => setFormData({ ...formData, IsActive: e.target.checked ? 1 : 0 })} className="form-check-input" type="checkbox" id="activeCheckbox" />
                                                    <label className="form-check-label" for="activeCheckbox">Active</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addUpdateRole} className="btn btn-primary" id='okayButton' type="button">{dialogButtonName}</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end dialog */}
        </>
    )
}

export default Roles