import axios from "axios";

//Get API with token 
const Get = async (url) => {

    let result = "";
    try {

        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');
        const response = await axios.get(url) // use await to wait for the response

        if (response && response.status === 200) {
            result = response.data
        }
    } catch (error) {
        ResponseErrors(error);
    }
    return result;
}

//Delete API with token
const Delete = async (url) => {
  
    let result = "";
    try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');
        const response = await axios.delete(url) // use await to wait for the response

        if (response && response.status === 200) {
            result = response.data
        }
    } catch (error) {
        ResponseErrors(error);
    }
    return result;
}

//Post API with token
const Post = async (url, data) => {
   
    let result = "";
    try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');
        const response = await axios.post(url, data) // use await to wait for the response

        if (response && response.status === 200) {
            result = response.data
        }
    } catch (error) {
        result = ResponseErrors(error);
    }
    return result;
}

//LogOut API with token
const LogOut = async (url) => {

    let result = "";
    try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');
        const response = await axios.post(url) // use await to wait for the response

        if (response && response.status === 200) {
            result = response.data
        }
    } catch (error) {
        ResponseErrors(error);
    }
    return result;
}

//Handle responce error 
export const ResponseErrors = (error, isFromLogin = false) => {
    let result = { message: '' }
    switch (error.response.status) {
        case 401:
            result.message = (isFromLogin) ? error.response.data.message : "Something went wrong you should re-login again!"
            break;

        default:
            result.message = "Oops! Something went wrong."
            break;
    }
}

export { Get, Delete,  Post, LogOut }