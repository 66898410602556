import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import MUIGrid from '../../Controller/MUIGrid';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import Tooltip from '@mui/material/Tooltip';
import { Edit } from '@mui/icons-material';
import { addLeaveRequest, checkHolidaysRequest} from '../../Components/AttendanceComponent.js';

import Messages from '../../Helpers/app.label';
import * as Common from '../../Controller/Common';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const ManageLeaves = () => {
    document.title = 'Manage Leaves | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [leaveData, setLeaveData] = useState('');

    const [isHoliday, setIsHoliday] = useState(false);
    const [holidayDates, setHolidayDates] = useState("");
    
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [remark, setRemark] = useState("");

    useEffect(() => {
        GetAllLeaves();
    }, []);

    const GetAllLeaves = async () => {

        let response
        response = await Get(APIs.getAllLeavesRecord);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setLeaveData(response);
        }
    }

    const columns = [
        {
            field: 'C_FromDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'From Date',
            width: 120,
            editable: false,
        },
        {
            field: 'C_ToDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'To Date',
            width: 120,
            editable: false,
        },
        {
            field: 'LeaveCount',
            headerClassName: 'super-app-theme--header',
            headerName: 'Leave Count',
            width: 100,
            editable: false,
        },
        {
            field: 'Status',
            headerClassName: 'super-app-theme--header',
            headerName: 'Leave Status',
            width: 105,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 2 ? 'success' : params.value === 3 ? 'danger' : params.value === 1 ? 'warning' : ""}`}>
                    {params.value === 2 ? 'Approved' : params.value === 3 ? 'Declined' : params.value === 1 ? 'Pending' : ""}
                </span>
            ),
        },
        {
            field: 'EmpRemarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Employee Remarks',
            width: 180,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ||  params.value === "undefined" ||  params.value === undefined ? "" : params.value;
            },
        },
        {
            field: 'LineManagerRemarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Line Manager Remark',
            width: 180,
            editable: false,
            valueFormatter: (params) => {
                return params.value === null || params.value === "null" ||  params.value === "undefined" ||  params.value === undefined ? "" : params.value;
            },
        },
        {
            field: 'C_CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Created Date',
            width: 170,
            editable: false,
        },
        {
            field: 'C_UpdatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Updated Date',
            width: 170,
            editable: false,
        }
    ];

    const rowsWithSrno = Array.from(leaveData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    const checkHolidays = async (e) => {

        setEndDate(e.target.value);
        setIsHoliday(false);
        setHolidayDates("");
        setIsLoading(true);
       // setIsLeaveButtonLoading(false);

        try {
            let response = await checkHolidaysRequest(startDate, e.target.value);

            if (response && response.Holidays !== "") {
                setIsHoliday(true);
                setHolidayDates(response.Holidays);
            } else {

            }
        } catch (error) {

        }

        setIsLoading(false);
        //setIsLeaveButtonLoading(true);
    };

    
    const addLeave = async () => {

        setIsLoading(true);
        //setIsLeaveButtonLoading(false);

        try {
            let response = await addLeaveRequest(startDate, endDate, remark);
            let msg = ['You have successfully apply for leave']
            let warnMsg = ["Already you have complete activity on given day for more information check details!"]

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog('leaveModel');
                GetAllLeaves();
                setStartDate("");
                setEndDate("");
                setRemark("");
                setIsHoliday(false);
            } else if (response && warnMsg.includes(response.Message)) {
                toast.warning(response.Message);
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
        //setIsLeaveButtonLoading(true);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">
                                    <NavLink to="/Attendance"
                                        style={{
                                            color: '#3874ff',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = '#1d55ff';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = '#3874ff';
                                        }}
                                    >
                                        Attendance
                                    </NavLink>
                                </li>

                                <li className="breadcrumb-item active">Manage Leaves</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Manage Leaves</h2>
                            </div>
                            <div className="col-auto">
                                <button  onClick={() => {
                                            setStartDate("");
                                            setEndDate("");
                                            setRemark("");}} 
                                        className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#leaveModel">
                                    <span className="fas fa-plus me-2"></span>Leave
                                </button>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                    </div>
                </div>
            </main>

            {/* Here apply leave dialog */}
            <div className="modal fade" id="leaveModel" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">Apply for leave</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>

                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-body fs-14">
                            <form id="leave" encType="multipart/form-data">

                                {isHoliday && (
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert variant="outlined" severity="warning">
                                            You have selected the Public Holiday’s date(s) - <br></br> {holidayDates}
                                        </Alert>
                                    </Stack>
                                )}

                                <div className="col-sm-3 col-md-12">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">
                                        From<span style={{ color: "red" }}> *</span>
                                    </label>
                                    <input
                                        // value={sDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        value={startDate}
                                        className="form-control"
                                        id="floatingInputGrid"
                                        type="date"
                                        placeholder='DD-MM-YYYY'
                                    />
                                </div>

                                <div className="col-sm-3 col-md-12 mt-2">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">
                                        To<span style={{ color: "red" }}> *</span>
                                    </label>
                                    <input
                                        onChange={checkHolidays}
                                        value={endDate}
                                        className="form-control"
                                        id="floatingInputGrid"
                                        type="date"
                                        placeholder='DD-MM-YYYY'
                                    />
                                </div>

                                <div className="col-sm-3 col-md-12 mt-2">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Remark<span style={{ color: "red" }}></span></label>
                                    <textarea onChange={(e) => setRemark(e.target.value)} value={remark} placeholder="Please provide the reason for your leave request ?" className="form-control" id="floatingProjectOverview"></textarea>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addLeave} className="btn btn-primary" id='okayButton' type="button">Submit</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
            {/* end dialog */}
        </>
    )
}

export default ManageLeaves    
