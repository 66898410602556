import React from 'react'

const Footer = () => {

    return (
        <>
            <footer className="footer position-absolute">
                <div className="row g-0 justify-content-between align-items-center h-100">
                    <div className="col-12 col-sm-auto text-center nav-item">
                        {/* <p className="mb-0 mt-2 mt-sm-0 text-900">© 2023 All Rights Reserved And Developed By<a className="mx-1" href="https://codestec.com/">Codes Technology</a></p> */}
                        <div className="navbar-vertical-footer">
                            <div className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
                                <span className="navbar-vertical-footer-text">© 2023 All Rights Reserved And Developed By<a className="mx-1" href="https://codestec.com/">Codes Technology</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-auto text-center">
                        {/* <p className="mb-0 text-600">v1.0.0</p> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
