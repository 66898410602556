import { Delete, Post,  Get } from '../Services/HttpClient/HttpRequest';
import * as Constants from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import * as APIs from '../Helpers/app.constant.api';
import Messages from '../Helpers/app.label';

// Get All Categories from Database
export const getCategories = async () => {
    let result = "";
    try {
        const response = await Get(APIs.viewBlogCategories)

        if (response !== null) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

//Get Category 
export const getCategoryById = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getCategoryById + "/" + id);

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

// Validate blog vvategory form
const validateBlogCategory = (formData) => {
    let result = { message: '' }
    if (!formData.Name) {
        result.message = "Blog category name is required!"
    } 

    return result;
}

// Add Update Blog Ctaegory Details
export const addUpdateBlogCategory = async (formData) => {
    let response = validateBlogCategory(formData);

    if(!response.message) {
        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('Name', formData.Name);

            response = await Post(Constants.addUpdateBlogCategories, data);

        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
        return response
    }
    
}

// Delete Blog Category
export const DeleteBlogCatagory = async (id) => {

    let response = "";
    try {
        response = await Delete((Constants.deleteBlogCategories + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}
