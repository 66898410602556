import React, { useEffect, useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import * as APIs from '../Helpers/app.constant.api';
import { Get, Post } from '../Services/HttpClient/HttpRequest';
import { ImageSrcURL } from '../Helpers/app.constant.api';
import { ShowLoadingIcon } from '../Helpers/Loader';

const Notifications = () => {

    document.title = 'Notifications | Codes';

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [notificationData, setNotificationData] = useState('');

    const [todayNotification, setTodayNotification] = useState([]);
    const [yesterdayNotification, setYesterdayNotification] = useState('');
    const [olderNotification, setOlderNotification] = useState('');

    useEffect(() => {
        GetAllNotification();
    }, []);

    const GetAllNotification = async () => {

        setIsLoading(true);
        let response
        response = await Get(APIs.getAllCategorizeNotification);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setNotificationData(response.Notifications.AllNotifications);

            setTodayNotification(response.Notifications.Today);
            setYesterdayNotification(response.Notifications.Yesterday);
            setOlderNotification(response.Notifications.Older);
        }
        setIsLoading(false);
    }

    const notificationSeen = async (Id, URL) => {

        setIsLoading(true);
        let response
        response = await Post(APIs.notificationSeen + "/" + Id);

        if (!response) {
            navigate("/" + URL);
        } else {
            navigate("/" + URL);
        }
        setIsLoading(false);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Notifications</li>
                            </ol>
                        </nav>
                        {/* <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto"> */}
                        <h2 className="text-bold text-1100 mb-5">Notifications</h2>
                        {/* </div>
                        </div> */}
                        {/* <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300"> */}
                        {notificationData.length === 0 ? (
                            <h3
                                className="fw-normal text-900 text-bold text-1100 mb-5"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "200%",
                                    textAlign: "center",
                                    backgroundColor: "#f7f7f7", // Add a background color for better visibility
                                    borderRadius: "10px", // Add rounded corners
                                    padding: "16px", // Add some padding
                                }}
                            >
                                No notifications found!
                            </h3>
                        ) : ("")}

                        {(todayNotification.length !== 0) ? (
                            <>
                                <h5 class="text-black mb-3">Today</h5>
                                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300">
                                    {Array.from(todayNotification).map((notificationItem, index) => (
                                        <a onClick={() => notificationSeen(notificationItem.Id, notificationItem.URL)} style={{ cursor: 'pointer' }}>
                                            <div class={`${notificationItem.ReadStatus === 1 ? "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top unread" : "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top read"}`}>
                                                <div class="d-flex">
                                                    <div class="avatar avatar-xl me-3">
                                                        <img className="rounded-circle" alt="" src={`${ImageSrcURL}/${notificationItem.ProfileImageName !== "" ? notificationItem.ProfileImageName : ""}`} />
                                                    </div>
                                                    <div class="me-3 flex-1 mt-2">
                                                        <h4 className="fs--1 text-black">{notificationItem.Full_Name}</h4>
                                                        <p class="fs--1 text-1000"><span class='me-1'>💬</span>{notificationItem.Description}<span class="fw-bold"></span><span class="ms-2 text-600 fw-bold fs--2"></span></p>
                                                        <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold">{notificationItem.FormattedTime}</span> {notificationItem.FormattedDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </>
                        ) : ("")}

                        {(yesterdayNotification.length !== 0) ? (
                            <>
                                <h5 class="text-black mb-3">Yesterday</h5>
                                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300">
                                    {Array.from(yesterdayNotification).map((notificationItem, index) => (
                                        <a onClick={() => notificationSeen(notificationItem.Id, notificationItem.URL)} style={{ cursor: 'pointer' }}>
                                            <div class={`${notificationItem.ReadStatus === 1 ? "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top unread" : "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top read"}`}>
                                                <div class="d-flex">
                                                    <div class="avatar avatar-xl me-3">
                                                        <img className="rounded-circle" alt="" src={`${ImageSrcURL}/${notificationItem.ProfileImageName !== "" ? notificationItem.ProfileImageName : ""}`} />
                                                    </div>
                                                    <div class="me-3 flex-1 mt-2">
                                                        <h4 className="fs--1 text-black">{notificationItem.Full_Name}</h4>
                                                        <p class="fs--1 text-1000"><span class='me-1'>💬</span>{notificationItem.Description}<span class="fw-bold"></span><span class="ms-2 text-600 fw-bold fs--2"></span></p>
                                                        <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold">{notificationItem.FormattedTime}</span> {notificationItem.FormattedDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </>
                        ) : ("")}

                        {(olderNotification.length !== 0) ? (
                            <>
                                <h5 class="text-black mb-3">Older</h5>
                                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300">
                                    {Array.from(olderNotification).map((notificationItem, index) => (
                                        <a onClick={() => notificationSeen(notificationItem.Id, notificationItem.URL)} style={{ cursor: 'pointer' }}>
                                            <div class={`${notificationItem.ReadStatus === 1 ? "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top unread" : "d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top read"}`}>
                                                <div class="d-flex">
                                                    <div class="avatar avatar-xl me-3">
                                                        <img className="rounded-circle" alt="" src={`${ImageSrcURL}/${notificationItem.ProfileImageName !== "" ? notificationItem.ProfileImageName : ""}`} />
                                                    </div>
                                                    <div class="me-3 flex-1 mt-2">
                                                        <h4 className="fs--1 text-black">{notificationItem.Full_Name}</h4>
                                                        <p class="fs--1 text-1000"><span class='me-1'>💬</span>{notificationItem.Description}<span class="fw-bold"></span><span class="ms-2 text-600 fw-bold fs--2"></span></p>
                                                        <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold">{notificationItem.FormattedTime}</span> {notificationItem.FormattedDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </>
                        ) : ("")}

                        {/* </div> */}
                        {/* Added Footer File  */}
                        <Footer />
                        {/* for toaster message */}
                        <ToastContainer theme="colored" />
                        {isLoading ? <ShowLoadingIcon /> : ''}
                    </div>
                </div>
            </main>
        </>
    )
}

export default Notifications
