import * as APIs from '../Helpers/app.constant.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LogOut } from '../Services/HttpClient/HttpRequest';

const SignOut = async () => {
  
  let result = "";
  try {
    const response = await LogOut(APIs.LogOut);

    if (response) {
      localStorage.removeItem('token');
      localStorage.removeItem('employeeName');
      result = "LogOut_Success"
    }
  } catch (error) {
    toast.error("Error occured while logout!");
  }
  return result;
}

export { SignOut }

