import React from "react";
import { useState, useEffect } from "react";
import Messages from "../../Helpers/app.label";
import { ToastContainer, toast } from 'react-toastify';
import * as Component from "../../Components/ResetPasswordComponent";
import { ShowLoadingIcon } from "../../Helpers/Loader";
import { useNavigate } from "react-router-dom";




const ResetPassword = () => {
  document.title = 'Forgot Password | Codes';
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate('/');
    }
    if (token) {
      try {
        const getPasswordStatus = async () => {
          const response = await Component.getPasswordStaus(token);
          if (response.Status === 200) {
            navigate('/');
          }
        };
        getPasswordStatus();
      } catch (error) {
        toast.error(Messages.SOMETHING_WENT_WRONG);
      }
    }
  }, [token, navigate]);


  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      setIsLoading(true);
      const response = await Component.resetPassword(password, token);

      if (response.result === true) {
        setIsLoading(false);
        navigate('/');
        toast.success(Messages.RESET_PASSWORD_SUCCESS_MSG);
        setPassword("");
        setConfirmPassword("");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(Messages.SOMETHING_WENT_WRONG);
      setIsLoading(false);
    };
  };

  return (
    <>
      <main className="main" id="top">
        {/* for toaster message */}
        <ToastContainer theme="colored" />
        {isLoading ? <ShowLoadingIcon /> : ''}
        <div className="container-fluid px-0" data-layout="container">
          <div className="row vh-100 flex-cente g-0">
            <div className="col-lg-6 position-relative d-none d-lg-block">
              <div
                className="bg-holder"
                style={{ backgroundImage: "url(../../../assets/img/bg/35.png)" }}
              ></div>
            </div>
            <div className="col-lg-6">
              <div className="row flex-center h-100 g-0 px-4 px-sm-0">
                <div className="col col-sm-6 col-lg-7 col-xl-6">
                  <a className="d-flex flex-center text-decoration-none mb-4" href="../../../index.html">
                    <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                      <img src="../../../assets/img/icons/CodesTechnology.png" alt="phoenix" width="200" />
                    </div>
                  </a>
                  <div className="text-center mb-6">
                    <h4 className="text-1000">Reset new password</h4>
                    <p className="text-700">Type your new password</p>
                    <form className="mt-5" >
                      <input
                        className="form-control mb-2"
                        id="password"
                        type="password"
                        placeholder="Type new password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <input
                        className="form-control mb-4"
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                      />
                      <button className="btn btn-primary w-100" type="button" onClick={handleSubmit}>
                        Set Password
                      </button>
                    </form>
                    {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ResetPassword;
