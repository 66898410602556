import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { ImageSrcURL } from '../Helpers/app.constant.api';
import { Post, Get } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import { ToastContainer, toast } from 'react-toastify';
import { ShowLoadingIcon } from '../Helpers/Loader';

const Dashboard = () => {

    document.title = 'Dashboard | Codes';

    const [greeting, setGreeting] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTodaysAttendanceLoading, setIsTodaysAttendanceLoading] = useState(false);

    useEffect(() => {
        CheckPunchinOrPunchout();
        GetTodaysAttendance();
        GetCurrentMonthAttendaceCount();
        GetBarChartData();

        const currentHour = new Date().getHours();

        if (currentHour >= 5 && currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour >= 12 && currentHour < 17) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }
    }, []);

    const [punchOutVisible, setPunchOutVisible] = useState(true);        // for punchout buuton 
    const [disablePunchButtons, setDisablePunchButton] = useState(true); // for punchin and punchout buttons
    const [disableButton, setDisableButton] = useState(false);           // for 3 buttons

    //Check punch in, punch out and leave button visible or disable
    const CheckPunchinOrPunchout = async () => {
        setIsTodaysAttendanceLoading(true);
        let response = await Post(APIs.checkPunchinOrPunchout);

        if (response && response.Rusult === "IsPunchedIn") {
            setPunchOutVisible(true);
            setDisableButton(true);
        } else if (response && response.Rusult === "IsPunchedOut") {
            setPunchOutVisible(false);
            setDisableButton(true);
        } else if (response && response.Rusult === "TodaysActivityCompleted") {
            setDisablePunchButton(false);
            setDisableButton(true);
        } else if (response && response.Rusult === "TodaysLeave") {
            setDisablePunchButton(false);
            setDisableButton(true);
        } else {
            setDisableButton(true);
        }
        const currentDate = new Date();
        const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
        if (isWeekend) {
            setDisableButton(false)
        }
        setIsTodaysAttendanceLoading(false);
    }

    const PunchIn = async () => {

        setIsTodaysAttendanceLoading(true);
        try {
            let response = await Post(APIs.punchIn);
            let msg = ['You have successfully punched-in']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                setPunchOutVisible(false);
                CheckPunchinOrPunchout();
                GetTodaysAttendance();
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsTodaysAttendanceLoading(false);
    }

    const PunchOut = async () => {
        setIsTodaysAttendanceLoading(true);

        try {
            let response = await Post(APIs.punchOut);
            let msg = ['You have successfully punched-out']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                setPunchOutVisible(true);
                CheckPunchinOrPunchout();
                GetTodaysAttendance();
            } else {
                toast.error(response.Message);
            }

        } catch (error) {
            toast.error("Something went wrong!");
        }
        setIsTodaysAttendanceLoading(false);
    }

    const [punchInTime, setPunchInTime] = useState("--:--");
    const [punchOutTime, setPunchOutTime] = useState("--:--");
    const [lastPunchInTime, setLastPunchInTime] = useState("--:--");
    const [lastDuration, setLastDuration] = useState("--:--");
    const [attendanceStatus, setAttendanceStatus] = useState("--:--");

    //Check punch in, punch out and leave button visible or disable
    const GetTodaysAttendance = async () => {
        setIsTodaysAttendanceLoading(true);

        let response = await Get(APIs.getTodaysAttendance);

        if (response && response.PunchInTime !== "") {
            setPunchInTime(response.PunchInTime);
        }
        if (response && response.PunchOutTime !== "") {
            setPunchOutTime(response.PunchOutTime);
        }
        if (response && response.LastPunchInTime !== "") {
            setLastPunchInTime(response.LastPunchInTime);
        }
        if (response && response.LastDuration !== "") {
            setLastDuration(response.LastDuration);
        }

        const today = new Date();
        const options = { weekday: 'long' };
        const dayName = today.toLocaleDateString('en-US', options);
        setAttendanceStatus(dayName);

        setIsTodaysAttendanceLoading(false);
    }

    const [presentPercentage, setPresentPercentage] = useState(0);
    const [leavePercentage, setLeavePercentage] = useState(0);
    const [halfDayPercentage, setHalfDayPercentage] = useState(0);
    const [daysDifference, setDaysDifference] = useState(0);

    const GetCurrentMonthAttendaceCount = async () => {

        let response = await Get(APIs.getCurrentMonthAttendaceCount);

        if (response) {
            setPresentPercentage(response.presentPercentage);
            setLeavePercentage(response.leavePercentage);
            setHalfDayPercentage(response.halfDayPercentage);
            setDaysDifference(response.daysDifference);
        }
    }

    const data = [
        { label: 'Present', value: presentPercentage },
        { label: 'Leave', value: leavePercentage },
        { label: 'Half Day', value: halfDayPercentage },
    ];

    const chartSetting = {
        xAxis: [
            {
                label: 'Working Hours',
            },
        ],
        width: 500,
        height: 400,
    };

    const [barChartData, setBarChartData] = useState([{
        hours: 0,
        day: 'Mon',
    },
    {
        hours: 0,
        day: 'Tue',
    },
    {
        hours: 0,
        day: 'Wed',
    },
    {
        hours: 0,
        day: 'Thu',
    },
    {
        hours: 0,
        day: 'Fri',
    },]);
   
    const valueFormatter = (value: number) => `${value} hours`;

    const GetBarChartData = async () => {

        let response = await Get(APIs.getBarChartDetails);

        if (response && response.length !== 0) {
            setBarChartData(response);
        }
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <div className="row gy-3 mb-6 justify-content-between">
                            <div className="col-md-9 col-auto">
                                <h2 className="mb-2 text-1100">{greeting}, {localStorage.getItem('UserFullName') ? localStorage.getItem('UserFullName') : ""} !</h2>
                            </div>
                        </div>

                        <div className="row gy-1 gx-xxl-6 mt-1">
                            <div className="col-lg-5 col-xl-4 order-1 order-lg-0" style={{ height: '350px' }}>

                                <div className="card cover-image mb-5">
                                    <div className="card-header hover-actions-trigger position-relative mb-7" style={{ minHeight: '65px' }}>
                                        <div className="bg-holder" style={{ backgroundImage: `linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%), url(../../assets/img/generic/59.png)`, }}>
                                        </div>
                                        <label className="avatar avatar-4xl feed-avatar-profile" htmlFor="upload-porfile-picture">
                                            <img className="rounded-circle img-thumbnail bg-white shadow-sm" src={`${ImageSrcURL}/${localStorage.getItem('UserProfileImage') !== "" ? localStorage.getItem('UserProfileImage') : ""}`} width="200" alt="" />
                                        </label>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex flex-wrap mb-3 align-items-center">
                                                    <h3 className="me-2">{localStorage.getItem('UserFullName') ? localStorage.getItem('UserFullName') : ""}</h3><br></br>
                                                    <span className="fw-normal fs-0">({localStorage.getItem('UserRole') ? localStorage.getItem('UserRole') : ""})</span>
                                                </div>

                                                <p className="fw-semi-bold mb-0">About me</p>
                                                <p className="text-700 mb-0">“I am {localStorage.getItem('UserRole') ? localStorage.getItem('UserRole') : ""} at Codes Technology, We aim to create a positive impact and achieve excellence in every endeavor.” </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 col-xl-4 order-1 order-lg-0" style={{ height: '350px' }}>
                                <div className="card h-100 hover-actions-trigger">
                                    <div className="card-body">
                                        {isTodaysAttendanceLoading ? <ShowLoadingIcon /> : ''}

                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-2 line-clamp-1 lh-sm flex-1 me-5">Today's Attendance</h4>
                                        </div><span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-success"><span className="badge-label">{attendanceStatus}</span></span>
                                        <div className="d-flex align-items-center mb-2">
                                            <p className="fw-bold mb-0 lh-1">Punch In &nbsp;&nbsp;&nbsp;: <span className="ms-1 text-1100"> {punchInTime}</span></p>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <p className="fw-bold mb-0 lh-1">Punch Out : <span className="ms-1 text-1100"> {punchOutTime}</span></p>
                                        </div>
                                        <div className="d-flex justify-content-between text-700 fw-semi-bold">
                                            <p className="mb-2"> Progress</p>
                                            <p className="mb-2 text-1100">100%</p>
                                        </div>
                                        <div className="progress bg-success-100">
                                            <div className="progress-bar rounded bg-success" role="progressbar" aria-label="Success example" style={{ width: '100px' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div className="d-flex align-items-center mt-4">
                                            <p className="mb-0 fw-bold fs--1">Last Punch In :<span className="fw-semi-bold text-600 ms-1"> {lastPunchInTime}</span></p>
                                        </div>
                                        <div className="d-flex align-items-center mt-2">
                                            <p className="mb-0 fw-bold fs--1">Last Working Day Hours : <span className="fw-semi-bold text-600 ms-1"> {lastDuration}</span></p>
                                        </div>
                                        <div className="d-flex justify-content-end mt-4">
                                            {disablePunchButtons === true ? (
                                                <>
                                                    {punchOutVisible === true ? (
                                                        <button onClick={PunchIn} className="btn btn-primary" type="button">Punch In</button>
                                                    ) : (
                                                        <button onClick={PunchOut} className="btn btn-danger" type="button">Punch Out</button>
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 col-xl-4 order-1 order-lg-0" style={{ height: '350px' }}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="mb-2">Current Month Attendance</h4>
                                                <h6 className="text-700">Last {daysDifference} days</h6>
                                            </div>
                                        </div>
                                        <div className="pb-4 pt-3" style={{ paddingLeft: '60px' }}>
                                            <PieChart
                                                colors={['#0000FF', '#FF5733', '#FFA500']}
                                                series={[
                                                    {
                                                        paddingAngle: 5,
                                                        innerRadius: 60,
                                                        outerRadius: 80,
                                                        data,
                                                    },
                                                ]}
                                                margin={{ right: 40 }}
                                                width={200}
                                                height={170}
                                                legend={{ hidden: true }}
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="bullet-item me-2" style={{ backgroundColor: '#0000FF' }}></div>
                                                <h6 className="text-900 fw-semi-bold flex-1 mb-0">Present</h6>
                                                <h6 className="text-900 fw-semi-bold mb-0">{presentPercentage}%</h6>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="bullet-item me-2" style={{ backgroundColor: '#FF5733' }}></div>
                                                <h6 className="text-900 fw-semi-bold flex-1 mb-0">Leave</h6>
                                                <h6 className="text-900 fw-semi-bold mb-0">{leavePercentage}%</h6>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="bullet-item me-2" style={{ backgroundColor: '#FFA500' }}></div>
                                                <h6 className="text-900 fw-semi-bold flex-1 mb-0">Half Day</h6>
                                                <h6 className="text-900 fw-semi-bold mb-0">{halfDayPercentage}%</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 gx-xxl-2">
                            <div className="col-12 col-xl-6 col-xxl-7">
                                <div className="card todo-list h-100">
                                    <div className="card-header border-bottom-0 pb-0">
                                        <div className="row justify-content-between align-items-center mb-4">
                                            <div className="col-auto">
                                                <h3 className="text-1100">To do</h3>
                                                <p className="mb-0 text-700">Task assigned to me</p>
                                            </div>
                                            <div className="col-auto w-100 w-md-auto">
                                                <div className="row align-items-center g-0 justify-content-between">
                                                    <div className="col-12 col-sm-auto">
                                                        <div className="search-box w-100 mb-2 mb-sm-0" style={{ maxWidth: '30rem' }}>
                                                            <form className="position-relative" data-bs-toggle="search" data-bs-display="static"><input className="form-control search-input search" type="search" placeholder="Search tasks" aria-label="Search" />
                                                                <span className="fas fa-search search-box-icon"></span>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto d-flex">
                                                        <p className="mb-0 ms-sm-3 fs--1 text-700 fw-bold"><span className="fas fa-filter me-1 fw-extra-bold fs--2"></span>23 tasks</p><button className="btn btn-link p-0 ms-3 fs--1 text-primary fw-bold text-decoration-none"><span className="fas fa-sort me-1 fw-extra-bold fs--2"></span>Sorting</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body py-0 scrollbar to-do-list-body">
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-0" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Designing the dungeon</label>
                                                        <div className="badge badge-phoenix ms-auto fs--2 badge-phoenix-primary"><span className="fw-bold badge-label">DRAFT</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>2</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-1" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Hiring a motion graphic designer</label>
                                                        <div className="badge badge-phoenix ms-auto fs--2 badge-phoenix-warning"><span className="fw-bold badge-label">URGENT</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>2</button><button className="btn p-0 text-warning fs--2 me-2"><span className="fas fa-tasks me-1"></span>3</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-2" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Daily Meetings Purpose, participants</label>
                                                        <div className="badge badge-phoenix ms-auto fs--2 badge-phoenix-info"><span className="fw-bold badge-label">ON PROCESS</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>4</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Dec, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">05:00 AM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-3" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Finalizing the geometric shapes</label></div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>3</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-4" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Daily meeting with team members</label></div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center">
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">1 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-5" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Daily Standup Meetings</label></div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center">
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">13 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">10:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-6" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Procrastinate for a month</label>
                                                        <div className="badge badge-phoenix ms-auto fs--2 badge-phoenix-info"><span className="fw-bold badge-label">ON PROCESS</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>3</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-7" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">warming up</label>
                                                        <div className="badge badge-phoenix ms-auto fs--2 badge-phoenix-info"><span className="fw-bold badge-label">CLOSE</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>3</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">12 Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">12:00 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="d-flex hover-actions-trigger py-3 border-top border-bottom"><input className="form-check-input form-check-input-todolist flex-shrink-0 my-1 me-2 form-check-input-undefined" type="checkbox" id="checkbox-todo-8" data-event-propagation-prevent="data-event-propagation-prevent" />
                                            <div className="row justify-content-between align-items-md-center btn-reveal-trigger border-200 gx-0 flex-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="mb-1 mb-md-0 d-flex align-items-center lh-1"><label className="form-check-label mb-1 mb-md-0 mb-xl-1 mb-xxl-0 fs-0 me-2 line-clamp-1 text-900">Make ready for release</label></div>
                                                </div>
                                                <div className="col-12 col-md-auto col-xl-12 col-xxl-auto">
                                                    <div className="d-flex lh-1 align-items-center"><button className="btn p-0 text-700 fs--2 me-2"><span className="fas fa-paperclip me-1"></span>2</button>
                                                        <p className="text-700 fs--2 mb-md-0 me-2 me-md-3 me-xl-2 me-xxl-3 mb-0">2o Nov, 2021</p>
                                                        <div className="hover-md-hide hover-xl-show hover-xxl-hide">
                                                            <p className="text-700 fs--2 fw-bold mb-md-0 mb-0 ps-md-3 ps-xl-0 ps-xxl-3 border-start-md border-xl-0 border-start-xxl border-300">1:00 AM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-none d-md-block d-xl-none d-xxl-block end-0 position-absolute" style={{ top: '23%' }} data-event-propagation-prevent="data-event-propagation-prevent">
                                                <div className="hover-actions end-0" data-event-propagation-prevent="data-event-propagation-prevent"><button className="btn btn-phoenix-secondary btn-icon me-1 fs--2 text-900 px-0 me-1" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-edit"></span></button><button className="btn btn-phoenix-secondary btn-icon fs--2 text-danger px-0" data-event-propagation-prevent="data-event-propagation-prevent"><span className="fas fa-trash"></span></button></div>
                                            </div>
                                        </div>
                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content bg-soft overflow-hidden">
                                                    <div className="modal-header px-6 py-5 border-300 pe-sm-5 px-md-6">
                                                        <h3 className="text-1000 fw-bolder mb-0">Designing the Dungeon Blueprint</h3><button className="btn btn-phoenix-secondary btn-icon btn-icon-xl flex-shrink-0" type="button" data-bs-dismiss="modal" aria-label="Close"><span className="fa-solid fa-xmark"></span></button>
                                                    </div>
                                                    <div className="modal-body bg-100 px-6 py-0">
                                                        <div className="row gx-14">
                                                            <div className="col-12 col-lg-7 border-end-lg border-300">
                                                                <div className="py-6">
                                                                    <div className="mb-7">
                                                                        <div className="d-flex align-items-center mb-3">
                                                                            <h4 className="text-900 me-3">Description</h4><a className="btn btn-link text-decoration-none p-0" href="#!"><span className="fa-solid fa-pen"></span></a>
                                                                        </div>
                                                                        <p className="text-1000 mb-0">The female circus horse-rider is a recurring subject in Chagall’s work. In 1926 the art dealer Ambroise Vollard invited Chagall to make a project based on the circus. They visited Paris’s historic Cirque d’Hiver Bouglione together; Vollard lent Chagall his private box seats. Chagall completed 19 gouaches Chagall’s work. In 1926 the art dealer Ambroise Vollard invited Chagall to make a project based on the circus.</p>
                                                                    </div>
                                                                    <div className="mb-7">
                                                                        <h4 className="mb-3">Subtasks</h4>
                                                                        <div className="d-flex flex-between-center hover-actions-trigger border-300 py-3 border-top">
                                                                            <div className="form-check mb-1 mb-md-0 d-flex align-items-center lh-1 min-h-auto"><input className="form-check-input form-check-line-through mt-0 me-3" type="checkbox" id="subtaskundefined1" /><label className="form-check-label mb-0 fs-0" htmlFor="subtaskundefined1">Study Dragons</label></div>
                                                                            <div className="hover-actions end-0"><button className="btn btn-sm me-1 fs--2 text-700 px-0 me-3"><span className="fa-solid fa-pencil"></span></button><button className="btn btn-sm text-700 px-0"><span className="fa-solid fa-xmark fs-0"></span></button></div>
                                                                        </div>
                                                                        <div className="d-flex flex-between-center hover-actions-trigger border-300 py-3 border-top">
                                                                            <div className="form-check mb-1 mb-md-0 d-flex align-items-center lh-1 min-h-auto"><input className="form-check-input form-check-line-through mt-0 me-3" type="checkbox" id="subtaskundefined2" /><label className="form-check-label mb-0 fs-0" htmlFor="subtaskundefined2">Procrastinate a bit</label></div>
                                                                            <div className="hover-actions end-0"><button className="btn btn-sm me-1 fs--2 text-700 px-0 me-3"><span className="fa-solid fa-pencil"></span></button><button className="btn btn-sm text-700 px-0"><span className="fa-solid fa-xmark fs-0"></span></button></div>
                                                                        </div>
                                                                        <div className="d-flex flex-between-center hover-actions-trigger border-300 py-3 border-top border-bottom mb-3">
                                                                            <div className="form-check mb-1 mb-md-0 d-flex align-items-center lh-1 min-h-auto"><input className="form-check-input form-check-line-through mt-0 me-3" type="checkbox" id="subtaskundefined3" /><label className="form-check-label mb-0 fs-0" htmlFor="subtaskundefined3">Staring at the notebook for 5 mins</label></div>
                                                                            <div className="hover-actions end-0"><button className="btn btn-sm me-1 fs--2 text-700 px-0 me-3"><span className="fa-solid fa-pencil"></span></button><button className="btn btn-sm text-700 px-0"><span className="fa-solid fa-xmark fs-0"></span></button></div>
                                                                        </div><a className="fw-bold fs--1 text-decoration-none" href="#!"><span className="fas fa-plus me-1"></span>Add subtask</a>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div>
                                                                            <h4 className="mb-3">Files</h4>
                                                                        </div>
                                                                        <div className="border-top border-300 px-0 pt-4 pb-3">
                                                                            <div className="me-n3">
                                                                                <div className="d-flex flex-between-center">
                                                                                    <div className="d-flex mb-1"><span className="fa-solid fa-image me-2 text-700 fs--1"></span>
                                                                                        <p className="text-1000 mb-0 lh-1">Silly_sight_1.png</p>
                                                                                    </div>
                                                                                    <div className="font-sans-serif btn-reveal-trigger"><button className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h"></span></button>
                                                                                        <div className="dropdown-menu dropdown-menu-end py-2" aria-labelledby="documentations"><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item text-danger" href="#!">Delete</a><a className="dropdown-item" href="#!">Download</a><a className="dropdown-item" href="#!">Report abuse</a></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex fs--1 text-700 mb-2 flex-wrap"><span>768 kb</span><span className="text-400 mx-1">| </span><a href="#!">Shantinan Mekalan </a><span className="text-400 mx-1">| </span><span className="text-nowrap">21st Dec, 12:56 PM</span></div><img className="rounded-2" src="../assets/img/generic/40.png" alt="" style={{ maxWidth: '270px' }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="border-top border-300 px-0 pt-4 pb-3">
                                                                            <div className="me-n3">
                                                                                <div className="d-flex flex-between-center">
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center mb-1"><span className="fa-solid fa-image me-2 fs--1 text-700"></span>
                                                                                            <p className="text-1000 mb-0 lh-1">All_images.zip</p>
                                                                                        </div>
                                                                                        <div className="d-flex fs--1 text-700 mb-0 flex-wrap"><span>12.8 mb</span><span className="text-400 mx-1">| </span><a href="#!">Yves Tanguy </a><span className="text-400 mx-1">| </span><span className="text-nowrap">19th Dec, 08:56 PM</span></div>
                                                                                    </div>
                                                                                    <div className="font-sans-serif btn-reveal-trigger"><button className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h"></span></button>
                                                                                        <div className="dropdown-menu dropdown-menu-end py-2" aria-labelledby="documentations"><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item text-danger" href="#!">Delete</a><a className="dropdown-item" href="#!">Download</a><a className="dropdown-item" href="#!">Report abuse</a></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="border-top border-bottom border-300 px-0 pt-4 pb-3">
                                                                            <div className="me-n3">
                                                                                <div className="d-flex flex-between-center">
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center mb-1 flex-wrap"><span className="fa-solid fa-file-lines me-2 fs--1 text-700"></span>
                                                                                            <p className="text-1000 mb-0 lh-1">Project.txt</p>
                                                                                        </div>
                                                                                        <div className="d-flex fs--1 text-700 mb-0 flex-wrap"><span>123 kb</span><span className="text-400 mx-1">| </span><a href="#!">Shantinan Mekalan </a><span className="text-400 mx-1">| </span><span className="text-nowrap">12th Dec, 12:56 PM</span></div>
                                                                                    </div>
                                                                                    <div className="font-sans-serif btn-reveal-trigger"><button className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h"></span></button>
                                                                                        <div className="dropdown-menu dropdown-menu-end py-2" aria-labelledby="documentations"><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item text-danger" href="#!">Delete</a><a className="dropdown-item" href="#!">Download</a><a className="dropdown-item" href="#!">Report abuse</a></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div><a className="fw-bold fs--1 text-decoration-none" href="#!"><span className="fas fa-plus me-1"></span>Add file(s)</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-5">
                                                                {/* <div className="py-6">
                                                                    <h4 className="mb-4 text-black">Others Information</h4>
                                                                    <h5 className="text-1000 mb-2">Status</h5><select className="form-select mb-4" aria-label="Default select example">
                                                                        <option selected="">Select</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                    <h5 className="text-1000 mb-2">Due Date</h5>
                                                                    <div className="flatpickr-input-container mb-4"><input className="form-control datetimepicker ps-6" type="text" placeholder="Set the due date" data-options='{"disableMobile":true}' /><span className="uil uil-calendar-alt flatpickr-icon text-700"></span></div>
                                                                    <h5 className="text-1000 mb-2">Reminder</h5>
                                                                    <div className="flatpickr-input-container mb-4"><input className="form-control datetimepicker ps-6" type="text" placeholder="Reminder" data-options='{"enableTime":true,"noCalendar":true,"dateFormat":"H:i","disableMobile":true,"static":true}' /><span className="uil uil-bell-school flatpickr-icon text-700"></span></div>
                                                                    <h5 className="text-1000 mb-2">Tag</h5>
                                                                    <div className="choices-select-container mb-6"><select className="form-select" data-choices="data-choices" multiple="multiple" data-options='{"removeItemButton":true,"placeholder":true}'>
                                                                        <option value="">Select organizer...</option>
                                                                        <option>Massachusetts Institute of Technology</option>
                                                                        <option>University of Chicago</option>
                                                                        <option>GSAS Open Labs At Harvard</option>
                                                                        <option>California Institute of Technology</option>
                                                                    </select><span className="uil uil-tag-alt choices-icon text-700" style={{ top: '26%' }}></span></div>
                                                                    <div className="text-end mb-9"><button className="btn btn-phoenix-danger">Delete Task</button></div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-0"><a className="fw-bold fs--1 mt-4 text-decoration-none" href="#!"><span className="fas fa-plus me-1"></span>Add new task</a></div>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6 col-xxl-7">
                                <div className="card todo-list h-100">
                                    <div className="card-header border-bottom-0 pb-0">
                                        <div className="row justify-content-between align-items-center mb-4">
                                            <div className="col-auto">
                                                <h3 className="text-1100">Last Week Working Hours</h3>
                                                <p className="mb-0 text-700"></p>
                                            </div>
                                        </div>
                                        <BarChart
                                            dataset={barChartData}
                                            yAxis={[{ scaleType: 'band', dataKey: 'day' }]}
                                            series={[{ dataKey: 'hours', label: 'Working Hours', valueFormatter }]}
                                            layout="horizontal"
                                            {...chartSetting}
                                            margin={{ left: 80 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Added Footer File  */}
                        <Footer />

                    </div>
                </div>
            </main>
        </>
    )
}

export default Dashboard
