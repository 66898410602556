import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import * as APIs from '../Helpers/app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';
import { Delete, MailOutline } from '@mui/icons-material';
import MUIGrid from '../Controller/MUIGrid';
import Messages from '../Helpers/app.label';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';




import ConformationDialog from '../Controller/ConformationDialog';
import { deleteSubscriber, sendMail, sendMailToMultipleSubscribers } from '../Components/SubscriberComponent.js';
import { ShowLoadingIcon } from '../Helpers/Loader';

const Subscriber = () => {

    document.title = 'Subscriber | Codes';
    const [isLoading, setIsLoading] = useState(false);
    const [subscriberData, setSubscriberData] = useState('');
    const [subscriberRowId, setSubscriberRowId] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);


    const columns = [
        {
            field: 'checkbox',
            headerName: 'Check All',
            width: 20,
            sortable: false,
            headerClassName: 'checkbox-header',
            renderHeader: () => (
                <Tooltip title="Check All">
                    <Checkbox
                        color="primary"
                        checked={selectedRows.length === subscriberData.length}
                        indeterminate={selectedRows.length > 0 && selectedRows.length < subscriberData.length}
                        onChange={updateSelectAllRows}
                    />
                </Tooltip>
            ),
            renderCell: (params) => (
                <Tooltip title="Check">
                    <Checkbox
                        color="primary"
                        checked={selectedRows.includes(params.row.Id)}
                        onChange={() => updateCheckboxSelection(params.row.Id)}
                    />
                </Tooltip>
            ),
        },


        {
            field: 'edit',
            headerName: '',
            width: 70,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Send Email">
                        <div style={{ marginRight: '10px', display: 'inline-block' }}>

                            <MailOutline
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                onClick={() => {
                                    handleSendMail(params.row.Id)
                                }}
                            />
                        </div>
                    </Tooltip>
                    <>
                        <Tooltip title="Delete Subscriber">
                            <div style={{ marginRight: '10px', display: 'inline-block' }}>
                                <Delete
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteSubscriber"
                                    onClick={() => {
                                        setSubscriberRowId(params.row.Id)
                                    }}
                                />
                            </div>
                        </Tooltip>

                    </>
                </>
            ),
        },
        {
            field: 'SRNO', headerName: 'SRNO', width: 70
        },
        {
            field: 'Email',
            headerClassName: 'super-app-theme--header',
            headerName: 'Email',
            width: 760,
            editable: false,
        }, {
            field: 'SubscribedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Subscribed Date',
            width: 180,
            editable: false,
        }
    ];

    const rowsWithSrno = Array.from(subscriberData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });


    useEffect(() => {
        GetAllSubscribers();
    }, []);

    const GetAllSubscribers = async (event) => {
        try {

            let response = await Get(APIs.getAllSubscribers);

            if (!response) {
                toast.error(Messages.GET_ALL_SUBSCRIBERS_EXCEPTION_MSG);
            } else {
                setSubscriberData(response);
            }
        } catch (error) {
            toast.error(Messages.GET_ALL_SUBSCRIBERS_EXCEPTION_MSG);
        }
    }


    //Handle delete subscriber
    const handleDeleteSubsciber = async () => {
        setIsLoading(true);
        try {
            const response = await deleteSubscriber(subscriberRowId)

            if (response.Status === 200) {
                setIsLoading(false);
                toast.success(Messages.DELETE_SUBSCRIBER_SUCCESS_MSG);
                GetAllSubscribers();
            } else {
            }
        } catch (error) {
            toast.error(Messages.DELETE_SUBSCRIBER_EXCEPTION_MSG);
        }

        setIsLoading(false);
    }
    //Handle send mail
    const handleSendMail = async (id) => {
        setIsLoading(true);
        try {
            const response = await sendMail(id);

            if (response === 'Mail_Send_Success') {
                setIsLoading(false);
                toast.success(Messages.MAIL_SUBSCRIBER_MSG);
            } else {
            }
        } catch (error) {
            toast.error(Messages.DELETE_SUBSCRIBER_EXCEPTION_MSG);
        }
        setIsLoading(false);

    }

    const sendMailToAll = async (selectedRows) => {
        setIsLoading(true);
        if (!selectedRows || selectedRows.length === 0) {
            toast.error('Please select at least one value.');
            setIsLoading(false);
            return;
        }

        try {
            const response = await sendMailToMultipleSubscribers(selectedRows);

            if (response === 'Mail_Send_Success') {
                setIsLoading(false);
                setSelectedRows([]);
                toast.success(Messages.MAIL_SUBSCRIBER_MSG);
            } else {
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);

    }


    const updateCheckboxSelection = (id) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });
    };

    const updateSelectAllRows = (event) => {
        if (event.target.checked) {
            const allIds = subscriberData.map((row) => row.Id);
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
    };


    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Subscriber</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Subscriber</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => sendMailToAll(selectedRows)} className="btn btn-primary px-5" to="">

                                        Send All
                                        <svg className="svg-inline--fa fa-paper-plane ms-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"></path>
                                        </svg>
                                    </button>
                                </div>


                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure to delete this Subscriber !"} pageName={"Delete" + " " + "Subscriber"} onOkayClick={handleDeleteSubsciber} id={"deleteSubscriber"} />

                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}
                    </div>
                  
                </div>
            </main>
        </>
    )
}

export default Subscriber
