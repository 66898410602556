import React, { useEffect, useState } from 'react'
import { SignOut } from '../Components/LogoutComponents';
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ShowLoadingIcon } from '../Helpers/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { ImageSrcURL, LinkURL } from '../Helpers/app.constant.api';
import * as Icon from 'react-feather';
import { Get, Post } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';

const Header = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [notificationIsLoading, setNotificationIsLoading] = useState(false);
    const [notificationData, setNotificationData] = useState('');
    const [unSeenNotification, setUnSeenNotification] = useState(0);

    // useEffect(() => {
    //     GetAllNotification();
    // }, []);

    useEffect(() => {
        const notificationInterval = setInterval(GetNotificationCount, 10000);
      
        return () => {
          clearInterval(notificationInterval);
        };
      }, []);

    const GetAllNotification = async () => {

        setNotificationIsLoading(true);

        let response
        response = await Get(APIs.getAllNotificationRecord);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setNotificationData(response.Notifications);
        }
        setNotificationIsLoading(false);
    }

    const GetNotificationCount = async () => {

        let response
        response = await Get(APIs.getNotificationCountRecord);

        if (!response) {
            // toast.error("Something went wrong!");
        } else {
            setUnSeenNotification(response.UnreadNotofications);
        }
    }

    const notificationSeen = async (Id, URL) => {
        
        setNotificationIsLoading(true);

        let response
        response = await Post(APIs.notificationSeen + "/" + Id);

        if (!response) {
            navigate("/" + URL);
        } else {
            navigate("/" + URL);
        }
        setNotificationIsLoading(false);
    }

    const handleSignOut = async () => {

        setIsLoading(true);

        const response = await SignOut();

        if (response === "LogOut_Success") {
            setIsLoading(false);
            navigate('/');
        } else {
            toast.error("Error occured while logout!");
            setIsLoading(true);

        }
    }

    return (
        <>
            {isLoading ? <ShowLoadingIcon /> : ''}

            <nav className="navbar navbar-top navbar-expand" id="navbarDefault" >
                <div className="collapse navbar-collapse justify-content-between">
                    <div className="navbar-logo">
                        <button className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                        <a className="navbar-brand me-1 me-sm-3" href="Dashboard">
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center"><img src="/Assets/img/icons/CodesTechnology.png" alt="Codes" width="200" />
                                    <p className="logo-text ms-2 d-none d-sm-block">Technology</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="search-box navbar-top-search-box d-none d-lg-block" data-list='{"valueNames":["title"]}'>
                        {/* style="width:25rem;" */}
                        <form className="position-relative" data-bs-toggle="search" data-bs-display="static"><input className="form-control search-input fuzzy-search rounded-pill form-control-sm" type="search" placeholder="Search..." aria-label="Search" />
                            <span className="fas fa-search search-box-icon"></span>
                        </form>
                        <div className="btn-close position-absolute end-0 top-50 translate-middle cursor-pointer shadow-none" data-bs-dismiss="search"><button className="btn btn-link btn-close-falcon p-0" aria-label="Close"></button></div>
                        <div className="dropdown-menu border border-300 font-base start-0 py-0 overflow-hidden w-100">
                            <div className="scrollbar-overlay" >
                                {/* style="max-height: 30rem;" */}
                                <div className="list pb-3">
                                    <h6 className="dropdown-header text-1000 fs--2 py-2">24 <span className="text-500">results</span></h6>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Recently Searched </h6>
                                    <div className="py-2"><a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                        <div className="d-flex align-items-center">
                                            <div className="fw-normal text-1000 title"><span className="fa-solid fa-clock-rotate-left" data-fa-transform="shrink-2"></span> Store Macbook</div>
                                        </div>
                                    </a>
                                        <a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="d-flex align-items-center">
                                                <div className="fw-normal text-1000 title"> <span className="fa-solid fa-clock-rotate-left" data-fa-transform="shrink-2"></span> MacBook Air - 13″</div>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Products</h6>
                                    <div className="py-2"><a className="dropdown-item py-2 d-flex align-items-center" href="../apps/e-commerce/landing/product-details.html">
                                        <div className="file-thumbnail me-2"><img className="h-100 w-100 fit-cover rounded-3" src="../assets/img/products/3.png" alt="" /></div>
                                        <div className="flex-1">
                                            <h6 className="mb-0 text-1000 title">MacBook Air - 13″</h6>
                                            <p className="fs--2 mb-0 d-flex text-700"><span className="fw-medium text-600">8GB Memory - 1.6GHz - 128GB Storage</span></p>
                                        </div>
                                    </a>
                                        <a className="dropdown-item py-2 d-flex align-items-center" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="file-thumbnail me-2"><img className="img-fluid" src="../assets/img/products/3.png" alt="" /></div>
                                            <div className="flex-1">
                                                <h6 className="mb-0 text-1000 title">MacBook Pro - 13″</h6>
                                                <p className="fs--2 mb-0 d-flex text-700"><span className="fw-medium text-600 ms-2">30 Sep at 12:30 PM</span></p>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Quick Links</h6>
                                    <div className="py-2"><a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                        <div className="d-flex align-items-center">
                                            <div className="fw-normal text-1000 title"><span className="fa-solid fa-link text-900" data-fa-transform="shrink-2"></span> Support MacBook House</div>
                                        </div>
                                    </a>
                                        <a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="d-flex align-items-center">
                                                <div className="fw-normal text-1000 title"> <span className="fa-solid fa-link text-900" data-fa-transform="shrink-2"></span> Store MacBook″</div>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Files</h6>
                                    <div className="py-2"><a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                        <div className="d-flex align-items-center">
                                            <div className="fw-normal text-1000 title"><span className="fa-solid fa-file-zipper text-900" data-fa-transform="shrink-2"></span> Library MacBook folder.rar</div>
                                        </div>
                                    </a>
                                        <a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="d-flex align-items-center">
                                                <div className="fw-normal text-1000 title"> <span className="fa-solid fa-file-lines text-900" data-fa-transform="shrink-2"></span> Feature MacBook extensions.txt</div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="d-flex align-items-center">
                                                <div className="fw-normal text-1000 title"> <span className="fa-solid fa-image text-900" data-fa-transform="shrink-2"></span> MacBook Pro_13.jpg</div>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Members</h6>
                                    <div className="py-2"><a className="dropdown-item py-2 d-flex align-items-center" href="../pages/pages/members.html">
                                        <div className="avatar avatar-l status-online  me-2 text-900">
                                            <img className="rounded-circle " src="../assets/img/team/10.png" alt="" />
                                        </div>
                                        <div className="flex-1">
                                            <h6 className="mb-0 text-1000 title">Carry Anna</h6>
                                            <p className="fs--2 mb-0 d-flex text-700">anna@technext.it</p>
                                        </div>
                                    </a>
                                        <a className="dropdown-item py-2 d-flex align-items-center" href="../pages/pages/members.html">
                                            <div className="avatar avatar-l  me-2 text-900">
                                                <img className="rounded-circle " src="../assets/img/team/12.png" alt="" />
                                            </div>
                                            <div className="flex-1">
                                                <h6 className="mb-0 text-1000 title">John Smith</h6>
                                                <p className="fs--2 mb-0 d-flex text-700">smith@technext.it</p>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="text-200 my-0" />
                                    <h6 className="dropdown-header text-1000 fs--1 border-bottom border-200 py-2 lh-sm">Related Searches</h6>
                                    <div className="py-2"><a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                        <div className="d-flex align-items-center">
                                            <div className="fw-normal text-1000 title"><span className="fa-brands fa-firefox-browser text-900" data-fa-transform="shrink-2"></span> Search in the Web MacBook</div>
                                        </div>
                                    </a>
                                        <a className="dropdown-item" href="../apps/e-commerce/landing/product-details.html">
                                            <div className="d-flex align-items-center">
                                                <div className="fw-normal text-1000 title"> <span className="fa-brands fa-chrome text-900" data-fa-transform="shrink-2"></span> Store MacBook″</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p className="fallback fw-bold fs-1 d-none">No Result Found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav navbar-nav-icons flex-row">
                        {/* <li className="nav-item">
                            <div className="theme-control-toggle fa-icon-wait px-2"><input className="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggle" /><label className="mb-0 theme-control-toggle-label theme-control-toggle-light" htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span className="icon" data-feather="moon"></span></label><label className="mb-0 theme-control-toggle-label theme-control-toggle-dark" htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span className="icon" data-feather="sun"></span></label></div>
                        </li> */}

                        <li className="nav-item dropdown">
                            <a onClick={() => GetAllNotification()} className="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                                <Icon.Bell size='24' />
                                {(unSeenNotification > 50 || unSeenNotification > "50") ?
                                    (<span style={badgeStyle}>50+</span>)
                                    : (unSeenNotification !== 0) ?
                                        (<span style={badgeStyle}>{unSeenNotification}</span>)
                                        :
                                        ""}
                            </a>
                            <div className="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border border-300 navbar-dropdown-caret" id="navbarDropdownNotfication" aria-labelledby="navbarDropdownNotfication">
                                <div className="card position-relative border-0">
                                    {notificationIsLoading ? <ShowLoadingIcon /> : ''}
                                    <div className="card-header p-2">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="text-black mb-0">Notificatons</h5>
                                            {/* <button className="btn btn-link p-0 fs--1 fw-normal" type="button">Mark all as read</button> */}
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="scrollbar-overlay" style={{ height: "27rem" }}>
                                            <div className="border-300">
                                                {notificationData.length === 0 ? (
                                                    <p
                                                        className="fs--1 text-1000 mb-2 mb-sm-3 fw-normal"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            textAlign: "center",
                                                            backgroundColor: "#f7f7f7", // Add a background color for better visibility
                                                            borderRadius: "8px", // Add rounded corners
                                                            padding: "16px", // Add some padding
                                                        }}
                                                    >
                                                        No notifications found!
                                                    </p>
                                                ) : (
                                                    Array.from(notificationData).map((notificationItem, index) => (
                                                        <a onClick={() => notificationSeen(notificationItem.Id, notificationItem.URL)}>
                                                            <div className={`${notificationItem.ReadStatus === 1 ? "p-3 border-300 notification-card position-relative unread border-bottom" : "p-3 border-300 notification-card position-relative read border-bottom"}`}>
                                                                <div className="d-flex align-items-center justify-content-between position-relative">
                                                                    <div className="d-flex">
                                                                        <div className="avatar avatar-m me-3">
                                                                            <img className="rounded-circle" alt="" src={`${ImageSrcURL}/${notificationItem.ProfileImageName !== "" ? notificationItem.ProfileImageName : ""}`} />
                                                                    </div>
                                                                        <div className="me-3 flex-1">
                                                                            <h4 className="fs--1 text-black">{notificationItem.Full_Name}</h4>
                                                                            <p className="fs--1 text-1000 mb-2 mb-sm-3 fw-normal"><span className='me-1 fs--2'>💬</span>{notificationItem.Description}<span className="ms-2 text-400 fw-bold fs--2"></span></p>
                                                                            <p className="text-800 fs--1 mb-0"><span className="me-1 fas fa-clock"></span><span className="fw-bold">{notificationItem.FormattedTime} </span>{notificationItem.FormattedDate}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {notificationData.length !== 0 ? (
                                        <div className="card-footer p-0 border-top border-0">
                                            <div className="my-2 text-center fw-bold fs--2 text-600"><Link className="fw-bolder" to="/Notifications">Notification history</Link></div>
                                        </div>
                                    ) : ("")}
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link" id="navbarDropdownNindeDots" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" data-bs-auto-close="outside" aria-expanded="false"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                                <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
                                <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
                                <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
                                <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
                                <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
                                <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
                                <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
                                <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
                            </svg></a>
                            <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-nide-dots shadow border border-300" aria-labelledby="navbarDropdownNindeDots">
                                <div className="card bg-white position-relative border-0">
                                    <div className="card-body pt-3 px-3 pb-0 overflow-auto scrollbar">
                                        {/* style="height: 20rem;" */}
                                        <div className="row text-center align-items-center gx-0 gy-0">
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/behance.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Behance</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/google-cloud.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Cloud</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/slack.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Slack</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/gitlab.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Gitlab</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/bitbucket.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">BitBucket</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/google-drive.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Drive</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/trello.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Trello</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/figma.png" alt="" width="20" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Figma</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/twitter.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Twitter</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/pinterest.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Pinterest</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/ln.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Linkedin</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/google-maps.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Maps</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/google-photos.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Photos</p>
                                            </a></div>
                                            <div className="col-4"><a className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3" href="#!"><img src="../assets/img/nav-icons/spotify.png" alt="" width="30" />
                                                <p className="mb-0 text-black text-truncate fs--2 mt-1 pt-1">Spotify</p>
                                            </a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown"><a className="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-l ">
                                <img className="rounded-circle " src={`${ImageSrcURL}/${localStorage.getItem('UserProfileImage') !== "" ? localStorage.getItem('UserProfileImage') : ""}`} alt="" />
                            </div>
                        </a>
                            <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
                                <div className="card position-relative border-0">
                                    <div className="card-body p-0">
                                        <div className="text-center pt-4 pb-3">
                                            <div className="avatar avatar-xl ">
                                                <img className="rounded-circle " src={`${ImageSrcURL}/${localStorage.getItem('UserProfileImage') !== "" ? localStorage.getItem('UserProfileImage') : ""}`} alt="" />
                                            </div>
                                            <h6 className="mt-2 text-black">{localStorage.getItem('UserFullName') ? localStorage.getItem('UserFullName') : ""}</h6>
                                        </div>
                                        <div className="mb-3 mx-3"><select className="form-control form-control-sm" id="statusUpdateInput" placeholder="Update your status">
                                            <option value="Active">Active</option>
                                            <option value="Busy">Busy</option>
                                            <option value="Meeting">Meeting</option>
                                            <option value="Available">Available</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="overflow-auto scrollbar" >
                                        {/* style="height: 10rem;" */}
                                        <ul className="nav d-flex flex-column mb-2 pb-1">
                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="user"></span><span><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user me-2 text-900"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>Profile</span></a></li>
                                            <li className="nav-item"><a className="nav-link px-3" href="#!"><span className="me-2 text-900" data-feather="pie-chart"></span><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-pie-chart me-2 text-900"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg>Dashboard</a></li>
                                            {/* <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="lock"></span>Posts &amp; Activity</a></li> */}
                                            <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="settings"></span><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings me-2 text-900"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>Settings &amp; Privacy </a></li>
                                            {/* <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="help-circle"></span>Help Center</a></li> */}
                                            {/* <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="globe"></span>Language</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="card-footer p-0 border-top">
                                        <ul className="nav d-flex flex-column my-3">
                                            {/* <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-900" data-feather="user-plus"></span>Add another account</a></li> */}
                                        </ul>
                                        {/* <hr /> */}
                                        <div className="px-3"><button onClick={handleSignOut} className="btn btn-phoenix-secondary d-flex flex-center w-100"> <span className="me-2" data-feather="log-out"> </span><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out me-2"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Sign out</button></div>
                                        <div className="my-2 text-center fw-bold fs--2 text-600"><a className="text-600 me-1" href="#!">Privacy policy</a>&bull;<a className="text-600 mx-1" href="#!">Terms</a>&bull;<a className="text-600 ms-1" href="#!">Cookies</a></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* for toaster message */}
            <ToastContainer theme="colored" />
        </>
    )
}

const badgeStyle = {
    position: 'absolute',
    top: '15%',
    right: '0',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '70%',
    padding: '4px 6px',
    fontSize: '9px',
};

export default Header
