import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import ConformationDialog from '../../Controller/ConformationDialog';
import * as APIs from '../../Helpers/app.constant.api';
import { Get } from '../../Services/HttpClient/HttpRequest';
import { Delete, Edit } from '@mui/icons-material';
import MUIGrid from '../../Controller/MUIGrid';
import Tooltip from '@mui/material/Tooltip';
import * as Component from '../../Components/PortfolioComponent.js';

const Portfolio = () => {

    document.title = 'Portfolio | Codes';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [portfolioData, setPortfolioData] = useState('');
    const [rowId, setRowId] = useState('');

    useEffect(() => {
        GetAllPortfolios();
    }, []);

    const GetAllPortfolios = async (event) => {

        let response = await Get(APIs.viewPortfolio);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setPortfolioData(response);
        }
    }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            sortable: false,
            width: 70,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Portfolio">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>
                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                onClick={() => {
                                    navigate('/AddUpdatePortfolio/' + params.row.Id);
                                }}
                            />
                        </div>
                    </Tooltip>
                    <>
                        <Tooltip title="Delete Portfolio">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                <Delete
                                   fontSize='small'
                                   color="#525B75"
                                   type="button"
                                   data-bs-toggle="modal"
                                   data-bs-target="#deletePortfolio"
                                    onClick={() => {
                                          setRowId(params.row.Id)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                </>
            ),
        },
        {
            field: 'SRNO', headerName: 'SRNO', width: 70, sortable: false
        },
        {
            field: 'Title',
            headerClassName: 'super-app-theme--header',
            headerName: 'Title',
            width: 300,
            sortable: false,
            editable: false,
        }, {
            field: 'Industry',
            headerClassName: 'super-app-theme--header',
            headerName: 'Industry',
            width: 120,
            sortable: false,
            editable: false,
        }, {
            field: 'CompanyName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Company Name',
            width: 170,
            sortable: false,
            editable: false,
        }, {
            field: 'Location',
            headerClassName: 'super-app-theme--header',
            headerName: 'Location',
            width: 220,
            sortable: false,
            editable: false,
        }, {
            field: 'ProjectTimeline',
            headerClassName: 'super-app-theme--header',
            headerName: 'Project Timeline',
            width: 120,
            sortable: false,
            editable: false,
        },
    ];

    const rowsWithSrno = Array.from(portfolioData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    const handleDeletePortfolio = async () => {
        setIsLoading(true);

        try {
            const response = await Component.deletePortfolio(rowId);

            if (response.Status === 200) {
                toast.success("Portfolio succefully deleted");
                GetAllPortfolios();
            } else{
                toast.error("Error occured while deleting!");
            }

        } catch (error) {
            toast.error("Error occured while deleting!");
        }
        setIsLoading(false);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Portfolio</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Portfolio</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <NavLink className="btn btn-primary px-5" to="/AddUpdatePortfolio">
                                        {/* <button className="btn btn-primary btn-sm" type="button"><span className="fas fa-plus me-2"></span>Add Portfolio</button> */}
                                        <i className="fa-solid fa-plus me-2"></i> Add Portfolio
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        <ConformationDialog msg={"Are you sure to delete this Portfolio !"} pageName={"Delete" + " " + "Portfolio"} onOkayClick={handleDeletePortfolio} id={"deletePortfolio"} />

                        {/* Added Footer File  */}
                        <Footer />

                         {/* Here loading icon */}
                         {isLoading ? <ShowLoadingIcon /> : ''}

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                    </div>
                </div>
            </main>
        </>
    )
}

export default Portfolio
