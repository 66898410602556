import React, { useEffect } from 'react'
import { NavLink, useLocation, Link } from "react-router-dom";
import * as Icon from 'react-feather';
import * as Common from '../Controller/Common';
import { useNavigate } from 'react-router-dom/dist';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const hasId = !!id;

    useEffect(() => {
        Common.isTokenValid(navigate);
    }, [navigate]);
    return (
        <>
            <nav className="navbar navbar-vertical navbar-expand-lg" >

                <div className="collapse navbar-collapse" id="navbarVerticalCollapse">

                    <div className="navbar-vertical-content">
                        <ul className="navbar-nav flex-column" id="navbarVerticalNav">

                            {(localStorage.getItem('Email') === "info@codestec.com") ?
                                (
                                    <li className="nav-item">
                                        <p className="navbar-vertical-label">Website</p>
                                        <hr className="navbar-vertical-line" />
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Blogs" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.FileText size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Blogs</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/BlogCategory" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Layers size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Blogs Category</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Portfolio" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Image size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Portfolio</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Subscriber" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Cast size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Subscribers</span></span>
                                                </div>
                                            </NavLink>
                                        </div>

                                    </li>
                                ) : ""}

                            <li className="nav-item">

                                <p className="navbar-vertical-label">Employee</p>
                                <hr className="navbar-vertical-line" />
                                <div className="nav-item-wrapper">
                                    <NavLink to="/Dashboard" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.PieChart size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Dashboard</span></span>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="nav-item-wrapper">
                                    <NavLink to="/Payslips" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.Send size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Payslips</span></span>
                                        </div>
                                    </NavLink>
                                </div>

                                <div className="nav-item-wrapper">
                                    <NavLink to="/Attendance" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.Calendar size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Attendance</span></span>
                                        </div>
                                    </NavLink>
                                </div>

                                <div className="nav-item-wrapper">
                                    <NavLink to="/Holidays" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.List size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Holidays</span></span>
                                        </div>
                                    </NavLink>
                                </div>

                                {(localStorage.getItem('IsLineManger') === "IsLineManger") ?
                                    (
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/PendingApproval" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.UserCheck size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Pending Approval</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ) : ""}

                                <div className="nav-item-wrapper">
                                    <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.ExternalLink size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Advance Salary Request</span></span>
                                        </div>
                                    </a>
                                </div>
                                <div className="nav-item-wrapper">
                                    <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.DivideCircle size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Tax Deductions</span></span>
                                        </div>
                                    </a>
                                </div>
                                <div className="nav-item-wrapper">
                                    <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.Tag size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Reimbursements</span></span>
                                        </div>
                                    </a>
                                </div>


                                <div className="nav-item-wrapper">
                                    {id ? (
                                        <Link
                                            className="nav-link label-1"
                                            to="/Documents"
                                            role="button"
                                            data-bs-toggle=""
                                            aria-expanded="false"
                                        >
                                            <div className="d-flex align-items-center">
                                                <span className="nav-link-icon">
                                                    <Icon.FilePlus size="18" />
                                                </span>
                                                <span className="nav-link-text-wrapper">
                                                    <span className="nav-link-text">Documents</span>
                                                </span>
                                            </div>
                                        </Link>
                                    ) : (
                                        <NavLink className="nav-link label-1" to="/Documents" role="button" data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center">
                                                <span className="nav-link-icon"><Icon.FilePlus size='18' /></span>
                                                <span className="nav-link-text-wrapper">
                                                    <span className="nav-link-text">Documents</span>
                                                </span>
                                            </div>
                                        </NavLink>
                                    )}
                                </div>


                                <div className="nav-item-wrapper">
                                    <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.HelpCircle size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Supports</span></span>
                                        </div>
                                    </a>
                                </div>
                                <div className="nav-item-wrapper">
                                    <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon"><Icon.User size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Profile</span></span>
                                        </div>
                                    </a>
                                </div>
                                {/* <NavLink className="nav-link label-1" to="/Notifications" role="button" data-bs-toggle="" aria-expanded="false">
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-icon"><Icon.Bell size='18' /></span>
                                        <span className="nav-link-text-wrapper">
                                            <span className="nav-link-text">Notifications</span>
                                        </span>
                                    </div>
                                </NavLink> */}

                               
                            </li>

                            {(localStorage.getItem('Email') === "hanif@gmail.com" || localStorage.getItem('Email') === "info@codestec.com" || localStorage.getItem('Email') === "makhdumc@codestec.com" || localStorage.getItem('IsAccountOwner') === "1") ?
                                (
                                    <li className="nav-item">

                                        <p className="navbar-vertical-label">Organization</p>
                                        <hr className="navbar-vertical-line" />
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Employee" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Users size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Employees</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Smile size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Contrators</span></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link dropdown-indicator label-1" data-bs-toggle="collapse" href="#email" role="button" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><Icon.Plus size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Employees Pay</span></span>
                                                </div>
                                            </a>
                                            <div className="parent-wrapper label-1">
                                                <ul className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="email">
                                                    <li className="nav-item"><a className="nav-link" href="../index.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">OneTime Payment</span></div>
                                                    </a>
                                                    </li>
                                                    <li className="nav-item"><a className="nav-link" href="project-managementt.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">Run Payroll</span></div>
                                                    </a>
                                                    </li>
                                                    <li className="nav-item"><a className="nav-link" href="../landing.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">Salary Requests</span></div>
                                                    </a>
                                                    </li>
                                                    <li className="nav-item"><a className="nav-link" href="../apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">Reimbursements</span></div>
                                                    </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link dropdown-indicator label-1" data-bs-toggle="collapse" href="#events" role="button" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><Icon.Percent size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Contractors Pay</span></span>
                                                </div>
                                            </a>
                                            <div className="parent-wrapper label-1">
                                                <ul className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="events">
                                                    <li className="nav-item"><a className="nav-link" href="../landing.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">Payments</span></div>
                                                    </a>
                                                    </li>
                                                    <li className="nav-item"><a className="nav-link" href="../apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center"><span className="nav-link-text">Reimbursements</span></div>
                                                    </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Trello size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Reports</span></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Transactions" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Repeat size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Transactions</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Edit3 size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Company Details</span></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <NavLink className="nav-link label-1" to="/OrganizationSettings" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Settings size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Settings</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </li>
                                ) : ""}

                            {(localStorage.getItem('Email') === "info@codestec.com" || localStorage.getItem('Email') === "makhdumc@codestec.com") ?
                                (
                                    <li className="nav-item">
                                        <p className="navbar-vertical-label">Super Admin</p>
                                        <hr className="navbar-vertical-line" />
                                        <div className="nav-item-wrapper">
                                            <NavLink to="/Organizations" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Figma size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Organizations</span></span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.File size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Billing</span></span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="nav-item-wrapper">
                                            <a className="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center">
                                                    <span className="nav-link-icon"><Icon.Settings size='18' /></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Settings</span></span>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                ) : ""}

                            <div className="nav-item-wrapper">
                                <NavLink to="/Maintenance" className="nav-link label-1" role="button" data-bs-toggle="" aria-expanded="false">
                                    <div className="d-flex align-items-center">
                                        <span className="nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Maintenance</span></span>
                                    </div>
                                </NavLink>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="navbar-vertical-footer"><button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"><span className="uil uil-left-arrow-to-left fs-0"></span><span className="uil uil-arrow-from-right fs-0"></span><span className="navbar-vertical-footer-text ms-2">Collapsed View</span></button></div>

            </nav>

        </>
    )
}

export default Navbar;
