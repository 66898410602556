import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { Delete, Edit } from '@mui/icons-material';
import * as APIs from './app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';
import MUIGrid from '../Controller/MUIGrid';
import Messages from './app.label';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { DeleteHoliday, addUpdateHolidayData } from '../Components/OrganizationSettingsComponent.js';
import * as Common from '../Controller/Common';
import ConformationDialog from '../Controller/ConformationDialog';
import { ShowLoadingIcon } from './Loader';

export const ManageHolidays = () => {

    // document.title = 'Subscriber | Codes';
    const [isLoading, setIsLoading] = useState(false);
    const [holidayData, setHolidayListData] = useState('');
    const [holidayRowId, setHolidayRowId] = useState('');
    const [dialogName, setDialogName] = useState(false);
    const [dialogButtonName, setDialogButtonName] = useState(false);
    const [holidayFormData, setHolidayFormData] = useState('');
    let initialAddUpdateFormState = { Id: 0, HolidayName: "", Type: 0, Date: "" }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            sortable: false,
            width: 70,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Holiday">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>
                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#addUpdateHolidayForm"
                                onClick={() => {
                                    openHolidayDialog(params.row.Id)
                                }}
                            />
                        </div>
                    </Tooltip>
                    <>
                        <Tooltip title="Delete Holiday">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                <Delete
                                    fontSize='small'
                                    color="#525B75"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteHoliday"
                                    onClick={() => {
                                        setHolidayRowId(params.row.Id)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                </>
            ),
        },


        {
            field: 'SRNO', headerName: 'SRNO', width: 70
        }, {
            field: 'Date',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            width: 150,
            editable: false,
        },{
            field: 'DayName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Day',
            width: 150,
            editable: false,
        }, {
            field: 'HolidayName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Holiday Name',
            width: 500,
            editable: false,
        }
        , {
            field: 'Type',
            headerClassName: 'super-app-theme--header',
            headerName: 'Type',
            width: 200,
            editable: false,
            renderCell: (params) => {
                const type = params.getValue(params.id, 'Type');

                if (type === 1) {
                    return <span style={{ color: 'blue' }}>Regional</span>;
                } else if (type === 2) {
                    return <span style={{ color: 'green' }}>National</span>;
                }

                return null;
            }
        }
    ];

    const rowsWithSrno = Array.from(holidayData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });


    useEffect(() => {
        GetAllHolidayList();
    }, []);


    const GetAllHolidayList = async (event) => {
        try {

            let response = await Get(APIs.getAllHolidayList);

            if (!response) {
                toast.error(Messages.SOMETHING_WENT_WRONG);
            } else {
                setHolidayListData(response);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    }

    const deleteHoliday = async () => {
        setIsLoading(true);
        try {

            const response = await DeleteHoliday(holidayRowId);
            let msg = ['Successfully delete holiday record']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                GetAllHolidayList();
            } else{
                toast.error(response.Message)
            }

        } catch (error) {
            toast.error(Messages.GET_ALL_SUBSCRIBERS_EXCEPTION_MSG);

        }
        setIsLoading(false);

    }

    const openHolidayDialog = async (id) => {

        setIsLoading(true);
        let dialogName = "";
        let dialogButtonName = "";

        try {
            if (id === 0) {
                dialogName = "Add Holiday";
                dialogButtonName = "Add";
                setHolidayFormData(initialAddUpdateFormState);
            } else {
                dialogName = "Update Holiday";
                dialogButtonName = "Update";

                initialAddUpdateFormState = await Get(APIs.getHolidayId + "/" + id);

                // if (!initialAddUpdateFormState) {
                //     throw new Error(Messages.Error);
                // }
                setHolidayFormData(initialAddUpdateFormState.holiday);
            }
            setDialogName(dialogName);
            setDialogButtonName(dialogButtonName);

        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    }

    const addUpdateHoliday = async () => {
        setIsLoading(true);
        try {
            const response = await addUpdateHolidayData(holidayFormData);
            let msg = ['Holiday Updated Successfully!', 'Holiday Created Successfully!']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                Common.CloseDialog("addUpdateHolidayForm");
                GetAllHolidayList();
                setHolidayFormData(initialAddUpdateFormState);
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    };

    return (
        <>
            <div className="row align-items-center justify-content-between g-3 mb-3">
                <div className="col col-auto">
                </div>
                <div className="col-auto">
                    <div className="d-flex align-items-right">
                        <button onClick={() => { openHolidayDialog(0) }} className="btn btn-primary px-5" to="" data-bs-toggle="modal" data-bs-target="#addUpdateHolidayForm">
                            Add Holiday
                        </button>
                    </div>

                </div>
            </div>

            {/* Added DataGrid */}
            <MUIGrid Data={rowsWithSrno} columnData={columns} />

            {/* for toaster message */}
            <ToastContainer theme="colored" />

            <ConformationDialog msg={"Are you sure to delete this Holiday !"} pageName={"Delete" + " " + "Holiday"} onOkayClick={deleteHoliday} id={"deleteHoliday"} />

            <div className="modal fade" id="addUpdateHolidayForm" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">{dialogName}</h5>
                            <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>
                        {isLoading ? <ShowLoadingIcon /> : ''}
                        <div className="modal-body">
                            <form id="" encType="multipart/form-data">
                                <div className="form-floating mb-4">
                                    <input value={holidayFormData.Date} className="form-control" id="floatingInputGrid" type="date" placeholder="Date"
                                        onChange={e => setHolidayFormData({ ...holidayFormData, Date: e.target.value })}
                                    />
                                    <label htmlFor="floatingInputGrid">Date<span className="text-danger">*</span></label>
                                </div>
                                <div className='row mb-4'>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input value={holidayFormData.HolidayName} className="form-control" id="floatingInputGrid" type="text" placeholder=""
                                                onChange={e => setHolidayFormData({ ...holidayFormData, HolidayName: e.target.value })}
                                            />
                                            <label htmlFor="floatingInputGrid">Holiday Name <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelectTask" value={holidayFormData.Type} onChange={e => setHolidayFormData({ ...holidayFormData, Type: e.target.value })}>
                                                <option value="">Select Type</option>
                                                <option value="1">Regional</option>
                                                <option value="2">National</option>
                                            </select>
                                            <label htmlFor="floatingSelectTask">Type <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => addUpdateHoliday()} className="btn btn-primary" id='okayButton' type="button">{dialogButtonName}</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end dialog */}
        </>
    )
}

// export default Subscriber
