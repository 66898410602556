import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
// import { Delete, Edit } from '@mui/icons-material';
import * as Component from '../../Components/Employee/GetDocumentsComponent';
import MUIGrid from '../../Controller/MUIGrid';
import * as Common from '../../Controller/Common';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import Messages from '../../Helpers/app.label';
import { useNavigate, NavLink } from "react-router-dom";
import ConformationDialog from '../../Controller/ConformationDialog';
import Visibility from '@mui/icons-material/Visibility';
import { Delete } from '@mui/icons-material';
import { ImageSrcURL } from '../../Helpers/app.constant.api';
import Tooltip from '@mui/material/Tooltip';
import Dropzone from '../../Controller/Dropzone';

const GetDocuments = () => {

    document.title = 'Documents | Codes';
    const [isLoading, setIsLoading] = useState(false);
    const [employeeDocumentData, setEmployeeDocumentData] = useState('');
    // const [employeeName, setName] = useState('');
    const [formData, setFormData] = useState('');
    const [rowId, setRowId] = useState('');
    const [documentsName, setDocumentsName] = useState('');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    const [fileNames, setFileData] = useState('');
    const [folderPath, setFolderPath] = useState('');
    const [userName, setUserName] = useState('');
    const [uploadedDate, setUploadDate] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [pageName, setPageName] = useState('');
    const [showImages, setShowImages] = useState(false);

    useEffect(() => {
        const url = window.location.pathname;
        const page = url.substring(1); // Remove the leading slash
        setPageName(page);
    }, []);

    useEffect(() => {
        if (id !== null) {
            const storedEmployeeName = localStorage.getItem('UserFullName');
            if (storedEmployeeName) {
                setEmployeeName(storedEmployeeName);
            }
        } else {
            getAllDocuments();
        }
    }, [id]);

    const initialAddUpdateFormState = {
        Description: "", DocumentType: "", UploadDocument: "",
    };

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 80,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="View Document">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>
                            <Visibility
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#viewDocumentsModal"
                                onClick={() => {
                                    renderDocuments(params.row.EmployeeId, params.row.DocumentType, params.row.Id)
                                }}
                            />
                        </div>
                    </Tooltip>
                    <>
                        {id && (
                            <Tooltip title="Delete Document">
                                <div style={{ marginRight: '5px', display: 'inline-block' }}>

                                    <Delete
                                        fontSize='small'
                                        color="#525B75"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteDocuments"
                                        onClick={() => {
                                            setRowId(params.row.EmployeeId)
                                            setDocumentsName(params.row.DocumentType)
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                </>
            ),
        },
        // { field: 'Id', headerName: 'SRNO', width: 90 },
        {
            field: 'SRNO', headerName: 'SRNO', width: 70
        },

        {
            field: 'DocumentType',
            headerClassName: 'super-app-theme--header',
            headerName: 'DocumentType',
            width: 300,
            editable: false,
        },
        {
            field: 'Description',
            headerClassName: 'super-app-theme--header',
            headerName: 'Description',
            width: 350,
            editable: false,
        },
        {
            field: 'UploadedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'UploadedDate',
            width: 250,
            editable: false,
        },


    ];

    const rowsWithSrno = Array.from(employeeDocumentData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    useEffect(() => {
        getAllDocuments();
    }, []);

    const getAllDocuments = async () => {
        try {
            let response;
            if (id) {
                response = await Component.getDocuments(id);
            } else {
                response = await Component.getDocuments();
            }
            if (response && response.length > 0) {
                setEmployeeDocumentData(response);
                // setName(response[0].name);
            } else {
                setEmployeeDocumentData([]);
                // setName(null);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    }

    //Add Document 
    const uploadDocuments = async () => {
        debugger
        try {
            setIsLoading(true);
            const response = await Component.uploadDocuments(formData, id);

            if (response.Status === 200) {
                toast.success(response.message);
                Common.CloseDialog("addDocumentsModal");
                Common.ResetModal('addDocumentsModal')
                setFormData(initialAddUpdateFormState);
                getAllDocuments();
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    }

    //Delete Documents
    const handleDeleteDocuments = async () => {
        setIsLoading(true);
        try {
            const response = await Component.deleteDocuments(rowId, documentsName)

            if (response.Status === 200) {
                setIsLoading(false);
                toast.success(Messages.DELETE_EMPLOYEE_DOCUMENTS_MSG);
                getAllDocuments();
            } else {
            }
        } catch (error) {
            toast.error(Messages.DELETE_EMPLOYEE_DOCUMENTS_EXCEPTION_MSG);
        }

        setIsLoading(false);
    }

    const handelRedirectingEmployee = () => {
        if (id == null) {
            navigate('/Employee');
        } else {
            navigate(`/AddUpdateEmployee?id=${id}`);
        }
    }

    const renderDocuments = async (empId, uplodedocumentName, Id) => {

        setFileData([]);
        setFolderPath([]);
        setUserName('');
        setUploadDate('');
        // Common.ResetModal('viewDocumentsModal')
        setIsLoading(true);
        try {
            const response = await Component.viewDocuments(empId, uplodedocumentName, Id);

            if (response.status === 200) {
                setIsLoading(false);
                setFileData(response.result.fileNames);
                setFolderPath(response.result.folderPath[0]);
                setUserName(response.result.uploadedBy);
                setUploadDate(response.result.uploadedDates);

            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop();
        switch (extension) {
            case 'jpg':
            case 'png':
            case 'gif':
                return '/Assets/img/icons/jpgimage.png';
            case 'pdf':
                return '/Assets/img/icons/pdfimg.png';
            default:
                return '/Assets/img/icons/no-file.png';
        }
    };

    const handleFilesAccepted = (files) => {
        setShowImages(true);
        setFormData({ ...formData, UploadDocument: files })
        // setFormData((prevFormData) => ({...prevFormData, UploadDocument: [...prevFormData, ...files]}));
        // setFormData((prevFiles) => [...prevFiles, ...files]);
    };

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/Employee">Employees</NavLink></li>
                                {id && (
                                    <li className="breadcrumb-item active">
                                        <a
                                            style={{
                                                color: '#3874ff',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.textDecoration = 'underline';
                                                e.target.style.color = '#1d55ff';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.textDecoration = 'none';
                                                e.target.style.color = '#3874ff';
                                            }}
                                            onClick={() => handelRedirectingEmployee(id)}
                                        >
                                            {employeeName}
                                        </a>
                                    </li>
                                )}
                                <li className="breadcrumb-item active">Documents</li>
                            </ol>
                        </nav>

                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Documents</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => setShowImages(false)} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addDocumentsModal" ><span className="fas fa-plus me-2"></span>Add Documents</button>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* Added Model */}
                        {/* <Model modelId={modelId} /> */}

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure you want to delete this document? This action will delete all associated Documents."} pageName={"Delete" + " " + pageName} onOkayClick={handleDeleteDocuments} id={"deleteDocuments"} />


                        {/* This button for open model & its hidden button */}
                        <button className="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target="#verticallyCentered">Vertically centered modal</button>
                    </div>

                    <div className="modal fade" id="addDocumentsModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="verticallyCenteredModalLabel">Add Documents</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                                </div>
                                {isLoading ? <ShowLoadingIcon /> : ''}
                                <div className="modal-body">
                                    <form encType="multipart/form-data">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Upload new document <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.DocumentType} onChange={e => setFormData({ ...formData, DocumentType: e.target.value })} className="form-select" id="floatingSelectTask" >
                                            <option value="">Select Document Type</option>
                                            <option value="Professional-Documents">Professional Documents</option>
                                            <option value="Degree">Degree</option>
                                            <option value="Aadhar">Aadhar</option>
                                            <option value="Tax-Deduction-Supporting-Documents">Tax Deduction Supporting Documents</option>
                                            <option value="Employment-Contract">Employment Contract</option>
                                            <option value="Previous-Employment-Documents">Previous Employment Documents</option>
                                            <option value="Bank-Account-Details">Bank Account Details</option>
                                            <option value="Other">Other</option>
                                        </select>

                                        <label className="ps-1 form-label  mt-3" htmlFor="floatingSelectTask">Description</label>
                                        <div className="form-floating ">
                                            <textarea className="form-control" id="floatingProjectOverview" placeholder="Description" style={{ height: "100px" }}
                                                value={formData.Description} onChange={e => setFormData({ ...formData, Description: e.target.value })}>
                                            </textarea>
                                            <label htmlFor="floatingProjectOverview"></label>
                                        </div>

                                        <label className="ps-1 form-label  mt-3" htmlFor="floatingSelectTask">Upload Document/Image <span style={{ color: "red" }}> *</span></label>
                                        <Dropzone onFilesAccepted={handleFilesAccepted} isShowImages={showImages} />

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={uploadDocuments} className="btn btn-primary" type="button">UPLOAD DOCUMENT(S)</button>
                                    <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* here update dialog */}
                    <div className="modal fade" id="viewDocumentsModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="verticallyCenteredModalLabel">ViewDocuments</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                                </div>
                                {isLoading ? <ShowLoadingIcon /> : ''}
                                <div className="modal-body">
                                    <div className="document-gallery">
                                        {fileNames && fileNames.length > 0 ? (
                                            fileNames.map((name, index) => (
                                                <div
                                                    key={index}
                                                    className="document-item"
                                                    style={{
                                                        cursor: 'pointer',
                                                        border: '1px solid #ccc',
                                                        display: 'inline-block',
                                                        width: '200px',
                                                        height: '200px',
                                                        margin: '10px',
                                                        textAlign: 'center',
                                                        padding: '30px',
                                                        boxSizing: 'border-box',
                                                    }}
                                                >
                                                    <a href={`${ImageSrcURL}/${folderPath}/${name}`} target="_blank" rel="noopener noreferrer" className="document-link">
                                                        <img src={getFileIcon(name)} alt="File Icon" style={{ width: '50px' }} />

                                                        <div className="document-name" style={{ fontSize: '11px' }}>{name}</div>
                                                        <div className="document-details">

                                                            <span className="document-name" style={{ fontSize: '11px' }}>Uploaded on: {uploadedDate}</span>
                                                            <br />
                                                            <span className="document-name" style={{ fontSize: '11px' }}>Uploaded by: {userName}</span>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <div>No files found.</div>
                                        )}
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end dialog */}
                </div>
            </main>

        </>
    )
}

export default GetDocuments