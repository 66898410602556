import { Post, Delete, Get } from '../../Services/HttpClient/HttpRequest';
import * as APIs from '../../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../../Helpers/app.label';

//Get Employee
export const getUserById = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getUserById + "/" + id);

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}
//Validate employee details
const validateEmployeeDetails = (formData, needToValidateProfileImage, needToValidatePassword, needToValidateBankDetails) => {
    let result = { Message: '' }

    if (!formData.name) {
        result.Message = "First name is required!"
    } else if (!formData.last_name) {
        result.Message = "Last name is required!"
    } else if (!formData.HireDate) {
        result.Message = "Hire date is required!"
    } else if (formData.DOB > formData.HireDate) {
        result.Message = "HireDate should be greter than DOB!"
    } else if (!formData.DOB) {
        result.Message = "Date of birth is required!"
    } else if (new Date(formData.DOB) > new Date()) {
        result.Message = "Date of birth cannot be in the future!"
    } else if (!formData.Phone) {
        result.Message = "Phone number is required!"
    } else if (!(/^\d{10}$/.test(formData.Phone))) {
        result.Message = "Invalid phone number!"
    } else if (!formData.email) {
        result.Message = "Email is required!"
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        result.Message = "Invalid email address!"
    } else if (!formData.Username) {
        result.Message = "Username is required!"
    } else if (!formData.ProfileImage && needToValidateProfileImage) {
        result.Message = "Profile Image is required!"
    } else if (!formData.JobTitle) {
        result.Message = "Job Title is required!"
    } else if (!formData.Department) {
        result.Message = "Department is required!"
    } else if (!formData.Manager && localStorage.getItem('IsAccountOwner') !== "1") {
        result.Message = "Manager is required!"
    } else if (!formData.RoleId) {
        result.Message = "Role is required!"
    } else if (!formData.AnnualSalary) {
        result.Message = "Annual Salary is required!"
    } else if (!formData.Location) {
        result.Message = "Location is required!"
    } else if (!formData.password && needToValidatePassword) {
        result.Message = "Password is required!"
    } else if (!formData.cpassword && needToValidatePassword) {
        result.Message = "Confirm Password is required!"
    } else if (formData.password !== formData.cpassword) {
        result.Message = 'Password Missmatch !';
    }
    // else if (!formData.BankName && needToValidateBankDetails) {
    //     result.Message = "Bank name is required!"
    // } else if (!formData.BranchName && needToValidateBankDetails) {
    //     result.Message = "Branch name is required!"
    // } else if (!formData.BankAccountNumber && needToValidateBankDetails) {
    //     result.Message = "Account number is required!"
    // } else if (!formData.IFSCcode && needToValidateBankDetails) {
    //     result.Message = "IFSC code is required!"
    // } else if (!formData.PANnumber && needToValidateBankDetails) {
    //     result.Message = "Pan number is required!"
    // }     

    return result;
}
// Add Update Employee Data
export const addUpdateEmployeeData = async (formData, needToValidateProfileImage, needToValidatePassword, needToValidateBankDetails) => {
    debugger
    let response = validateEmployeeDetails(formData, needToValidateProfileImage, needToValidatePassword, needToValidateBankDetails);

    if (!response.Message) {
        // let response = "";
        try {
            const data = new FormData();
            data.append('Id', formData.id);
            data.append('name', formData.name);
            data.append('middle_name', formData.middle_name);
            data.append('last_name', formData.last_name);
            data.append('Sex', formData.Sex);
            data.append('DOB', formData.DOB);
            data.append('Phone', formData.Phone);
            data.append('email', formData.email);
            data.append('Username', formData.Username);
            data.append('password', formData.password);
            data.append('ProfileImage', formData.ProfileImage);

            data.append('HireDate', formData.HireDate);
            data.append('JobTitle', formData.JobTitle);
            data.append('Department', formData.Department);
            data.append('Manager', formData.Manager);
            data.append('Role', formData.RoleId);
            data.append('AnnualSalary', formData.AnnualSalary);
            data.append('Location', formData.Location);

            data.append('BankName', formData.BankName);
            data.append('BranchName', formData.BranchName);
            data.append('AccountNumber', formData.BankAccountNumber);
            data.append('IfscCode', formData.IFSCcode);
            data.append('PanNumber', formData.PANnumber);

            response = await Post((APIs.addUpdateUser), data)
            debugger
            if (response && needToValidateProfileImage && (formData.id.toString() === localStorage.getItem('UserId'))) {
                const getProfile = await Get(APIs.getUserById + "/" + formData.id);
                if (getProfile) {
                    localStorage.removeItem('UserProfileImage');
                    localStorage.setItem("UserProfileImage", getProfile.ProfileImageName);
                }
            }
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}

//Delete Employee
export const deleteEmployee = async (id) => {
    let response = "";
    try {
        response = await Delete((APIs.deleteUser + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}

//Get transaction
export const getEmployeeById = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getUserById + "/" + id);

        if (response) {
            result = response;
            localStorage.setItem('employeeName', response.name);
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

//Validate employee details
const validatePasswordForm = (passwordData) => {
    let result = { Message: '' }

    if (!passwordData.password) {
        result.Message = "Password is required!"
    } else if (!passwordData.cpassword) {
        result.Message = "Confirm Password is required!"
    } else if (passwordData.password !== passwordData.cpassword) {
        result.Message = 'Password Missmatch !';
    }
    return result;
}
//Get Employee
export const changeUserPassword = async (id, passwordData) => {
    let response = validatePasswordForm(passwordData);

    if (!response.Message) {
        try {
            const data = new FormData();
            data.append('password', passwordData.cpassword);

            response = await Post((APIs.changePassword + "/" + id), data)

        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}

// Get managers from Database
export const getManagers = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getManagers + "/" + id)

        if (response !== null) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

// Get roles from Database
export const getRoles = async () => {
    let result = "";
    try {
        const response = await Get(APIs.getAllRoles)

        if (response !== null) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}
export const changeOwnerEmployee = async (id) => {
    let response = "";
    try {
        response = await Post((APIs.changeOwnerEmployee + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}
