import { Post, Delete, Get } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

const validateBlog = (formData, needToValidate) => {
    let result = { message: '' }
    if (!formData.Title) {
        result.message = "Title is required!"
    } else if (!formData.CategoryId) {
        result.message = "Category is required!"
    } else if (!formData.MetaTitle) {
        result.message = "Meta Title is required!"
    } else if (!formData.image && needToValidate) {
        result.message = "Thumbnail Image is required!"
    } else if (!formData.MetaDescription) {
        result.message = "Meta Description is required!"
    } else if (!formData.BlogDescription) {
        result.message = "Description is required!"
    }
    return result;
}

// Add Update Blog Data
export const addUpdateBlogData = async (formData, needToValidate) => {
    let response = validateBlog(formData, needToValidate);

    if (!response.message) {

        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('Title', formData.Title);
            data.append('CategoryId', formData.CategoryId);
            data.append('MetaTitle', formData.MetaTitle);
            data.append('image', formData.image);
            data.append('MetaDescription', formData.MetaDescription);
            data.append('BlogDescription', formData.BlogDescription);

            response = await Post((APIs.addUpdateBlog), data)
            
        } catch (error) {
            response = "";
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}

//Delete Blog
export const DeleteBlog = async (id) => {
    let response = "";
    try {
        response = await Delete((APIs.deleteBlog + "/" + id));

    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}

//Get blog 
export const getBlogById = async (id) => {
    let result = "";
    try {
        const response = await Get((APIs.getBlogById + "/" + id));

        if (response.blogDetails) {
            result = response.blogDetails;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}