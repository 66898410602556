import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Layout/Navbar';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { addUpdateEmployeeData, getEmployeeById, getManagers, getRoles } from '../../Components/Employee/EmployeeComponent.js';
import Messages from '../../Helpers/app.label';
import { ShowLoadingIcon } from '../../Helpers/Loader';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageSrcURL } from '../../Helpers/app.constant.api';
import { IndianStates } from '../../Helpers/IndianStates';

const AddUpdateEmployee = () => {

    document.title = 'Add Update Employee | Codes';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pageName, setDialogName] = useState(false);
    const [formButtonName, setDialogButtonName] = useState(false);
    const [formData, setFormData] = useState('');
    const [wantToUploadNewImage, setNewUploadImageBit] = useState(false);
    const [needToValidatePassword, setNeedToValidatePassword] = useState(false);
   // const [needToValidateBankDetails, setNeedToValidateBankDetails] = useState(false);
    const [managers, setManagers] = useState('');
    const [roles, setRoles] = useState('');

    let intialAddUpdateFormState = {
        id: 0, name: "", middle_name: "", last_name: "", Sex: 1, DOB: "", Phone: "", email: "", Username: "", ProfileImageName: "",
        HireDate: "", JobTitle: "", Department: "", Manager: "", AnnualSalary: "", Location: "", RoleId: "",
        BankName: "", BranchName: "", BankAccountNumber: "", IFSCcode: "", PANnumber: "", IsAccountOwner: 0
    }

    let inputDOBDate = formData.DOB;
    let DOB = inputDOBDate ? inputDOBDate.substring(0, 10) : "";

    let inputHireDate = formData.HireDate;
    let HireDate = inputHireDate ? inputHireDate.substring(0, 10) : "";

    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");

    useEffect(() => {
        getEmployeeData(id);
        fetchManagersAndRoles();
    }, []);

    // Fetch managers from DB
    const fetchManagersAndRoles = async () => {
        try {
            let managerData = await getManagers(id);
            setManagers(managerData.result);

            let roleData = await getRoles();
            setRoles(roleData);
        } catch (error) {
            console.error(error);
        }
    };

    const getEmployeeData = async (id) => {

        setIsLoading(true);

        let dialogNameEmp = "";
        let dialogButtonNameEmp = "";

        try {
            if (!id) {
                dialogNameEmp = "Add Employee";
                dialogButtonNameEmp = "Add";
                $("#hidebutton").hide();
                setNeedToValidatePassword(true);
              //  setNeedToValidateBankDetails(true);
            } else {
                dialogNameEmp = "Update Employee";
                dialogButtonNameEmp = "Update";

                intialAddUpdateFormState = await getEmployeeById(id);

                if (!intialAddUpdateFormState) {
                    throw new Error(Messages.Error);
                }
            }
            setDialogName(dialogNameEmp);
            setDialogButtonName(dialogButtonNameEmp);
            setFormData(intialAddUpdateFormState);
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    }

    const addUpdateEmployee = async () => {
        debugger
        setIsLoading(true);

        let needToValidateProfileImage = !(formData.Id !== 0 && !wantToUploadNewImage);
        let needToValidateBankDetails = id === 0 || id === null || id === "" ? false : true;

        try {
            const response = await addUpdateEmployeeData(formData, needToValidateProfileImage, needToValidatePassword, needToValidateBankDetails);
            let msg = ['Employee Successfully Created', 'Employee Successfully Updated']

            if (response && msg.includes(response.Message)) {
                let SuccessToast = await toast.success(response.Message);
                setFormData(intialAddUpdateFormState);
                if (id) {
                    window.location.reload(false);
                } else {
                    if (SuccessToast) {
                        navigate('/Employee');
                    }
                }
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }

        setIsLoading(false);
    }

    //Handle thumbnail image 
    const hideShowThumbnailImage = async (isHide) => {
        if (isHide) {
            $(".divThumbnailImage").hide();
            $(".divUploadFile").show();
        } else {
            $(".divUploadFile").hide();
            $(".divThumbnailImage").show();
        }
        setNewUploadImageBit(isHide);
    }
    //Handel View Documents
    const handelViewDocuments = () => {
        navigate(`/Documents?id=${id}`);
    }
    const RedirectViewAttendence = () => {
        navigate(`/Attendance?id=${id}`);
    }
    return (
        <>
            <main className="main" id="top">

                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">

                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active"><NavLink to="/Employee">Employees</NavLink></li>
                                <li className="breadcrumb-item active">{pageName}</li>
                            </ol>
                        </nav>
                        <h2 className="mb-4">{pageName}</h2>
                        <h4 className="fs-1 mb-4">Personal Details</h4>
                        <div className="row">
                            <div className="col-xl-9">
                                <form id="addUpdateEmployeeForm" encType="multipart/form-data" className="row g-3 mb-6">

                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-floating">
                                            <input value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">First Name <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-floating">
                                            <input value={formData.middle_name === "null" ? "" : formData.middle_name} onChange={e => setFormData({ ...formData, middle_name: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Middle Name <span className="text-danger"></span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-floating">
                                            <input value={formData.last_name} onChange={e => setFormData({ ...formData, last_name: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Last Name <span className="text-danger">*</span></label>
                                        </div>

                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={HireDate} onChange={e => setFormData({ ...formData, HireDate: e.target.value })} className="form-control" id="floatingInputGrid" type="date" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Hire Date <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={DOB} onChange={e => setFormData({ ...formData, DOB: e.target.value })} className="form-control" id="floatingInputGrid" type="date" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">DOB <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <label className="form-label" htmlFor="floatingInputStartDate">Gender<span style={{ color: "red" }}> *</span></label>
                                        {formData.id === 0 ? (
                                            <>
                                                <label className="ps-5 radio-inline col-6">
                                                    <input type="radio" name="gender" value="1" onChange={e => setFormData({ ...formData, Sex: e.target.value })} checked /> Male
                                                </label> &nbsp;&nbsp;
                                                <label className="ps-12 radio-inline col-6">
                                                    <input type="radio" name="gender" value="2" onChange={e => setFormData({ ...formData, Sex: e.target.value })} /> Female
                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <label className="ps-5 radio-inline col-6">
                                                    <input type="radio" value="1" name="gender" id="gender" {...(formData.Sex === "1" ? { checked: true } : {})} onChange={e => setFormData({ ...formData, Sex: e.target.value })} /> Male
                                                </label> &nbsp;&nbsp;
                                                <label className="ps-12 radio-inline col-6">
                                                    <input type="radio" value="2" name="gender" id="gender" {...(formData.Sex === "2" ? { checked: true } : {})} onChange={e => setFormData({ ...formData, Sex: e.target.value })} /> Female
                                                </label>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.Phone} onChange={e => setFormData({ ...formData, Phone: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Phone <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Email <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input value={formData.Username} onChange={e => setFormData({ ...formData, Username: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                            <label htmlFor="floatingInputGrid">Username <span className="text-danger">*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Profile Image <span className="text-danger">*</span></label>
                                        {formData.id === 0 ?
                                            <input onChange={e => setFormData({ ...formData, ProfileImage: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" type="file" />
                                            :
                                            <>
                                                {/* <div className="divThumbnailImage" style={{ float: "right", marginTop: "6px" }}>
                                                    <img src={`${ImageSrcURL}/${formData.ProfileImageName}`} alt="Not found" width="200" height="auto" />
                                                    <i onClick={() => hideShowThumbnailImage(true)} title="Remove Thumbail" style={{ padding: "6px 12px", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer", verticalAlign: "bottom", marginleft: "8px" }}><FontAwesomeIcon icon="fa-solid fa-trash" /> </i>
                                                </div> */}

                                                <div style={{ float: "right", marginTop: "6px" }} className="divThumbnailImage">
                                                    <a href={`${ImageSrcURL}/${formData.ProfileImageName}`} target="_blank" rel="noopener noreferrer" className="document-link">
                                                        <img src={'/Assets/img/icons/jpgimage.png'} alt="File Icon" style={{ width: "70px", height: "auto", marginRight: "240px" }} className="document-icon" />
                                                    </a>
                                                    <i onClick={() => hideShowThumbnailImage(true)} title="Remove Thumbnail" style={{ padding: "6px 12px", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer", verticalAlign: "bottom", marginLeft: "70px", marginTop: "-40px" }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-trash" />
                                                    </i>
                                                </div>

                                                <div style={{ display: "none" }} className="divUploadFile">
                                                    <div className='row'>
                                                        <div className='col-11'>
                                                            <input onChange={e => setFormData({ ...formData, ProfileImage: e.target.files[0] })} accept=".jpg, .jpeg, .png" className="form-control" id="floatingInputStartDate" type="file" placeholder="" />
                                                        </div>
                                                        <div className='col-1'>
                                                            <i onClick={() => hideShowThumbnailImage(false)} title="Undo Thumbail" style={{ padding: "7px 12px", float: "right", textAlign: "center", display: "inline-block", color: "black", cursor: "pointer" }}>
                                                                <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputGrid">Job Title <span className="text-danger">*</span></label>
                                        <input value={formData.JobTitle} onChange={e => setFormData({ ...formData, JobTitle: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Job Title" />
                                    </div>

                                    <div className="col-sm-6 col-md-4">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Role <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.RoleId} onChange={e => setFormData({ ...formData, RoleId: e.target.value })} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Role</option>
                                            {Array.from(roles).map(role => (
                                                <option key={role.Id} value={role.Id}>
                                                    {role.RoleName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-sm-6 col-md-4">
                                        <label className="ps-1 form-label" htmlFor="floatingInputGrid">Department <span className="text-danger">*</span></label>
                                        <input value={formData.Department} onChange={e => setFormData({ ...formData, Department: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Department" />
                                    </div>

                                    <div className="col-sm-6 col-md-4">
                                        <label className="ps-1 form-label" htmlFor="floatingInputGrid">Annual Salary <span className="text-danger">*</span></label>
                                        <input value={formData.AnnualSalary} onChange={e => setFormData({ ...formData, AnnualSalary: e.target.value })} className="form-control" id="floatingInputGrid" type="number" placeholder="Annual Salary" />
                                    </div>

                                    {(formData.IsAccountOwner === 1 || formData.IsAccountOwner === "1") ?
                                        (<>
                                            <div className="col-sm-12 col-md-12 mb-3">
                                                <div className="">
                                                    <label className="ps-1 form-label" htmlFor="floatingInputGrid">Location <span className="text-danger">*</span></label>
                                                    <select value={formData.Location} onChange={e => setFormData({ ...formData, Location: e.target.value })} className="form-select" id="floatingSelectTask">
                                                        <option value="">Select Location</option>
                                                        {Object.entries(IndianStates).map(([key, value]) => (<option key={key} value={key}> {value} </option>))}
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                        ) : (
                                            <>
                                    <div className="col-sm-6 col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingSelectTask">Manager <span style={{ color: "red" }}> *</span></label>
                                        <select value={formData.Manager} onChange={e => setFormData({ ...formData, Manager: e.target.value })} className="form-select" id="floatingSelectTask">
                                            <option value="">Select Manager</option>
                                            {Array.from(managers).map(manager => (
                                                <option key={manager.id} value={manager.id}>
                                                    {manager.name + " " + manager.last_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 col-md-6 mb-3">
                                        <div className="">
                                            <label className="ps-1 form-label" htmlFor="floatingInputGrid">Location <span className="text-danger">*</span></label>
                                            <select value={formData.Location} onChange={e => setFormData({ ...formData, Location: e.target.value })} className="form-select" id="floatingSelectTask">
                                                <option value="">Select Location</option>
                                                {Object.entries(IndianStates).map(([key, value]) => (<option key={key} value={key}> {value} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                            </>
                                        )}

                                    {formData.id === 0 ? (
                                        <>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input onChange={e => setFormData({ ...formData, password: e.target.value })} className="form-control" id="floatingInputGrid" type="password" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Password <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 mb-4">
                                                <div className="form-floating">
                                                    <input onChange={e => setFormData({ ...formData, cpassword: e.target.value })} className="form-control" id="floatingInputGrid" type="password" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Confirm Password <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                        </>
                                    ) : ("")}

                                    {formData.id !== 0 ? (
                                        <>
                                            <h4 className="fs-1 mb-2">Bank Details</h4>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input value={formData.BankName === "null" ? "" : formData.BankName} onChange={e => setFormData({ ...formData, BankName: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Bank Name <span className="text-danger"></span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input value={formData.BranchName === "null" ? "" : formData.BranchName} onChange={e => setFormData({ ...formData, BranchName: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Branch Name <span className="text-danger"></span></label>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input value={formData.BankAccountNumber === "null" ? "" : formData.BankAccountNumber} onChange={e => setFormData({ ...formData, BankAccountNumber: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">Account Number <span className="text-danger"></span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input value={formData.IFSCcode === "null" ? "" : formData.IFSCcode} onChange={e => setFormData({ ...formData, IFSCcode: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">IFSC Code <span className="text-danger"></span></label>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-floating">
                                                    <input value={formData.PANnumber === "null" ? "" : formData.PANnumber} onChange={e => setFormData({ ...formData, PANnumber: e.target.value })} className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                    <label htmlFor="floatingInputGrid">PAN Number <span className="text-danger"></span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 mb-4">
                                            </div>
                                        </>
                                    ) : ("")}

                                    <div className="col-12 gy-400">
                                        <div className="row g-3 justify-content-end">
                                            <div className="col-auto">
                                                <button onClick={() => {
                                                    navigate('/Employee')
                                                }} className="btn btn-phoenix-primary px-5">Cancel
                                                </button>
                                            </div>
                                            <div className="col-auto"><button onClick={addUpdateEmployee} type="button" className="btn btn-primary px-5 px-sm-15">{formButtonName}</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-xl-3" id="hidebutton">
                                <div className="d-flex flex-column justify-content-between" style={{ border: "1px solid gray", padding: "10px", marginBottom: "10px" }}>
                                    <button type="button" className="btn btn-success px-3 px-sm-3 mb-2">
                                        Generate Document
                                    </button>
                                    <button type="button" onClick={RedirectViewAttendence} className="btn btn-success px-3 px-sm-4 mb-2">
                                        View Attendance
                                    </button>
                                    <button type="button" className="btn btn-success px-3 px-sm-5 mr-2 mb-2">
                                        View Payslips
                                    </button>
                                    <button type="button" className="btn btn-success px-3 px-sm-5 mr-2 mb-2">
                                        View Tax Deductions
                                    </button>
                                    <button type="button" className="btn btn-success px-3 px-sm-5 mr-2 mb-2">
                                        View Reimbursements
                                    </button>
                                    <button onClick={handelViewDocuments} type="button" className="btn btn-warning px-3 px-sm-5 mr-2 mb-2">
                                        View Documents
                                    </button>
                                    <button type="button" className="btn btn-success px-3 px-sm-5 mr-2 mb-2">
                                        View Notes
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                    </div>
                </div >
            </main >
        </>
    )
}

export default AddUpdateEmployee
