import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import MUIGrid from '../Controller/MUIGrid';
import Messages from '../Helpers/app.label';
import * as APIs from '../Helpers/app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';

const Holidays = () => {

    document.title = 'Holidays | Codes';
    const [holidaysData, setHolidaysData] = useState('');

    const columns = [
        {
            field: 'SRNO', headerName: 'SRNO', width: 70
        },{
            field: 'Date',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            width: 150,
            editable: false,
        },{
            field: 'DayName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Day',
            width: 150,
            editable: false,
        }, {
            field: 'HolidayName',
            headerClassName: 'super-app-theme--header',
            headerName: 'Holiday Name',
            width: 500,
            editable: false,
        }
        , {
            field: 'Type',
            headerClassName: 'super-app-theme--header',
            headerName: 'Type',
            width: 200,
            editable: false,
            renderCell: (params) => {
                const type = params.getValue(params.id, 'Type');

                if (type === 1) {
                    return <span style={{ color: 'blue' }}>Regional</span>;
                } else if (type === 2) {
                    return <span style={{ color: 'green' }}>National</span>;
                }

                return null;
            }
        }
    ];

    useEffect(() => {
        getAllHolidays();
    }, []);

    // Get All Categories
    const getAllHolidays = async () => {

        try {
            let response = await Get(APIs.getAllHolidayList);

            if (response) {
                setHolidaysData(response);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    }

    const rowsWithSrno = Array.from(holidaysData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    // Design Section
    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
                                <li className="breadcrumb-item active">Holidays</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Holidays</h2>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                    </div>
                </div>
            </main>
        </>
    )
}

export default Holidays