import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom/dist';

const ProtectedRoute = (props) => {

  const {Component} = props;
  const navigate = useNavigate();

  useEffect(()=>{
    let login = localStorage.getItem("token");
    if(!login){
      navigate("/");
    }
  });

  return(
    <>
       <Component/>
    </>
  )
};
export default ProtectedRoute;
