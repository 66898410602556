import { Delete, Post } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';


//Delete Subscriber
export const deleteSubscriber = async (id) => {

    let response = "";
    try {
        response = await Delete((APIs.deleteSubscriber + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}

//Send Mail
export const sendMail = async (id) => {
    let result = "";
    try {
        const response = await Post(APIs.sendEmail + "/" + id);

        if (response.Status === 200) {
            result = "Mail_Send_Success"
        }
    } catch (error) {
        toast.error("Something went wrong, error occured while sending mail!");
    }
    return result;
}

export const sendMailToMultipleSubscribers = async (selectedRows) => {
   
        let result = "";
        try {
            const data = new FormData();
                data.append('Ids', selectedRows);

            // response = await Post(APIs.addEmployeeDocuments, data)
            const response = await Post(APIs.sendEmailToAll,data);

            if (response.Status === 200) {
                result = "Mail_Send_Success"
            }
        } catch (error) {
            toast.error("Something went wrong, error occured while sending mail!");
        }
        return result;
    }
    