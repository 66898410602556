import { Get } from '../Services/HttpClient/HttpRequest';
import 'react-toastify/dist/ReactToastify.css';
import * as APIs from '../Helpers/app.constant.api';
import Messages from '../Helpers/app.label';

// Get All Categories from Database
export const getMaintenance = async () => {
    let result = "";
    try {
        const response = await Get(APIs.viewMaintenance)

        if (response !== null) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

//Get Category 
export const viewErrorMessage = async (Id) => {
    let result = "";
    try {
        const response = await Get(APIs.viewErrorMessage + "/" + Id);

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}


