import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { Delete, Edit } from '@mui/icons-material';
import * as Component from '../Components/OrganizationsComponent.js';
import MUIGrid from '../Controller/MUIGrid';

import ConformationDialog from '../Controller/ConformationDialog';
import * as Common from '../Controller/Common';
import { ShowLoadingIcon } from '../Helpers/Loader';
import Messages from '../Helpers/app.label';
import Tooltip from '@mui/material/Tooltip';

const Organizations = () => {

    document.title = 'Organizations | Codes';
    const [isLoading, setIsLoading] = useState(false);
    const [orgnizationsData, setOrganizationData] = useState('');
    const [blogCategoryFormData, setBlogCategoryFormData] = useState('');
    const [dialogName, setDialogName] = useState(false);
    const [dialogButtonName, setDialogButtonName] = useState(false);
    let initialAddUpdateFormState = { Id: 0, Name: "" }
    const columns = [
        // {
        //     field: 'edit',
        //     headerName: '',
        //     width: 80,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <>
        //             <Tooltip title="Edit Blog Category">
        //                 <div style={{ marginRight: '5px', display: 'inline-block' }}>

        //                     <Edit
        //                         fontSize='small'
        //                         color="#525B75"
        //                         type="button"
        //                         data-bs-toggle="modal"
        //                         data-bs-target="#addUpdateBlogCategoryModal"
        //                         onClick={() => {
        //                             openBlogCategoryDialog(params.row.Id)
        //                         }}
        //                     />
        //                 </div>
        //             </Tooltip>
        //             <>
        //                 <Tooltip title="Delete Blog Category">
        //                     <div style={{ marginRight: '5px', display: 'inline-block' }}>
        //                         <Delete
        //                             fontSize='small'
        //                             color="#525B75"
        //                             type="button"
        //                             data-bs-toggle="modal"
        //                             data-bs-target="#deleteBlogCategory"
        //                             onClick={() => {
        //                                 // Component.DeleteBlogCatagory(params.row.Id)
        //                                 setBlogCategoryFormData({ ...blogCategoryFormData, Id: params.row.Id })
        //                                 //setCategoryId(params.row.id)
        //                             }}
        //                         />
        //                     </div>
        //                 </Tooltip>
        //             </>
        //         </>
        //     ),
        // },
        {
            field: 'Id', headerName: 'SRNO', width: 90
        },
        {
            field: 'Name',
            headerClassName: 'super-app-theme--header',
            headerName: 'Name',
            width: 150,
            editable: false,
        },
        {
            field: 'Address',
            headerClassName: 'super-app-theme--header',
            headerName: 'Address',
            width: 300,
            editable: false,
        },

        {
            field: 'City',
            headerClassName: 'super-app-theme--header',
            headerName: 'City',
            width: 150,
            editable: false,
        },

        {
            field: 'State',
            headerClassName: 'super-app-theme--header',
            headerName: 'State',
            width: 100,
            editable: false,
        },
        {
            field: 'PinCode',
            headerClassName: 'super-app-theme--header',
            headerName: 'PinCode',
            width: 100,
            editable: false,
        },

        {
            field: 'CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'CreatedDate',
            width: 150,
            editable: false,
        },
        {
            field: 'ModifiedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'ModifiedDate',
            width: 150,
            editable: false,
        },

    ];

    useEffect(() => {
        getAllOrganizations();
    }, []);

    // Get All Categories
    const getAllOrganizations = async () => {

        try {
            let response = await Component.getOrganizations();

            if (response) {
                setOrganizationData(response.Organizations);
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
    }

    // Get single category details by ID
    const openBlogCategoryDialog = async (id) => {
        setIsLoading(true);
        let dialogName = "";
        let dialogButtonName = "";

        try {
            if (id === 0) {
                dialogName = "Add Category";
                dialogButtonName = "Add";
            } else {
                dialogName = "Update Category";
                dialogButtonName = "Update";

                initialAddUpdateFormState = await Component.getCategoryById(id);

                if (!initialAddUpdateFormState) {
                    throw new Error(Messages.Error);
                }
            }

            setDialogName(dialogName);
            setDialogButtonName(dialogButtonName);
            setBlogCategoryFormData(initialAddUpdateFormState);
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    }

    // Add Update Blog Category
    const addUpdateCategory = async () => {
        setIsLoading(true);
        try {

            const response = await Component.addUpdateBlogCategory(blogCategoryFormData);
            let msg = ['Blog Category Updated Successfully!', 'Blog Category Created Successfully!']

            if (response && msg.includes(response.message)) {
                toast.success(response.message);
                Common.CloseDialog("addUpdateBlogCategoryModal");
                // getAllBlogCategories();
            } else if (response.message === 'The name has already been taken.') {
                toast.warning('Blog Category Already Exist!');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(Messages.ADD_UPDATE_BLOG_CATEGORY_EXCEPTION_MSG);
        }

        setIsLoading(false);
    };

    // Delete Category
    const deleteBlogCategory = async () => {
        setIsLoading(true);
        try {
            const response = await Component.DeleteBlogCatagory(blogCategoryFormData.Id)

            if (response.Result === true) {
                setIsLoading(false);
                toast.success(Messages.DELETE_BLOG_CATEGORY_SUCCESS_MSG);
                // getAllBlogCategories();
            } else {
            }
        } catch (error) {
            toast.error(Messages.DELETE_BLOG_CATEGORY_EXCEPTION_MSG);
        }

        setIsLoading(false);
    }

    // Design Section
    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
                                <li className="breadcrumb-item active">Organizations</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Organizations</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addUpdateBlogCategoryModal" onClick={() => { openBlogCategoryDialog(0) }}><span className="fas fa-plus me-2"></span>Add Organizations</button>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={orgnizationsData} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* Added Model */}
                        {/* <Model modelId={modelId} /> */}

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure to delete this Blog Category !"} pageName={"Delete" + " " + "BlogCategory"} onOkayClick={deleteBlogCategory} id={"deleteBlogCategory"} />

                        {/* This button for open model & its hidden button */}
                        <button className="btn btn-primary d-none" type="button" data-bs-toggle="modal" data-bs-target="#verticallyCentered">Vertically centered modal</button>

                        {/* here update dialog */}
                        <div className="modal fade" id="addUpdateBlogCategoryModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{dialogName}</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                                    </div>
                                    {isLoading ? <ShowLoadingIcon /> : ''}
                                    <div className="modal-body">
                                        <form id="addUpdateBlogCategoryForm" encType="multipart/form-data">
                                            {/* <input
                                                className="form-control"
                                                id="categoryName"
                                                name='categoryName'
                                                value={blogCategoryFormData.Name}
                                                placeholder='Enter category name'
                                                onChange={e => setBlogCategoryFormData({ ...blogCategoryFormData, Name: e.target.value })}
                                            /> */}
                                            <div className='row'>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-floating">
                                                        <input className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                        <label htmlFor="floatingInputGrid">First Name <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-floating">
                                                        <input className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                        <label htmlFor="floatingInputGrid">Middle Name <span className="text-danger"></span></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-floating">
                                                        <input className="form-control" id="floatingInputGrid" type="text" placeholder="Title" />
                                                        <label htmlFor="floatingInputGrid">Last Name <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={() => addUpdateCategory()} className="btn btn-primary" id='okayButton' type="button">{dialogButtonName}</button>
                                        <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end dialog */}
                    </div>
                </div>
            </main>
        </>
    )
}

export default Organizations