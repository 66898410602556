import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';

import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import ProtectedRoute from './Routes/ProtectedRoute';
import Blogs from './Pages/Blogs';
import Portfolio from './Pages/Portfolio/Index';
import BlogCategories from './Pages/BlogCategories';
import AddUpdatePortfolio from './Pages/Portfolio/AddUpdatePortfolio';
import Subscriber from './Pages/Subscriber';
import ForgotPassword from './Pages/Authentication/ForgotPassword';
import ResetPassword from './Pages/Authentication/ResetPassword';
import Transactions from './Pages/Transactions';
import Employee from './Pages/Employee/Employee';
import AddUpdateEmployee from './Pages/Employee/AddUpdateEmployee';
import GetDocuments from './Pages/Employee/GetDocuments';
import Roles from './Pages/Employee/Roles';
import Maintenance from './Pages/Maintenance';
import Organizations from './Pages/Organizations';
import Attendance from './Pages/Attendance/Attendance';
import AttendanceDetails from './Pages/Attendance/AttendanceDetails';
import Settings from './Pages/OrganizationsSettings';
import Payslips from './Pages/Payslips';
import ManageLeaves from './Pages/Attendance/ManageLeaves';
import PendingApproval from './Pages/Attendance/PendingApproval';
import Notifications from './Pages/Notifications';
import Forbidden from './Pages/Forbidden';
import Holidays from './Pages/Holidays';

const App = () => {

  const checkLogin = localStorage.getItem('token');
  const accessWebsite = localStorage.getItem('Email') === "info@codestec.com" ? true : false;
  const accessOrganization = localStorage.getItem('Email') === "hanif@gmail.com" || localStorage.getItem('Email') === "info@codestec.com" || localStorage.getItem('Email') === "makhdumc@codestec.com" ? true : false;
  const accessSuperAdmin = localStorage.getItem('Email') === "info@codestec.com" || localStorage.getItem('Email') === "makhdumc@codestec.com" ? true : false;
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path='/' element={ <Login />} />

          {/* <Route path='/' element={checkLogin ? <ProtectedRoute Component={Dashboard} /> : <Login />} /> */}

          <Route path='ForgotPassword' element={<ForgotPassword />} />
          <Route path='/ResetPassword' element={<ResetPassword />} />

          {/* <Route path='ResetPassword/:token' element = {<ResetPassword/>}/> */}
          {/* <Route path='/ViewDocuments' element = {<ViewDocuments/>}/> */}

          {/* Protected Routes */}

          {/* Website */}
          <Route path='Blogs' element={accessWebsite ? <ProtectedRoute Component={Blogs} /> : <Forbidden />} />
          <Route path='Portfolio' element={accessWebsite ? <ProtectedRoute Component={Portfolio} /> : <Forbidden />} />
          <Route path='BlogCategory' element={accessWebsite ? <ProtectedRoute Component={BlogCategories} /> : <Forbidden />} />
          <Route path='Subscriber' element={accessWebsite ? <ProtectedRoute Component={Subscriber} /> : <Forbidden />} />

          {/* Organization */}
          <Route path='/AddUpdatePortfolio/:id?' element={accessWebsite ? <ProtectedRoute Component={AddUpdatePortfolio} /> : <Forbidden />} />
          <Route path='Transactions' element={accessOrganization ? <ProtectedRoute Component={Transactions} /> : <Forbidden />} />
          <Route path='Employee' element={ accessOrganization ? <ProtectedRoute Component={Employee} /> : <Forbidden />} />
          <Route path='AddUpdateEmployee' element={accessOrganization ? <ProtectedRoute Component={AddUpdateEmployee} /> : <Forbidden />} />
          <Route path='Documents/:id?' element={accessOrganization ? <ProtectedRoute Component={GetDocuments} /> : <Forbidden />} />
          <Route path='Roles' element={accessOrganization ? <ProtectedRoute Component={Roles} /> : <Forbidden />} />
          <Route path='OrganizationSettings' element={accessOrganization ? <ProtectedRoute Component={Settings} /> : <Forbidden />} />

          {/* Super Admin */}
          <Route path='Maintenance' element={accessSuperAdmin ? <ProtectedRoute Component={Maintenance} /> : <Forbidden />} />
          <Route path='Organizations' element={accessSuperAdmin ? <ProtectedRoute Component={Organizations} /> : <Forbidden />} />

          {/* Employee */}
          <Route path='Dashboard' element={<ProtectedRoute Component={Dashboard} />} />
          <Route path='Attendance' element={<ProtectedRoute Component={Attendance} />} />
          <Route path='AttendanceDetails/:id?' element={<ProtectedRoute Component={AttendanceDetails} />} />
          <Route path='Payslips' element={<ProtectedRoute Component={Payslips} />} />
          <Route path='ManageLeaves' element={<ProtectedRoute Component={ManageLeaves} />} />
          <Route path='PendingApproval' element={<ProtectedRoute Component={PendingApproval} />} />
          <Route path='Notifications' element={<ProtectedRoute Component={Notifications} />} />
          <Route path='Holidays' element={<ProtectedRoute Component={Holidays} />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
