import { Post } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import Messages from '../Helpers/app.label';

export const CloseDialog = (id) => {
  
    // Find the modal and hide it
    const modal = document.getElementById(id);
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');

    // Remove the modal - backdrop element
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
}
export const ResetModal = (id) => {
    // Find the modal and loop through its form elements
    const modal = document.getElementById(id);
    const formElements = modal.querySelectorAll('input, select, textarea');
    formElements.forEach((element) => {
        // Reset the value of the form element
        if (element.type === 'checkbox' || element.type === 'radio') {
            element.checked = element.defaultChecked;
        } else if (element.tagName.toLowerCase() === 'textarea') {
            element.value = element.defaultValue;
        } else {
            element.value = element.defaultValue;
        }
    });
}

export const isTokenValid = async (navigate) => {
    
    try {
        const data = new FormData();
        data.append('userId', localStorage.getItem('UserId'));
        data.append('token', localStorage.getItem('token'));

        let response = await Post((APIs.isTokenValid), data)

        if (response.Result === "UnAuthorized") {
            localStorage.removeItem('token');
            navigate("/");
        } else {
        }
    } catch (error) {
       // throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
}
