import { Post, Get } from '../../Services/HttpClient/HttpRequest';
import * as APIs from '../../Helpers/app.constant.api';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../../Helpers/app.label';

//Validate role details
const validateRoleDetails = (formData) => {
    let result = { Message: '' }

    if (!formData.RoleName) {
        result.Message = "Role name is required!"
    }
    return result;
}
// Add Update Role Data
export const addUpdateRoleData = async (formData) => {
    let response = validateRoleDetails(formData);

    if (!response.Message) {
        // let response = "";
        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('RoleName', formData.RoleName);
            data.append('IsActive', formData.IsActive);

            response = await Post((APIs.addUpdateRole), data)
        } catch (error) {
            throw new Error(Messages.SOMETHING_WENT_WRONG);
        }
    }
    return response;
}

//Get role by id
export const getRoleById = async (id) => {
    let result = "";
    try {
        const response = await Get(APIs.getRoleById + "/" + id);

        if (response) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);

    }
    return result;
}