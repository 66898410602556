import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { Delete, Edit } from '@mui/icons-material';
import * as Component from '../Components/MaintenanceComponent.js';
import MUIGrid from '../Controller/MUIGrid';

import ConformationDialog from '../Controller/ConformationDialog';
import * as Common from '../Controller/Common';
import { ShowLoadingIcon } from '../Helpers/Loader';
import Messages from '../Helpers/app.label';
import Visibility from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';


const Maintenance = () => {

    document.title = 'Maintenance | Codes';
    const [isLoading, setIsLoading] = useState(false);
    const [maintenanceData, setMaintenanceData] = useState('');
    const [errorMessageData, setErrorMessageData] = useState('');

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="View Error">
                        <Visibility
                            fontSize='small'
                            color="#525B75"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#viewErrorModal"
                            onClick={() => {
                                renderError(params.row.Id)
                            }}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'Id', headerName: 'Id', width: 50
        },
        {
            field: 'MethodName',
            headerClassName: 'super-app-theme--header',
            headerName: 'MethodName',
            width: 450,
            editable: false,
        },
        {
            field: 'PlatForm',
            headerClassName: 'super-app-theme--header',
            headerName: 'PlatForm',
            width: 100,
            editable: false,
        },
        {
            field: 'LineNumber',
            headerClassName: 'super-app-theme--header',
            headerName: 'LineNumber',
            width: 100,
            editable: false,
        },
        {
            field: 'CreatedDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'CreatedDate',
            width: 170,
            editable: false,
        },
    ];

    useEffect(() => {
        getAllMaintenance();
    }, []);

    // Get All Categories
    const getAllMaintenance = async () => {

        try {
            let response = await Component.getMaintenance();

            if (response) {
                setMaintenanceData(response.Mentenance);
            }
        } catch (error) {
            toast.error(Messages.GET_ALL_CATEGORIES_EXCEPTION_MSG);
        }
    }


    const renderError = async (Id) => {
        setErrorMessageData('');
        setIsLoading(true);
        try {
            const response = await Component.viewErrorMessage(Id);

            if (response.Status === 200) {
                setIsLoading(false);
                setErrorMessageData(response.data.ErrorMessage);
                // alert(response.data.ErrorMessage)
            }
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }

        setIsLoading(false);
    };




    // Design Section
    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="#!">Dashboard</a></li>
                                <li className="breadcrumb-item active">Maintenance</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-4">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Maintenance</h2>
                            </div>
                            {/* <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addUpdateBlogCategoryModal" onClick={() => { openBlogCategoryDialog(0) }}><span className="fas fa-plus me-2"></span>Add Category</button>
                                </div>
                            </div> */}
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={maintenanceData} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* Added Model */}
                        {/* <Model modelId={modelId} /> */}

                        {/* Conformation Dialog */}
                        {/* <ConformationDialog msg={"Are you sure to delete this Blog Category !"} onOkayClick={deleteBlogCategory} id={"deleteBlogCategory"} /> */}

                        {/* This button for open model & its hidden button */}


                    </div>
                </div>
            </main>
            {/* Modal Start hare */}
            <div className="modal fade" id="viewErrorModal" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">View Error</h5>
                            <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                                <svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                                    <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                </svg>
                            </button>
                        </div>
                        {isLoading && <ShowLoadingIcon />}
                        <div className="modal-body">
                            <div className="document-gallery">
                                <p className="word-break">{errorMessageData}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* end dialog */}

        </>
    )
}

export default Maintenance