import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import * as APIs from '../Helpers/app.constant.api';
import { Get } from '../Services/HttpClient/HttpRequest';
import { Delete, Edit } from '@mui/icons-material';
import MUIGrid from '../Controller/MUIGrid';
import { ShowLoadingIcon } from '../Helpers/Loader';
import ConformationDialog from '../Controller/ConformationDialog';
import { addUpdateTransactionData, deleteTransaction, getTransactionById } from '../Components/TransactionComponent.js';
import Messages from '../Helpers/app.label';
import * as Common from '../Controller/Common';
import Tooltip from '@mui/material/Tooltip';

const Transactions = () => {
    document.title = 'Transactions | Codes';

    const [isLoading, setIsLoading] = useState(false);
    const [transactionsData, setTransactionsData] = useState('');
    const [transactionsRowId, setTransactionsRowId] = useState('');
    const [formData, setFormData] = useState('');
    const [dialogName, setDialogName] = useState(false);
    const [dialogButtonName, setDialogButtonName] = useState(false);
    let DebitCreditBalanceAmount = { TotalDebitAmount: 0, TotalCreditAmount: 0, Balance: 0 }
    const [amount, setAmount] = useState({ DebitCreditBalanceAmount });
    let intialAddUpdateFormState = { Id: 0, Remarks: "", PayReceiveBy: 1, TransactionType: 1, TransactionAmount: "" }

    useEffect(() => {
        GetAllTransactions();
        GetDebitCreditAmount();
    }, []);

    const GetAllTransactions = async () => {

        let response = await Get(APIs.getAllTransactions);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setTransactionsData(response.Transactions);
        }
    }

    const GetDebitCreditAmount = async () => {
        let response = await Get(APIs.getDebitCreditAmount);

        if (!response) {
            toast.error("Something went wrong!");
        } else {
            setAmount(response);
        }
    }

    const columns = [
        {
            field: 'edit',
            headerName: '',
            width: 80,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit Transaction">
                        <div style={{ marginRight: '5px', display: 'inline-block' }}>
                            <Edit
                                fontSize='small'
                                color="#525B75"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target='#addUpdateTransactionModel'
                                onClick={() => {
                                    //setTransactionsRowId(params.row.Id);
                                    getTransactionData(params.row.Id);
                                }}
                            />
                        </div>
                    </Tooltip>
                    <>
                        <Tooltip title="Delete Transaction">
                            <div style={{ marginRight: '5px', display: 'inline-block' }}>
                                <Delete
                                    fontSize='small'
                                    color="#525B75"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteTransactions"
                                    onClick={() => {
                                        setTransactionsRowId(params.row.Id)
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </>
                </>
            ),
        },
        { field: 'SRNO', headerName: 'SRNO', width: 60, sortable: false },
        {
            field: 'TransactionDate',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            width: 190,
            editable: false,
        }, {
            field: 'Remarks',
            headerClassName: 'super-app-theme--header',
            headerName: 'Description',
            width: 220,
            editable: false,
        }, {
            field: 'PayReceiveBy',
            headerClassName: 'super-app-theme--header',
            headerName: 'Pay By',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <span className={`badge badge-phoenix fs--2 badge-phoenix-${params.value === 1 ? 'primary' : 'warning'}`}>
                    {params.value === 1 ? 'Cash' : params.value === 2 ? 'Check' : '-'}
                </span>
            ),
        }, {
            field: 'TransactionBy',
            headerClassName: 'super-app-theme--header',
            headerName: 'Added By',
            width: 150,
            editable: false,
        }, {
            field: 'TransactionType',
            headerClassName: 'super-app-theme--header',
            headerName: 'Debit(₹)',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <span style={{ color: "red", fontWeight: "bold", textAlign: "right" }}>{params.value === 1 ? params.row.TransactionAmount : '-'}</span>
            ),
        }, {
            field: '',
            headerClassName: 'super-app-theme--header',
            headerName: 'Credit(₹)',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <span style={{ color: "green", fontWeight: "bold", textAlign: "right" }}>{params.row.TransactionType === 2 ? params.row.TransactionAmount : '-'}</span>
            ),
        },

        // {
        //     field: 'delete',
        //     headerName: '',
        //     width: 25,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <>
        //             <Delete
        //                 fontSize='small'
        //                 color="#525B75"
        //                 type="button"
        //                 data-bs-toggle="modal"
        //                 data-bs-target="#deleteTransactions"
        //                 onClick={() => {
        //                     setTransactionsRowId(params.row.Id)
        //                 }}
        //             />
        //         </>
        //     ),
        // },
    ];

    const rowsWithSrno = Array.from(transactionsData).map((row, index) => {
        return { ...row, SRNO: index + 1 };
    });

    //Get Blog Data By ID
    const getTransactionData = async (id) => {
        setIsLoading(true);
        let dialogName = "";
        let dialogButtonName = "";

        try {
            if (id === "") {
                dialogName = "Add Transaction";
                dialogButtonName = "Add";
            } else {
                dialogName = "Update Transaction";
                dialogButtonName = "Update";

                intialAddUpdateFormState = await getTransactionById(id);

                if (!intialAddUpdateFormState) {
                    throw new Error(Messages.Error);
                }
            }
            setDialogName(dialogName);
            setDialogButtonName(dialogButtonName);
            setFormData(intialAddUpdateFormState);
        } catch (error) {
            toast.error(Messages.SOMETHING_WENT_WRONG);
        }
        setIsLoading(false);
    }

    // Add Update Transaction
    const addUpdateTransaction = async () => {
        setIsLoading(true);
        try {
            const response = await addUpdateTransactionData(formData);
            let msg = ['Transaction created successfully', 'Transaction updated successfully']

            if (response && msg.includes(response.Message)) {
                toast.success(response.Message);
                // formRef.current.reset();
                Common.CloseDialog("addUpdateTransactionModel");
                setFormData(intialAddUpdateFormState);
                await GetAllTransactions();
                await GetDebitCreditAmount();
            } else if (response.Message === 'Transaction Already Exist!') {
                toast.warning("Transaction already exist!");
            } else {
                toast.error(response.Message);
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }

    //Handle delete transaction
    const handleDeleteTransaction = async () => {
        setIsLoading(true);

        try {
            const response = await deleteTransaction(transactionsRowId);

            if (response.Status === 200) {
                setIsLoading(false);
                // toast.success(Messages.DELETE_TRANSACTION_SUCCESS_MSG);
                toast.success("Transaction successfully deleted");
                GetAllTransactions();
                GetDebitCreditAmount();
            } else {
            }
        } catch (error) {
            toast.error(Messages.DELETE_TRANSACTION_EXCEPTION_MSG);
        }
        setIsLoading(false);
    }

    return (
        <>
            <main className="main" id="top">
                <div className="container-fluid px-0" data-layout="container">

                    {/* Navabar Here */}
                    <Navbar />

                    {/* Header Here */}
                    <Header />

                    <div className="content">
                        <nav className="mb-2" aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">Transactions</li>
                            </ol>
                        </nav>
                        <div className="row align-items-center justify-content-between g-3 mb-2">
                            <div className="col col-auto">
                                <h2 className="text-bold text-1100 mb-5">Transactions</h2>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" onClick={() => { getTransactionData("") }} data-bs-target='#addUpdateTransactionModel'><span className="fas fa-plus me-2"></span>Add Transaction</button>
                                </div>
                            </div>
                        </div>

                        <div className='card mb-2'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-4' style={{ textAlign: "inherit" }} >
                                        <p className="mb-2 text-800">Total Debit Amount</p>
                                        <h3 className="text-800 text-danger">₹ {amount.DebitCreditBalanceAmount.TotalDebitAmount}</h3>
                                    </div>
                                    <div className='col-4' style={{ textAlign: "center" }} >
                                        <p className="mb-2 text-800">Total Credit Amount</p>
                                        <h3 className="text-800 text-success">₹ {amount.DebitCreditBalanceAmount.TotalCreditAmount}</h3>
                                    </div>
                                    <div className='col-4' style={{ textAlign: "end" }} >
                                        <p className="mb-2 text-800">Balance</p>
                                        <h3 className="text-800 text-primary">₹ {amount.DebitCreditBalanceAmount.Balance}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Added DataGrid */}
                        <MUIGrid Data={rowsWithSrno} columnData={columns} />

                        {/* Added Footer File  */}
                        <Footer />

                        {/* for toaster message */}
                        <ToastContainer theme="colored" />

                        {/* Conformation Dialog */}
                        <ConformationDialog msg={"Are you sure to delete this Transaction !"} pageName={"Delete"+ " " + "Transaction"} onOkayClick={handleDeleteTransaction} id={"deleteTransactions"} />

                        {/* Here loading icon */}
                        {/* {isLoading ? <ShowLoadingIcon /> : ''} */}
                    </div>
                </div>
            </main>

            {/* Here add dialog */}
            <div className="modal fade" id="addUpdateTransactionModel" tabIndex="-1" aria-labelledby="verticallyCenteredModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="verticallyCenteredModalLabel">{dialogName}</h5><button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close"><svg className="svg-inline--fa fa-xmark fs--1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg></button>
                        </div>

                        {/* Here loading icon */}
                        {isLoading ? <ShowLoadingIcon /> : ''}

                        <div className="modal-body">
                            <form id="addUpdateTransactionForm" encType="multipart/form-data">

                                <div className="col-12 gy-3">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Remarks <span style={{ color: "red" }}> *</span></label>
                                    <textarea value={formData.Remarks} onChange={e => setFormData({ ...formData, Remarks: e.target.value })} className="form-control" placeholder="Why are you doing this transaction?"></textarea>
                                </div>
                                <div className="row mt-40">
                                    <div className="col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Pay/Receive By<span style={{ color: "red" }}> *</span></label>

                                        <div className="form-check form-check-inline">
                                            {formData.Id === 0 ? (
                                                <>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="optRadioPayReceiveBy" value="1" id="payReceiveByCash" {...(formData.PayReceiveBy === 1 ? { checked: true } : {})} onChange={e => setFormData({ ...formData, PayReceiveBy: e.target.value })} /> Cash
                                                    </label> &nbsp;&nbsp;
                                                    <label className="radio-inline">
                                                        <input type="radio" name="optRadioPayReceiveBy" value="2" {...(formData.PayReceiveBy === 2 ? { checked: true } : {})} id="payReceiveByCheck" onChange={e => setFormData({ ...formData, PayReceiveBy: e.target.value })} /> Check
                                                    </label>
                                                </>
                                            ) : (
                                                <>
                                                    <label className="radio-inline">
                                                        <input type="radio" value="1" name="optRadioPayReceiveByaa" id="payReceiveByCashh" {...(formData.PayReceiveBy === 1 ? { checked: true } : {})} onChange={e => setFormData({ ...formData, PayReceiveBy: e.target.value })} /> Cash
                                                    </label> &nbsp;&nbsp;
                                                    <label className="radio-inline">
                                                        <input type="radio" value="2" name="optRadioPayReceiveByaa" id="payReceiveByCheckk" {...(formData.PayReceiveBy === 2 ? { checked: true } : {})} onChange={e => setFormData({ ...formData, PayReceiveBy: e.target.value })} /> Check
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {/* new code */}
                                    <div className="col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Transaction Type<span style={{ color: "red" }}> *</span></label>
                                        <div className="form-check form-check-inline">
                                            {formData.Id === 0 ?
                                                <>
                                                    <label className="radio-inline">
                                                        <input
                                                            onChange={(e) => setFormData({ ...formData, TransactionType: e.target.value })}
                                                            type="radio"
                                                            value="1"
                                                            name="optRadioTranType"
                                                            id="payReceiveByDebit"
                                                            checked
                                                        />{" "}
                                                        Debit
                                                    </label>{" "}
                                                    &nbsp;&nbsp;
                                                    <label className="radio-inline">
                                                        <input
                                                            onChange={(e) => setFormData({ ...formData, TransactionType: e.target.value })}
                                                            type="radio"
                                                            value="2"
                                                            name="optRadioTranType"
                                                            id="payReceiveByCredit"
                                                        />{" "}
                                                        Credit
                                                    </label>
                                                </>
                                                : (
                                                    <>
                                                        <label className="radio-inline">
                                                            <input
                                                                onChange={(e) => setFormData({ ...formData, TransactionType: e.target.value })}
                                                                type="radio"
                                                                value="1"
                                                                name="optRadioTranType"
                                                                id="payReceiveByDebit"
                                                                checked={formData.TransactionType === 1}
                                                            />{" "}
                                                            Debit
                                                        </label>{" "}
                                                        &nbsp;&nbsp;
                                                        <label className="radio-inline">
                                                            <input
                                                                onChange={(e) => setFormData({ ...formData, TransactionType: e.target.value })}
                                                                type="radio"
                                                                value="2"
                                                                name="optRadioTranType"
                                                                id="payReceiveByCredit"
                                                                checked={formData.TransactionType === 2}
                                                            />{" "}
                                                            Credit
                                                        </label>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>


                                    {/* <div className="col-md-6">
                                        <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Transaction Type<span style={{ color: "red" }}> *</span></label>
                                        <div className="form-check form-check-inline">
                                            {formData.Id === 0 ?
                                                <>
                                                    <label className="radio-inline">
                                                        <input
                                                            // defaultValue={transactionDetailsById.TransactionType}
                                                            onChange={(e) =>
                                                                setFormData({ ...formData, TransactionType: e.target.value })
                                                            }
                                                            type="radio"
                                                            value="1"
                                                            name="optRadioTranType"
                                                            id="payReceiveByDebit"

                                                            checked
                                                        />{" "}
                                                        Debit
                                                    </label>{" "}
                                                    &nbsp;&nbsp;
                                                    <label className="radio-inline">
                                                        <input
                                                            //defaultValue={transactionDetailsById.TransactionType}
                                                            onChange={(e) =>
                                                                setFormData({ ...formData, TransactionType: e.target.value })
                                                            }
                                                            type="radio"
                                                            value="2"
                                                            name="optRadioTranType"
                                                            id="payReceiveByCredit"
                                                        />{" "}
                                                        Credit
                                                    </label>
                                                </>
                                                : formData.TransactionType == 1 ? // modified condition
                                                    <>
                                                        <label className="radio-inline">
                                                            <input
                                                                // defaultValue={transactionDetailsById.TransactionType}
                                                                onChange={(e) =>
                                                                    setFormData({ ...formData, TransactionType: e.target.value })
                                                                }
                                                                type="radio"
                                                                value="1"
                                                                name="optRadioTranType"
                                                                id="payReceiveByDebit"
                                                                checked // modified comparison
                                                            />{" "}
                                                            Debit
                                                        </label>{" "}
                                                        &nbsp;&nbsp;
                                                        <label className="radio-inline">
                                                            <input
                                                                //   defaultValue={transactionDetailsById.TransactionType}
                                                                onChange={(e) =>
                                                                    setFormData({ ...formData, TransactionType: e.target.value })
                                                                }
                                                                type="radio"
                                                                value="2"
                                                                name="optRadioTranType"
                                                                id="payReceiveByCredit"
                                                            />{" "}
                                                            Credit
                                                        </label>
                                                    </>
                                                    : formData.TransactionType == 2 ? // modified condition
                                                        <>
                                                            <label className="radio-inline">
                                                                <input
                                                                    //defaultValue={transactionDetailsById.TransactionType}
                                                                    onChange={(e) =>
                                                                        setFormData({ ...formData, TransactionType: e.target.value })
                                                                    }
                                                                    type="radio"
                                                                    value="1"
                                                                    name="optRadioTranType"
                                                                    id="payReceiveByDebit"
                                                                />{" "}
                                                                Debit
                                                            </label>{" "}
                                                            &nbsp;&nbsp;
                                                            <label className="radio-inline">
                                                                <input
                                                                    //  defaultValue={transactionDetailsById.TransactionType}
                                                                    onChange={(e) =>
                                                                        setFormData({ ...formData, TransactionType: e.target.value })
                                                                    }
                                                                    type="radio"
                                                                    value="2"
                                                                    name="optRadioTranType"
                                                                    id="payReceiveByCredit"
                                                                    checked // modified comparison
                                                                />{" "}
                                                                Credit
                                                            </label>
                                                        </> : null}
                                        </div>
                                    </div> */}
                                </div>

                                <div className="col-12 gy-3">
                                    <label className="ps-1 form-label" htmlFor="floatingInputStartDate">Transaction Amount <span style={{ color: "red" }}> *</span></label>
                                    <input value={formData.TransactionAmount} onChange={e => setFormData({ ...formData, TransactionAmount: e.target.value })} className="form-control" id="floatingInputGrid" type="number" min="1" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={addUpdateTransaction} className="btn btn-primary" id='okayButton' type="button">{dialogButtonName}</button>
                            <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
            {/* end dialog */}
        </>
    )
}

export default Transactions