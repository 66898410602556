class Messages {

    // Common Messages
    static SOMETHING_WENT_WRONG = "Something went wrong!";
    static ERROR = "Error!";

    // Login Screen Messages
    static DELETE_BLOG_SUCCESS_MSG = "Blog Successfully Deleted!"

    // Blog's Success/Error Messages
    static LOGIN_CREDENTIALS_INCORRECT_MSG = 'Invalid credentials. Please try again.'
    static LOGIN_EXCEPTION_MSG = "Error occured while Login. Please try again later!"

    // Blog Categories Success/Error Messages
    static GET_ALL_BLOG_CATEGORIES_EXCEPTION_MSG = "Error occured while get all Categories from the Server!";
    static GET_ALL_SUBSCRIBERS_EXCEPTION_MSG = "Error occurred while get all Subscriber from the Server!";
    static ADD_UPDATE_BLOG_CATEGORY_EXCEPTION_MSG = "Error occured while Add/Update Blog Category!"
    static DELETE_BLOG_CATEGORY_EXCEPTION_MSG = "Error occured while Deleting Blog Category!"
    static DELETE_BLOG_CATEGORY_SUCCESS_MSG = "Blog Category Successfully Deleted!"
    //Subscriber Success/Error Messages
    static DELETE_SUBSCRIBER_EXCEPTION_MSG = "Error occured while Deleting Blog SUBSCRIBER!";
    static DELETE_SUBSCRIBER_SUCCESS_MSG = "Subscriber Successfully Deleted!";
    static MAIL_SUBSCRIBER_MSG = "Mail Send Succesfully";

    // Transaction's Success/Error Messages
    static DELETE_TRANSACTION_SUCCESS_MSG = "Transaction Successfully Deleted!"
    static DELETE_TRANSACTION_EXCEPTION_MSG = "Error occured while Deleting Transaction!"
    // Forgot Password
    static FORGOT_PASSWORD_SUCCESS_MSG = "Reset Password link has been successfully sent to your email"
    //Reset password
    static RESET_PASSWORD_SUCCESS_MSG = "Password has been reset successfully"
    
    // Employee's Success/Error Messages
    static DELETE_EMPLOYEE_SUCCESS_MSG = "Employee Successfully Deleted!"
    static DELETE_EMPLOYEE_EXCEPTION_MSG = "Error occured while Deleting Employee!"
    static OWNER_CHANGE_SUCCESS_MSG = "The owner  has been successfully changed"
    static OWNER_NOT_CHANGE_WARNING = "Only the account owner can perform this action"
    static ADMIN_CAN_NOT_BE_DELETE = "Admin accounts cannot be deleted"
    //Employee Documents
    static DELETE_EMPLOYEE_DOCUMENTS_MSG = "Employee Documents Successfully Deleted!"
    static DELETE_EMPLOYEE_DOCUMENTS_EXCEPTION_MSG = "Error occured while Deleting Employee Documents!"
    //orgnizations setting MSG
    static OGNIZATIONS_SETTING_MSG = "No Setting Found!"





}


export default Messages