import { Post, Get, Delete } from '../Services/HttpClient/HttpRequest';
import * as APIs from '../Helpers/app.constant.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Messages from '../Helpers/app.label';

const validatePorfolio = (formData, needToValidateThumbnailImage, needToValidateGalleryImage) => {
    let result = { message: '' }

    if (!formData.Title) {
        result.message = 'Title is required!';
    } else if (!formData.Industry) {
        result.message = 'Industry is required!';
    } else if (!formData.CompanyName) {
        result.message = 'Company name is required!';
    } else if (!formData.Location) {
        result.message = 'Project location is required!';
    } else if (!formData.ProjectTimeline) {
        toast.error("Project timeline is required!");
        return false;
    } else if (!formData.OverviewText) {
        result.message = 'Project timeline is required!';
    } else if (!formData.FeaturedImage && needToValidateThumbnailImage) {
        result.message = 'Featured image is required!';
    } else if (!formData.IsWindowsPlatformType && !formData.IsAndroidPlatformType && !formData.IsIOSPlatformType && !formData.IsWebPlatformType
        && !formData.IsMacPlatformType && !formData.IsLinuxPlatformType) {
            result.message = 'Please select platform type!';
    } else if (!formData.PortGalleryImages && needToValidateGalleryImage) {
        result.message = 'Gallery images is required!';
    } else if (!formData.DevelopmentSkills
        || !formData.Frontend
        || !formData.Backend
        || !formData.DatabasesName
        || !formData.Webservers) {
        result.message = 'Fill Every Technology Components!';
    }
    return result;
}

// Add update portfolio
export const addUpdatePortfolio = async (formData, needToValidateThumbnailImage, needToValidateGalleryImage) => {
    let response = validatePorfolio(formData, needToValidateThumbnailImage, needToValidateGalleryImage);
    if (!response.message) {

        try {
            const data = new FormData();
            data.append('Id', formData.Id);
            data.append('Title', formData.Title);
            data.append('Industry', formData.Industry);
            data.append('CompanyName', formData.CompanyName);
            data.append('Location', formData.Location);
            data.append('ProjectTimeline', formData.ProjectTimeline + "," + formData.ProjectTimelineType);
            data.append('ProjectLink', formData.ProjectLink);
            data.append('OverviewText', formData.OverviewText);
            data.append('FeaturesText', formData.FeaturesText);
            data.append('ModulesText', formData.ModulesText);
            data.append('DevelopmentSkills', formData.DevelopmentSkills);
            data.append('Frontend', formData.Frontend);
            data.append('Backend', formData.Backend);
            data.append('DatabasesName', formData.DatabasesName);
            data.append('Webservers', formData.Webservers);
            data.append('FeaturedImage', formData.FeaturedImage);
            data.append('IsWindowsPlatformType', formData.IsWindowsPlatformType);
            data.append('IsAndroidPlatformType', formData.IsAndroidPlatformType);
            data.append('IsIOSPlatformType', formData.IsIOSPlatformType);
            data.append('IsWebPlatformType', formData.IsWebPlatformType);
            data.append('IsLinuxPlatformType', formData.IsLinuxPlatformType);
            data.append('IsMacPlatformType', formData.IsMacPlatformType);

            if (formData.PortGalleryImages) {
                for (let i = 0; i < formData.PortGalleryImages.length; i++) {
                    data.append("PortGalleryImages[]", formData.PortGalleryImages[i]);
                }
            }


            response = await Post(APIs.AddUpdatePortfolio, data)
        } catch (error) {
            toast.error("Something went wrong.");
        }
    }
    return response;
}

//Get Portfolio Details By ID 
export const getPortfolioById = async (id) => {
    let result = "";
    try {
        const response = await Get((APIs.getPortfolioById + "/" + id));

        if (response.portfolioDetails) {
            result = response.portfolioDetails;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}

//Delete portfolio
export const deletePortfolio = async (id) => {
    let response = "";
    try {
        response = await Delete((APIs.deletePortfolio + "/" + id));
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return response;
}