import { Post, Get } from '../../Services/HttpClient/HttpRequest';
import 'react-toastify/dist/ReactToastify.css';
import * as APIs from '../../Helpers/app.constant.api';
import Messages from '../../Helpers/app.label';
import { toast } from 'react-toastify';


const validateDocuments = (formData) => {
    let result = { message: '' }

    if (!formData.DocumentType) {
        result.message = 'Please Select Document Type!';
    } else if (!formData.UploadDocument) {
        result.message = 'Document is required!';
    } else {
        const files = formData.UploadDocument;
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.size > maxSize) {
                result.message = 'File size exceeds the limit of 5MB.';
                break;
            }
        }
    }

    return result;
}


// Add update portfolio
export const uploadDocuments = async (formData, id = null) => {
    let response = validateDocuments(formData);
    if (!response.message) {

        try {
            const data = new FormData();
            data.append('DocumentType', formData.DocumentType);
            data.append('Description', formData.Description);
            if (id !== null) {
                data.append('id', id);
            }

            if (formData.UploadDocument) {
                for (let i = 0; i < formData.UploadDocument.length; i++) {
                    data.append("UploadDocument[]", formData.UploadDocument[i]);
                }
            }

            response = await Post(APIs.addEmployeeDocuments, data)
        } catch (error) {
            toast.error("Something went wrong.");
        }
    }
    return response;
}

// Get All Documents from Database
export const getDocuments = async (id = null) => {
    let result = "";
    let response;
    try {
        if (id !== null) {
            response = await Get(APIs.viewDocuments + "/" + id)
        } else {
            response = await Get(APIs.viewDocuments)

        }

        if (response !== null) {
            result = response;
        }
    } catch (error) {
        throw new Error(Messages.SOMETHING_WENT_WRONG);
    }
    return result;
}
export const deleteDocuments = async (employeesRowId, documentsName) => {
    let response = "";
    try {
        const data = new FormData();     
            data.append('employee_id', employeesRowId);
            data.append('document_name',documentsName);
        response = await Post(APIs.deleteEmployeeDocuments, data)
    } catch (error) {
        toast.error("Something went wrong.");
    }
    return response;

}
export const viewDocuments = async (employeesRowId, documentsName, Id) => {
    debugger
    let response = "";
    try {
        const data = new FormData();     
            data.append('employee_id', employeesRowId);
            data.append('document_name',documentsName);
            data.append('Id',Id);
        response = await Post(APIs.viewEmployeeDocuments, data)
    } catch (error) {
        toast.error("Something went wrong.");
    }
    return response;

}


